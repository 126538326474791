.icon-overlap-cards-section {
	margin-bottom: 30px;
	h2 {
		font-size: rem-calc(40);
		color: $dark-blue;
		line-height: 1.1;
		margin-bottom: 30px;
		padding: 0;
		&::after {
			display: none;
		}
		@include breakpoint(large) {
			margin-bottom: 50px;
		}
	}
	.inner {
		max-width: 75em;
		margin: 0 auto;
		padding: 0 0.625em;
		@include breakpoint(medium) {
			padding: 0 0.9375rem;
		}
	}
}

.icon-overlap-cards {
	max-width: 750px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	@include breakpoint(900px) {
		max-width: 100%;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
}


.icon-overlap-card {
	background: $off-white;
	font-size: rem-calc(20);
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
	padding: 0 20px 20px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	h3 {
		font-size: rem-calc(27);
		font-weight: $heading-bold;
		color: $brand-alt;
		line-height: 1.26;
		text-align: left;
		padding-bottom: 0;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
	}
	.icon {
		margin-top: -20px;
	}
	.button {
		font-size: rem-calc(16);
		margin-top: auto;
		margin-bottom: 0;
		align-self: flex-start;
	}
}


.icon-overlap-cards.wide {
	.icon-overlap-card {
		width: 100%;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
	@include breakpoint(900px) {
		grid-template-columns: 1fr 1fr;
		.icon-overlap-card {
			.icon img {
				width: auto;
				height: 50px;
				margin-bottom: 10px;
			}
		}
	}
}