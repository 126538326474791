/*----------------------------------------------------------*\
    Helpers

    This file contains most of the available helper
    utilities (not including base Foundation). Use these
    when small tweaks are needed on existing pieces that
    don't warrant a full semantic CSS process.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Font Helpers
\*------------------------------------*/

.text-main {
    font-family: $main-font !important;
    font-weight: $main-normal !important;
}

.text-alt {
    font-family: $alt-font !important;
    font-weight: $alt-normal !important;
}

.text-medium {
    font-size: rem-calc(18);
    line-height: 1.5;
}

.text-lrg {font-size:1.3125em !important;}
ul.text-lrg {
    padding-left: 0;
    li {
        background-image: url('/images/cssimg/flames.png');
        background-size: 18px 18px;
        background-position: 0 6px;
        padding-left: 30px;
    }
}
.text-sml {font-size:.75em !important;}

.text-shadow {text-shadow: $text-shadow !important;}

.text-brand {color:$brand !important;}
.text-brand-ally {color:$brand-ally !important;}
.text-brand-accent {color:$brand-accent !important;}
.text-brand-alt {color:$brand-alt !important;}
.text-brand-alt-accent {color:$brand-alt-accent !important;}
.text-dark-gray {color:$dark-gray !important;}
.text-gray {color:$gray !important;}
.text-light-gray {color:$light-gray !important;}
.text-white {color:$white !important;}
.text-off-white {color:$off-white !important;}
.text-black {color:$black !important;}

.text-dark-blue {color:$dark-blue !important;}
.text-bright-blue {color:$bright-blue !important;}
.text-orange {color:$orange !important;}
.text-yellow {color:$yellow !important;}
.text-green {color:$green !important;}

.simple-heading {
    font-size: rem-calc(24px);
    text-align: left;
    line-height: 1.25;
    padding-bottom: 0;
    margin-bottom: 1.5rem;
    @include breakpoint(800px) {
        font-size: rem-calc(32px);
        line-height: 1.125;
    }
    &::after {
        display: none;
    }
    &.heading-center {
        text-align: center;
    }
}

.heading-space-bottom {margin-bottom: rem-calc(48);}
/* @end */


/*------------------------------------*\
    @group Text Highlights
\*------------------------------------*/

.highlight {
	color: $brand;
}
.highlight-dark {
    color: $brand-ally;
}
.highlight-alt {
	color: $brand-alt;
	font-weight: 700;
	text-shadow: $text-shadow;
}
.highlight-gray {
    color: #CCC;
}

/* @end */


/*------------------------------------*\
    @group Background Helpers
\*------------------------------------*/

.bg-brand {background-color:$brand !important;}
.bg-brand-accent {background-color:$brand-accent !important;}
.bg-brand-alt {background-color:$brand-alt !important;}
.bg-brand-alt-accent {background-color:$brand-alt-accent !important;}
.bg-dark-gray {background-color:$dark-gray !important;}
.bg-gray {background-color:$gray !important;}
.bg-light-gray {background-color:$light-gray !important;}
.bg-white {background-color:$white !important;}
.bg-off-white {background-color:$off-white !important;}
.bg-black {background-color:$black !important;}

.bg-dark-blue {background-color:$dark-blue !important;}
.bg-bright-blue {background-color:$bright-blue !important;}
.bg-orange {background-color:$orange !important;}
.bg-yellow {background-color:$yellow !important;}
.bg-green {background-color:$green !important;}

.box-shadow {box-shadow: $box-shadow;}

.bg-round {border-radius: $border-round;}

/* @end */


/*------------------------------------*\
    @group Spacing Helpers
\*------------------------------------*/
.mb-0 {margin-bottom: 0 !important;}
.mb-5 {margin-bottom: 5px !important;}
.mb-10 {margin-bottom: 10px !important;}
.mb-15 {margin-bottom: 15px !important;}
.mb-20 {margin-bottom: 20px !important;}
.mb-25 {margin-bottom: 25px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-35 {margin-bottom: 35px !important;}
.mb-40 {margin-bottom: 40px !important;}
.mb-50 {margin-bottom: 50px !important;}
.mb-60 {margin-bottom: 60px !important;}

.mt-0 {margin-top: 0 !important;}
.mt-5 {margin-top: 5px !important;}
.mt-10 {margin-top: 10px !important;}
.mt-15 {margin-top: 15px !important;}
.mt-20 {margin-top: 20px !important;}
.mt-25 {margin-top: 25px !important;}
.mt-30 {margin-top: 30px !important;}
.mt-35 {margin-top: 35px !important;}
.mt-40 {margin-top: 40px !important;}
.mt-50 {margin-top: 50px !important;}
.mt-60 {margin-top: 60px !important;}

.stacked {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

@media screen and #{breakpoint(large)} {
    .extra-pad {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .extra-pad-inner > .column {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.z-0 {
    z-index: 0 !important;
}

.visually-hidden {

}

/* @end */


/*------------------------------------*\
    @group Animations and Transitions
\*------------------------------------*/

.fade-scale-animation {
    display: block;
    animation: fadeScaleIn 0.3s ease;
}

@keyframes fadeScaleIn {
    from {
        opacity:0;
        transform:scale(0)
    }
    to {
        opacity:1;
        transform:scale(1)
    }
}

/* @end Animations and Transitions */


/*------------------------------------*\
    @group Social Media brand Colors
\*------------------------------------*/

.brand-twitter {@include link-colors($color:$brand-twitter);}
.brand-facebook {@include link-colors($color:$brand-facebook);}
.brand-googleplus {@include link-colors($color:$brand-googleplus);}
.brand-pinterest {@include link-colors($color:$brand-pinterest);}
.brand-linkedin {@include link-colors($color:$brand-linkedin);}
.brand-youtube {@include link-colors($color:$brand-youtube);}
.brand-vimeo {@include link-colors($color:$brand-vimeo);}
.brand-instagram {@include link-colors($color:$brand-instagram);}
.brand-quora {@include link-colors($color:$brand-quora);}
.brand-foursquare {@include link-colors($color:$brand-foursquare);}
.brand-stumbleupon {@include link-colors($color:$brand-stumbleupon);}
.brand-soundcloud {@include link-colors($color:$brand-soundcloud);}

/* @end */