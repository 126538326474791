.jitasa-financial-calculator {
	max-width: 780px;
	background: $brand-alt;
	color: $white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	margin: 0 auto 30px;
	overflow: hidden;
	h2 {
		font-size: rem-calc(40);
		font-weight: $heading-bold;
		color: $white;
		line-height: 1.1;
		text-align: left;
		padding-bottom: 0;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
	}
	form {
		[type="submit"] {
			margin-bottom: 0;
			margin-top: 30px;
		}
		label {
			margin: 0;
		}
		label > span {
			font-family: $heading-font;
			font-size: rem-calc(18);
			font-weight: $heading-bold;
			color: $white;
			line-height: 1.5;
			margin-bottom: 8px;
			display: block;
		}
		.calculator-inputs {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
			grid-gap: 18px 30px;
			&.triple {
				grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
			}
		}
		input[type="text"] {
			height: 41px;
			padding-left: 15px;
			margin-bottom: 0;
		}
		.input-icon {
			margin-bottom: 4px;
			display: flex;
			align-items: stretch;
			.dollar-sign {
				width: 41px;
				background-color: $light-gray;
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			img {
				margin: 0;
			}
		}
	}
	.calculator-tooltip-button {
		font-family: $alt-font;
		font-size: rem-calc(14);
		font-weight: $alt-normal;
		color: $brand-alt-lightest;
		line-height: 1.29;
		margin: 0;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		img {
			margin: 0 5px 0 0;
		}
	}

}

.jitasa-financial-calculator-inputs {
	padding: 30px;
}

.jitasa-financial-calculator-results {
	background: #62256A;
	font-family: $alt-font;
	padding: 30px;
	.title {
		font-size: rem-calc(18);
		font-weight: $alt-bold;
		color: $brand-alt-lightest;
		margin-bottom: 0;
	}
	.result {
		font-size: rem-calc(60);
		font-weight: $alt-bold;
		color: $white;
		line-height: 1;
		margin-bottom: 14px;
	}
	.result-explainer {
		margin-bottom: 0;
	}
}