.clients-template {
	.hero-short {
		box-shadow: none;
		margin-bottom: 0;
	}
}

@media screen and (min-width:50em) {
	.clients-template {
		.hero-numbers {
			height: 350px;
		}
	}
}