/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding-top: 25px;
    padding-bottom: 15px;
    position: relative;
    z-index: 5;
}
.contained {
	max-width: 75rem;
	padding-left: .9375rem;
	padding-right: .9375rem;
    margin-left: auto;
    margin-right: auto;
}

.contained-narrow {
    max-width: 56.25rem;
    padding-left: .9375rem;
    padding-right: .9375rem;
    margin-left: auto;
    margin-right: auto;
}

.contained-narrowish {
    max-width: 60.625rem;
    padding-left: .9375rem;
    padding-right: .9375rem;
    margin-left: auto;
    margin-right: auto;
}


.contained.contained-extra-narrow,
.contained-extra-narrow {
    max-width: rem-calc(810px);
    &.center {
        margin-left: auto;
        margin-right: auto;
    }
}

.contained .contained-narrow,
.contained .contained-narrowish {
    padding-left: 0;
    padding-right: 0;
}

@media screen and #{breakpoint(medium)} {

    .page-content {
        padding-top: 50px;
        padding-bottom: 30px;
    }

}