.price-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 50px 30px;
	justify-items: center;
	margin-bottom: 50px;
}

.price-card {
	width: 100%;
	max-width: 360px;
	box-shadow: $box-shadow;
	h3 {
		font-size: rem-calc(28);
		color: $brand-alt;
		line-height: 1.1;
		margin-bottom: 50px;
	}
	ul {
		padding: 0;
	}
	li {
		background: url('/images/icons/subtract_circle.svg') no-repeat right 4px;
		font-family: $alt-font;
		font-size: rem-calc(16);
		font-weight: $alt-normal;
		color: $dark-gray;
		padding-left: 0;
		padding-right: 20px;
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: solid 1px $off-white;
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
		.small {
			font-family: $main-font;
			font-size: rem-calc(14);
			color: $gray;
			display: block;
		}
		&.included {
			background-image: url('/images/icons/checkmark_circle.svg');
		}
	}
	.price-card-content {
		padding: 20px 20px 0;
	}
	.price-card-footer {
		min-height: 205px;
		background: $brand-alt;
		font-family: $alt-font;
		font-size: rem-calc(24);
		color: $white;
		text-align: center;
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			line-height: 1.2;
			margin: 0;
		}
	}
}