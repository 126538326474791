.loader {
    width: 30px;
    height: 30px;
    border: 5px solid #FFF;
    border-bottom-color: $brand;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader.dark {
    border-color: #d9b9dd;
    border-bottom-color: #8d3499;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    button,
    .button {
        flex-shrink: 0;
    }
}