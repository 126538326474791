/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
$small-header-height: 50px;
$large-header-height: 80px;

.page-header {
	width: 100%;
	height: auto;
    min-height: $small-header-height;
	background: $white;
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    transition: height .3s;
    position: fixed;
    z-index: 999;
    top: 0;
    // &[aria-hidden="true"] {
    //     z-index: 1;
    // }
    + * {
        transition: transform .3s;
    }
    .header-inner {
        height: $small-header-height;
        display: flex;
        justify-content: space-between;
    }
    .logo {
        height: $small-header-height;
        flex-basis: 183px;
    	position: relative;
        z-index: 100;
    	.main-logo {
            max-height: 70px;
            width: auto;
    		background: $white;
    		padding: 11px;
    		margin-bottom: 0;
		  	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
		  	display: block;
		  	position: absolute;
    	}
    	.flame-logo {
            width: auto;
            max-height: 31px;
            margin-top: 10px;
            margin-bottom: 0;
    		display: none;
    	}
    }
    .nav-controls {
        text-align: right;
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .icon-link {
            font-family: $alt-font;
            font-size: rem-calc(16);
            padding: 0 20px;
            text-transform: uppercase;
            text-align: center;
            a {
                font-size: rem-calc(12);
                text-decoration: none;
                line-height: 1;
                display: inline-block;
                &:hover, &:focus {
                    color: $brand-ally;
                }
            }
            .icon {
                font-size: 28px;
                color: $brand;
                display: block;
                &.green {
                    color: $green;
                }
            }
        }
    }
    .nav-toggle {
        font-family: $alt-font;
        color: $brand-alt;
        transition: color .3s;
        .hamburger {
            display: block;
            float: right;
        }
        .nav-label {
            font-size: 12px;
            line-height: 1;
            text-transform: uppercase;
            display: block;
        }
        &:hover,
        &.active {
            color: $brand-ally;
        }
    }
    .main-nav {
        display: none;
    }
}

@media screen and #{breakpoint(medium)} {
    .page-header {
        min-height: $large-header-height;
        .header-inner {
            min-height: $large-header-height;
        }
        .logo {
            height: $large-header-height;
            .main-logo {
                max-height: none;
                padding: 22px 26px;
            }
            .flame-logo {
                max-height: none;
                margin-top: 18px;
            }
        }
    }
}

@media screen and (min-width: 60.625em) {
    .page-header {
        .nav-toggle,
        .nav-controls {
            display: none !important;
        }
        .main-nav {
            padding-top: 19px;
            display: flex;
            justify-content: flex-end;
            > ul {
                display: flex;
                gap: 27px;
            }
        }
    }
}


/*------------------------------------*\
    @group Navigation
\*------------------------------------*/


@media screen and (min-width: 60.625em) {
    .page-header .main-nav {
        font-family: $alt-font;
        text-align: right;
        a {
            color: $main-color;
            text-decoration: none;
            display: block;
            &.selected, &:hover, &:focus {
                color: $brand-ally;
            }
        }
        > ul > li {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-bottom: 19px;
            li {
                padding-left: 0;
                margin: 0;
                display: block;
            }
        }
        .has-dropdown {
            position: relative;
            > a {
                // height: 52px;
                padding-right: 18px;
                position: relative;
                &::after {
                    content: '';
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1008 1008'%3E%3Cpath fill='%2338c0fe' d='M504 668l374-360c7-7 15-10 25-10s18 3 25 10 10 15 10 25-3 18-10 25L529 753c-7 7-15 10-25 10s-18-3-25-10L80 358c-7-7-10-15-10-25s3-18 10-25 15-10 25-10 18 3 25 10z'/%3E%3C/svg%3E") no-repeat left top;
                    width: 12px;
                    height: 12px;
                    background-size: contain;
                    position: absolute;
                    right: 0;
                    top: 7px;
                    display: block;
                }
            }
            ul {
                min-width: 200px;
                background: $brand-ally;
                text-align: left;
                padding: .4rem 1.5rem .6rem;
                box-shadow: 0 8px 5px -5px rgba(0,0,0,.15);
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s ease-out .4s, opacity .5s ease-in-out;
                position: absolute;
                left: -2rem;
                top: 100%;
                a {
                    color: $white;
                    transition: text-decoration .2s;
                    text-decoration: underline transparent;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                    &.selected, &:hover, &:focus {
                        text-decoration-color: currentColor;
                        transition: text-decoration 0;
                    }
                }
            }
            &:hover ul,
            &:focus-within > ul {
                visibility: visible;
                opacity: 1;
                transition: all 0.3s ease-out 0s;
            }
        }
        .services-dropdown > ul,
        .wide-dropdown > ul {
            min-width: 300px;
        }
        .button-nav {
            a {
                background: $brand-ally;
                font-size: rem-calc(18);
                font-family: $alt-font;
                font-weight: $alt-bold;
                color: $white;
                padding: .5em 1em .6em;
                margin: 0;
                border: none;
                display: inline-block;
                line-height: 1.2;
                transition: background-color .3s;
                &:hover,
                &:focus {
                    background: darken($brand-ally,10);
                    color: $white;
                    text-decoration: none;
                }
            }
            &.green a {
                background: $green;
                &:hover,
                &:focus {
                    background: darken($green,10);
                }
            }
        }
        i {
            display: none;
        }
    }
}

/* @end Navigation */


.logo-slide-in {
	@include mui-slide(
		$direction: down,
		$duration: .3s
	);
}