.media-stacked-card {
	max-width: 560px;
	border: solid 7px $light-gray;
	margin: 0 auto 30px;
	.media {
		img {
			width: 100%;
			margin: 0;
		}
	}
	.details {
		padding: 30px 20px;
	}
	blockquote {
		background: none;
		color: $gray;
		padding: 0;
		margin: 0;
		.icon {
			color: $brand-alt-accent;
			vertical-align: 5px;
		}
		p {
			font-size: rem-calc(16);
			font-style: italic;
			color: $gray;
			line-height: 1.5;
		}
		cite {
			font-size: rem-calc(16);
			color: $gray;
			.speaker {
				font-size: rem-calc(18);
				font-weight: $main-normal;
				color: $dark-gray;
				margin-bottom: 3px;
				display: block;
			}
			a {
				color: $brand-alt;
				&:hover, &:focus {
					color: $brand-ally;
				}
			}
		}
	}
}

@include breakpoint(850px) {
	.media-stacked-card {
		.details {
			width: 100%;
			padding: 55px 35px 35px 35px;
			p, li {
				font-size: rem-calc(18);
			}
		}
	}
}


@media screen and #{breakpoint(large)} {
	.media-stacked-card {
		.details {
			padding: 50px;
			p {
				font-size: rem-calc(20);
			}
		}
	}
}