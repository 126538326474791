/*----------------------------------------------------------*\
    Mixins

    This file contains custom mixins. All mixins should go
    here as this file is imported into both the base and
    main css files.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Social Media Link Colors
\*------------------------------------*/

@mixin link-colors ($color: null, $bg: null) {
	background-color: $bg;
	color: $color;
	&:hover, &:focus {
		@if ($bg) {
			background-color: darken($bg,10%);
		}
		@if ($color and $bg == null) {
			//color: darken($color,10%);
		}
	}
}

/* @end Social Media Link Colors */

/*------------------------------------*\
    @group Small border under text
\*------------------------------------*/

@mixin small-border($color: #CCC, $position: middle) {
	padding-bottom: 12px;
	position: relative;
	&::after {
		content: '';
		width: 40px;
		height: 2px;
		border-bottom: 2px solid $color;
		display: block;
		position: absolute;
		bottom: 0;
		@if ($position == middle) {
			left: 50%;
			transform: translateX(-50%);
		}
		@if ($position == left) {
			left: 0;
		}
		@if ($position == right) {
			left: auto;
			right: 0;
		}
	}
}

/* @end Small border under text */