.simple-email-subscribe-message {
	p {
		font-size: rem-calc(20);
		font-family: $heading-font;
		font-weight: $heading-bold;
		color: $white;
		text-align: center;
		line-height: 1.2;
		margin-bottom: 0;
	}
}

@include breakpoint(medium) {
	.simple-email-subscribe-message {
		p {
			font-size: rem-calc(24);
		}
	}
}