@font-face {
    font-family: 'meta-pro';
    src: url('/fonts/meta_pro_normal.eot');
    src: url('/fonts/meta_pro_normal.woff2') format('woff2'),
        url('/fonts/meta_pro_normal.woff') format('woff'),
        url('/fonts/meta_pro_normal.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'meta-pro';
    src: url('/fonts/meta_pro_bold.eot');
    src: url('/fonts/meta_pro_bold.woff2') format('woff2'),
        url('/fonts/meta_pro_bold.woff') format('woff'),
        url('/fonts/meta_pro_bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'meta-serif';
    src: url('/fonts/meta_serif_book.eot');
    src: url('/fonts/meta_serif_book.woff2') format('woff2'),
        url('/fonts/meta_serif_book.woff') format('woff'),
        url('/fonts/meta_serif_book.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'meta-serif';
    src: url('/fonts/meta_serif_bold.eot');
    src: url('/fonts/meta_serif_bold.woff2') format('woff2'),
        url('/fonts/meta_serif_bold.woff') format('woff'),
        url('/fonts/meta_serif_bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}