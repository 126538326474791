/*------------------------------------*\
    @group Home Template
\*------------------------------------*/

.home-layout {
	.hero {
		height: 411px;
	}
	.client-image-grid {
		padding-bottom: 30px;
	}
	&.home-industries .hero {
		height: 450px;
	}
}

@media screen and #{breakpoint(medium)} {

.home-layout {
	.hero,
	&.home-industries .hero {
		height: 560px;
	}
	.client-image-grid {
		padding-bottom: 50px;
	}
}

}

.hero-image .slider-bg-img {
	background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water.jpg?auto=format');
	background-position: center;
	background-size: cover;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	&.img-0 {
		background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water.jpg?auto=format');
	}
	&.img-1 {
		background-image: url('https://jitasa.imgix.net/bg/hero/food_bank_volunteer.jpg?auto=format&fit=crop&w=640&h=400');
	}
	&.img-2 {
		background-image: url('https://jitasa.imgix.net/bg/hero/science_kids.jpg?auto=format&fit=crop&w=640&h=400');
	}
}

@media screen and #{breakpoint(medium)} {
	.hero-image .slider-bg-img {
		background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water_med.jpg?auto=format');
		&.img-0 {
			background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water_med.jpg?auto=format');
		}
		&.img-1 {
			background-image: url('https://jitasa.imgix.net/bg/hero/food_bank_volunteer.jpg?auto=format&fit=crop&w=1000&h=700');
		}
		&.img-2 {
			background-image: url('https://jitasa.imgix.net/bg/hero/science_kids.jpg?auto=format&fit=crop&w=1000&h=700');
		}
	}

}

@media screen and #{breakpoint(large)} {
	.hero-image .slider-bg-img {
		background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water_lrg.jpg?auto=format');
		&.img-0 {
			background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water_lrg.jpg?auto=format');
		}
		&.img-1 {
			background-image: url('https://jitasa.imgix.net/bg/hero/food_bank_volunteer.jpg?auto=format&w=1680');
		}
		&.img-2 {
			background-image: url('https://jitasa.imgix.net/bg/hero/science_kids.jpg?auto=format&w=1680');
		}
	}
}

/* @end Home Template */


/*------------------------------------*\
    @group Home Service Sizes Template
\*------------------------------------*/
.home-sizes-layout .hero-flames {
	height: 485px;
	background-image: url('https://jitasa.imgix.net/bg/hero/flames.png?auto=format'), linear-gradient(to top, #2781ac 0%, #19357d 100%);
	background-repeat: no-repeat;
	background-position: center 35px, 0 0;
	.hero-content {
		text-align: center;
		top: calc(50% - 40px);
		position: relative;
		transform: translateY(-50%);
	}
	h1 {
		font-size: rem-calc(42);
		line-height: 1.2;
		color: $white;
		padding: 0;
		margin-bottom: 8px;
		&::after {
			display: none;
		}
	}
	.subheading {
		font-family: $alt-font;
		font-size: rem-calc(24);
		color: $brand-accent;
		line-height: 1.2;
	}
}

.home-sizes-layout .size-offerings {
	margin-top: -165px;
	h2 {
		font-family: $main-font;
		font-size: rem-calc(20);
		font-weight: $heading-bold;
		color: $white;
		padding: 0;
		&::after {
			display: none;
		}
	}
	.inner {
		max-width: 1165px;
		margin: 0 auto;
	}
	.card-arrows {
		text-align: center;
		padding: 0 .9375rem;
		.icon {
			font-size: 38px;
			color: $brand-accent;
		}
		.middle-arrow {
			animation: seesawCenter 1.75s linear infinite;
		}
		.arrow-group {
			margin: 13px 0;
		}
		.first-arrow, .last-arrow {
			display: none;
		}
	}
}

.home-sizes-layout {
	.hero-short {
		box-shadow: none;
		margin-bottom: 0;
	}
}

@media screen and (min-width:50em) {
	.home-sizes-layout {
		.hero-numbers {
			height: 350px;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.home-sizes-layout .hero-flames {
		height: 653px;
		background-image: url('https://jitasa.imgix.net/bg/hero/flames_lrg.png?auto=format'), linear-gradient(to top, #2781ac 0%, #19357d 100%);
		background-repeat: no-repeat;
		background-position: center 51px, 0 0; 
		h1 {
			font-size: rem-calc(72);
			line-height: 1;
		}
		.subheading {
			font-size: rem-calc(36);
		}
	}

	.home-sizes-layout .size-offerings {
		margin-top: -230px;
		h2 {
			font-size: rem-calc(28);
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		}
		.card-arrows {
			.arrow-group {
				margin: 28px 0;
				position: relative;
			}
			.first-arrow, .last-arrow {
				display: block;
				position: absolute;
				top: 0;
			}
			.first-arrow {
				transform: rotate(45deg);
				left: 25%;
				animation: seesaw 1.75s linear infinite;
			}
			.last-arrow {
				transform: rotate(-45deg);
				right: 25%;
				animation: seesawInverse 1.75s linear infinite;
			}
		}
	}

}

@keyframes seesaw {
	0% {
		transform: translate(0, 0) rotate(45deg);
	}
	50% {
		transform: translate(10px, -10px) rotate(45deg);
	}
	100% {
		transform: translate(0, 0) rotate(45deg);
	}
}

@keyframes seesawCenter {
	0% {
		transform: translate(0, 5px);
	}
	50% {
		transform: translate(0, -5px);
	}
	100% {
		transform: translate(0, 5px);
	}
}

@keyframes seesawInverse {
	0% {
		transform: translate(0, 0) rotate(-45deg);
	}
	50% {
		transform: translate(-10px, -10px) rotate(-45deg);
	}
	100% {
		transform: translate(0, 0) rotate(-45deg);
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.home-sizes-layout .hero-flames {
		background-image: url('https://jitasa.imgix.net/bg/hero/flames@2x.png?auto=format'), linear-gradient(to top, #2781ac 0%, #19357d 100%);
		background-size: 255px 255px, 100%;
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and #{breakpoint(large)},
only screen and (-o-min-device-pixel-ratio: 13/10) and #{breakpoint(large)},
only screen and (min-resolution: 120dpi) and #{breakpoint(large)} {
	.home-sizes-layout .hero-flames {
		background-image: url('https://jitasa.imgix.net/bg/hero/flames_lrg@2x.png?auto=format'), linear-gradient(to top, #2781ac 0%, #19357d 100%);
		background-size: 438px 438px, 100%;

	}
}
/* @end Home Service Sizes Template */



/*------------------------------------*\
    @group Home Flame Hero
\*------------------------------------*/
.hero-flames {
	background-color: #4b2050;
	background-image: url('https://jitasa.imgix.net/bg/hero/bg_flame_collage.jpg?auto=format');
	background-position: left top;
	background-repeat: no-repeat;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://jitasa.imgix.net/bg/hero/bg_flame_collage@2x.jpg?auto=format');
		background-size: 640px 600px;
	}
	h1 {
		font-size: rem-calc(36);
		color: $white;
		text-align: left;
		line-height: 1;
		padding: 0;
		margin: 0;
		&::after {
			display: none;
		}
	}
	.subheading {
		font-family: $heading-font;
		font-size: rem-calc(24);
		color: $brand-accent;
		line-height: 1.33;
	}
	img {
		max-width: 60%;
		margin: 0 auto;
		display: block;
	}
	.button {
		font-size: rem-calc(18);
		padding: 9px 18px 11px;
		margin: 0;
	}
	.text {
		max-width: 350px;
		margin-bottom: 30px;
	}
	.hero-content {
		padding-top: 50px;
	}
}

@include breakpoint(medium) {
	.hero-flames {
		background-image: url('https://jitasa.imgix.net/bg/hero/bg_flame_collage_med.jpg?auto=format');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_flame_collage_med@2x.jpg?auto=format');
			background-size: 1024px 400px;
		}
		.hero-content {
			height: 400px;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
		}
		img {
			max-width: 100%;
		}
		.text {
			margin-bottom: 0;
		}
		.media {
			flex-basis: 50%;
			align-self: flex-end;
		}
	}
}

@include breakpoint(large) {
	.hero-flames {
		background-image: url('https://jitasa.imgix.net/bg/hero/bg_flame_collage_lrg.jpg?auto=format');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_flame_collage_lrg@2x.jpg?auto=format');
			background-size: 1800px 500px;
		}
		.hero-content {
			height: 500px;
		}
		h1 {
			font-size: rem-calc(50);
		}
		.subheading {
			font-size: rem-calc(32);
		}
		.text {
			max-width: 480px;
		}
	}
}

@include breakpoint(1800px) {
	.hero-flames {
		background-size: cover;
		background-position: center;
	}
}
/* @end Home Flame Hero */