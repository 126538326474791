/*------------------------------------*\
    @group Testimonials List
\*------------------------------------*/

.testimonials-list {
	.quote {
		padding: 15px 0;
		&:nth-child(even) {
			background: $off-white;
		}
		blockquote {
			max-width: 75rem;
		    margin-left: auto;
		    margin-right: auto;
		}
	}
}

/* @end Testimonials List */