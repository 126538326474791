.quote-form-wrap {
	max-width: 755px;
	font-family: "ff-meta-serif-web-pro", palatino, arial;
	font-size: rem-calc(15);
	color: #58585B;
	margin: 0 auto;
	h1 {
		color: #8a3397;
		font-family: "ff-meta-web-pro", "Lucida Grande", sans-serif;
		font-size: 24px;
		line-height: 32px;
		font-weight: normal;
		text-align: left;
		padding-bottom: 0;
		clear: both;
		margin-top: 45px;
		&::after {
			display: none;
		}
	}
	h2 {
		color: #8a3397;
		font-family: "ff-meta-web-pro", "Lucida Grande", sans-serif;
		font-size: 20px;
		line-height: 32px;
		font-weight: normal;
		clear: both;
	}
	h3 {
		color: #58585b;
		font-family: "ff-meta-serif-web-pro", palatino, arial;
		font-size: 18px;
		font-weight: bold;
		line-height: 32px;
		clear: both;
		margin-bottom: 0;
		margin-top: 30px;
	}
	a {
		color: #38bdff;
	}
	a:hover {
		color: #1388C2;
	}
	em {
		font-style: italic;
	}
	.default_selection {
		color: #8a3397;
	}
	p {
		margin: 15px 0;
	}
	#submitnow {
		margin: 0;
	}

	label {
		width: 263px;
		font-size: 15px;
		color: #58585B;
		float: left;
		clear: both;
		margin: 4px 0;
		text-align: right;
		padding-top: 3px;
		padding-right: 10px;
		line-height: 18px;
		em {
			margin-left: 5px;
		}
	}
	label.longlabel {
		width: 525px;
	}
	.radioenclosure label {
		width: auto;
		float: left;
		padding: 3px;
		clear: none;
		margin-right: 5px;
		margin-top: 0;
	}
	input, select, span {
		width: auto;
		float: left;
		margin: 3px 0;
		font-size: 12px !important;
		display: inline-block;
	}
	select {
		background-position: right -14px center;
		border: 1px solid darkgray;
		padding: 3px 20px 3px 3px;
		font-size: 14px;
		margin-top: 2px;
	}

	.clr {
		clear: both;
	}

	.fieldlegend {
		float: left;
		clear: both;
		font-size: 12px;
		margin-bottom: 24px;
		font-style: italic;
		width: 540px;
	}

	.radioenclosure select.inline {
		font-size: 10px;
		margin-right: 17px;
		max-width: 180px;
	}
	.radioenclosure {
		margin-top: 5px;
		float: left;
	}
	.calc_field_row {
		clear: both;
		border-top: 1px solid lightgray;
		display: block;
		margin: 3px 0;
	}
	.calc_field_row.labelleft label {
		text-align: left;
	}
	div.noborder {
		border: none;
	}
	#calc_submit_container {
		margin: 10px 0 25px 0;
		clear: both;
		overflow: auto;
	}
	#calc_submit_container input {
		background-color: #37BDFF;
		border: 1px solid #37BDFF;
		border-radius: 4px 4px 4px 4px;
		color: #FFFFFF;
		font-family: "ff-meta-web-pro","Lucida Grande",sans-serif;
		font-size: 18px !important;
		line-height: 1;
		padding: 15px 28px;
		text-decoration: none;
		margin-right: 24px;
		cursor: pointer;
	}
	#calc_submit_container input:hover {
		background-color: #1388C2;
		border: 1px solid #1388C2;
	}

	span.pointer {
		margin-right: 25px;
		padding-left: 3px;
		cursor: pointer;
	}
	input, textarea {
		border: 1px solid darkgray;
		padding: 3px;
		font-size: 14px;
	}
	input[type="radio"] {
		border: none;
		font-size: 18px;
		margin-top: 5px;
	}
	.finalhoursdescript {
		font-weight: bold;
		font-size: 15px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.finalhourbox {
		background-color: #F2F2F2;
		border: 1px solid #DADADB;
		font-weight: bold;
		margin: 20px 0;
		width: 75px;
		text-align: center;
	}

	.disclaimer {
		background-color: #F2F2F2;
		border: 1px solid #DADADB;
		border-radius: 6px;
		padding: 16px;
		font-style: italic;
		margin: 16px 0;
		clear: both;
	}
	.disclaimer p {
		margin-top: 0;
	}

	#wait {
		width: 31px;
		height: 31px;
		background: url('/images/cssimg/ajax-loader.gif') no-repeat;
		visibility: hidden;
		float: left;
		margin-top: 14px;
		margin-right: 14px;
	}
	#submitnow {
		margin-right: 9px;
		&:disabled, &:disabled:hover, &:disabled:focus {
			background-color: #b4aeae;
			border: none;
			cursor: default;
		}
	}

	table { clear: both; border-collapse: collapse; border-spacing: 0; margin-bottom: 30px; }

	.ykey { white-space: nowrap; }

	tr td, tr th { margin: 0; padding: 3px 7px;}

	th {
		font-size: 14px;
		text-align: center;
		border: 1px solid darkgray;
		font-weight: bold;
	}
	th.ykey {
		border-left: 0 none;
		border-top: 0 none;
		border-bottom: 0 none;
	}
	td {
		font-size: 14px;
		text-align: center;
		border: 1px solid darkgray;
	}
	#b0-num, #b1-num, #b2-num {
		fontis-zie: 12px;
	}

	td.ykey {
		font-size: 13px;
		padding-right: 7px;
		text-align: right;
		border: none;
	}

	#b1-title, #b1-num, #b1-min, #b1-avg, #b1-max, #b1-cost, #b1-overage {
		font-weight: bold;
		background-color: #F7E4CC;
	}

	#emailphonediv label {
		width: 150px;
		clear: none;
		text-align: left;
		margin-right: 10px;
	}

	#emailphonediv input {
		width: 150px;
		clear: none;
		margin-right: 10px;
	}
	#complextiyoptions {
		float: left;
		width: 425px;
		padding-top: 4px;
	}
	#complextiyoptions label {
		float: left;
		text-align: left;
		cursor: pointer;
		width: 195px;
		clear: none;
		margin: 1px 0 2px;
	}
	#complextiyoptions input, #complextiyoptions select {
		float: left;
		clear: left;
		margin: 5px 5px 0 0;
	}
	#complextiyoptions input[type=checkbox] {
		padding: 0;
		border: 0 none;
	}
	#complextiyoptions div {
		clear: both;
	}

	h3 {
		clear: both;

	}
	#startup_contact, #overagedisclaimer, #minimumdisclaimer {
		display: none;
	}
	label.selectlabel {
		padding-top: 9px;
	}
	.checkboxenclosure {
		clear: both;
	}
	.checkboxenclosure input {
		float: left;
	}
	.checkboxenclosure label {
		float: left;
		text-align: left;
		clear: none;
		padding: 0;
		margin: 0 0 0 5px;
		cursor: pointer;
	}
	h4 {
		margin-bottom: 0;
	}
	#discount_note {
		display: none;
	}
	#resetform {
		clear: both;
		padding-top: 7px;
	}
	#resetform a {
		color: gray;
		text-decoration: none;
		font-size: 12px;
	}



	.checkboxlabel input {
		margin: 0 5px 0 0;
	}
	.checkboxlabel {
		width: auto;
		display: flex;
		align-items: center;
	}
	#please_describe {
		margin-left: 10px;
	}
}


// Groundswell request quote form
.groundswell-client-quote {
	.submit {
		margin-bottom: 0;
		&[disabled="disabled"] {
			background: gray;
		}
	}
	.label {
		font-size: rem-calc(20);
		font-weight: bold;
		color: $brand-alt;
		line-height: 1.5;
		margin-bottom: 5px;
		span.subtext {
			font-weight: normal;
		}
	}
	.checkbox-item {
		label {
			color: $main-color;
		}
		&.hidden-child {
			display: block;
			.sub-field {
				margin-top: 5px;
			}
		}
		p {
			font-family: $main-font;
			font-size: rem-calc(14);
			line-height: 1.29;
		}
		.sub-field {
			label {
				font-size: rem-calc(14);
				line-height: 1.29;
			}
		}
	}
	.checkbox-item-label {
		display: flex;
		align-items: center;
		gap: 8px;
		input {
			margin: 0;
		}
		label {
			color: $main-color;
		}
	}
	.size-selections {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		grid-gap: 30px;
		margin-bottom: 30px;
		select {
			margin-bottom: 0;
		}
	}
	.label-tip {
		display: flex;
		align-items: center;
		gap: 7px;
		svg {
			width: 15px;
			height: 15px;
		}
	}
	.checkbox-group {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-gap: 15px 30px;
		margin-bottom: 30px;
	}

	.checkbox-group.single {
		display: block;
		.checkbox-item {
			max-width: 400px;
			margin: 0 auto;
			display: block;
		}
		label {
			font-size: rem-calc(32);
		}
		select {
			height: 70px;
			font-size: rem-calc(24);
		}
	}
	.field-full {
		grid-column: 1 / -1;
	}
	.organization-fields {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		grid-gap: 4px 30px;
		label {
			color: $main-color;
		}
	}
	.recaptcha-error {
		color: $white;
	}
}
.groundswell-quote-total {
	margin-top: 50px;
	h2 {
		font-size: rem-calc(40);
		font-weight: bold;
		color: $dark-gray;
		line-height: 1.1;
		padding-bottom: 0;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
	}
	.quote-monthly-total {
		font-family: $heading-font;
		font-size: rem-calc(18);
		font-weight: $heading-bold;
		color: $main-color;
		text-align: center;
		margin-bottom: 15px;
		padding: 0;
		&::after {
			display: none;
		}
		span {
			font-size: rem-calc(60);
			color: $brand-alt;
			line-height: 1;
			display: inline-block;
		}
	}
	.quote-tax-total-wrapper {
		text-align: center;
	}
	.quote-tax-total-divider {
		font-size: rem-calc(24);
	}
	.quote-tax-total {
		font-family: $heading-font;
		font-size: rem-calc(18);
		font-weight: $heading-bold;
		text-align: center;
		margin-bottom: 0;
		span {
			font-size: rem-calc(27);
			color: $brand-alt;
			line-height: 1;
			display: inline-block;
		}
		&.large span {
			font-size: rem-calc(60);
		}
	}
	.quote-tax-subtitle {
		font-family: $main-font;
		font-size: 1rem;
		line-height: 1.5;
	}
	.quote-includes-button {
		text-align: center;
		margin-top: 20px;
		> button {
			font-family: $main-font;
			font-weight: bold;
			color: $brand-ally;
			transition: color .3s;
			&:hover, &:focus {
				color: $brand-alt;
			}
		}
	}
}

.groundswell-client-quote-results {
	background: $brand-alt;
	padding: 30px;
	margin: 60px 0;
	h2 {
		font-size: rem-calc(40);
		font-weight: bold;
		color: $white;
		text-align: left;
		line-height: 1.1;
		padding-bottom: 0;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
	}
	p {
		color: $white;
	}
	.header {
		margin-bottom: 30px;
	}
	.organization-fields label {
		color: $white;
	}
}

.groundswell-client-quote-results.light {
	background: $off-white;
	padding: 40px 30px;
	margin: 60px 0;
	h2 {
		font-size: rem-calc(40);
		font-weight: bold;
		color: $dark-gray;
		text-align: center;
		line-height: 1.1;
		padding-bottom: 0;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
	}
	p {
		color: $main-color;
	}
	.header {
		margin-bottom: 60px;
	}
	.organization-fields label {
		color: $main-color;
	}
	.quote-monthly-total {
		font-family: $heading-font;
		font-size: rem-calc(18);
		font-weight: $heading-bold;
		text-align: center;
		margin-bottom: 15px;
		span {
			font-size: rem-calc(60);
			color: $brand-alt;
			line-height: 1;
			display: inline-block;
		}
	}
	.quote-tax-total-wrapper {
		text-align: center;
	}
	.quote-tax-total-divider {
		font-size: rem-calc(24);
	}
	.quote-tax-total {
		font-family: $heading-font;
		font-size: rem-calc(18);
		font-weight: $heading-bold;
		text-align: center;
		margin-bottom: 15px;
		span {
			font-size: rem-calc(27);
			color: $brand-alt;
			line-height: 1;
			display: inline-block;
		}
		&.large span {
			font-size: rem-calc(60);
		}
	}
	input, textarea {
		border-color: $brand-alt;
	}
}


// Quote request overview page
.quote-request-path {
	.intro-section {
		text-align: center;
		padding-bottom: 20px;
		p {
			margin: 0;
		}
	}
	.tippy-tooltip-button {
		font-family: $alt-font;
		font-weight: $alt-normal;
		color: $brand-ally;
	}
	hr {
		margin: 45px auto;
	}
}

.quote-request-path-options {
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 15px 30px;
	.button {
		background: none;
		font-weight: $alt-normal;
		color: $brand-ally;
		padding: 20px;
		border: solid 4px $light-gray;
		margin: 0;
		text-align: center;
		flex-grow: 1;
		transition: border-color .3s;
		&:hover, &:focus {
			border-color: $brand-alt;
		}
	}
}

@include breakpoint(580px) {
	.quote-request-path-options {
		flex-wrap: nowrap;
	}
}

.quote-request-alt-callout {
	max-width: 780px;
	text-align: center;
	margin: 0 auto 40px;
	h2 {
		font-size: rem-calc(20);
		font-weight: $alt-bold;
		line-height: 1.5;
		padding-bottom: 0;
		margin-bottom: 5px;
		&::after {
			display: none;
		}
	}
	p {
		margin-bottom: 10px;
	}
	.cta-link {
		font-family: $alt-font;
		font-size: rem-calc(18);
		font-weight: $alt-normal;
		color: $brand-ally;
		line-height: 1.5;
		&:hover, &:focus {
			color: $brand-alt;
		}
	}
}

// Quote request pages intro
.quote-request-heading {
	font-size: rem-calc(32);
	text-align: left;
	padding: 0;
	margin-bottom: 40px;
	@include breakpoint(medium) {
		font-size: rem-calc(40);
	}
	&::after {
		display: none;
	}
	.subheading {
		font-size: rem-calc(18);
		color: $main-color;
		line-height: 1.5;
		display: block;
		span {
			font-weight: $heading-bold;
			color: $brand-alt;
		}
	}
}

.quote-request-product-callouts-heading {
	font-weight: bold;
	font-style: italic;
	margin-bottom: 4px;
}

.quote-request-product-callouts {
	max-width: 400px;
	margin-bottom: 50px;
	padding: 10px 0;
	border: 1px solid $light-gray;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	&.extra-mb {
		margin-bottom: 60px;
	}
	@include breakpoint(medium) {
		max-width: 100%;
		flex-wrap: nowrap;
		> * {
			flex-basis: 50%;
		}
	}
}

.quote-request-product-callout {
	font-weight: bold;
	padding: 10px 20px;
	margin: 0 auto;
	&:first-child {
		border-bottom: solid 1px $light-gray;
		@include breakpoint(medium) {
			border-right: solid 1px $light-gray;
			border-bottom: 0;
		}
	}
	p {
		margin: 0;
	}
	a {
		color: $main-color;
		text-decoration: none;
		span {
			color: $brand-alt;
		}
		&:hover, &:focus {
			color: $brand-alt;
		}
	}
}

// Quote request callout
.quote-request-callout {
	background: $brand-alt;
	color: $white;
	text-align: center;
	padding: 30px;
	h2 {
		font-size: rem-calc(32);
		color: $white;
		line-height: 1.1;
		padding-bottom: 0;
		margin-bottom: 16px;
		&::after {
			display: none;
		}
	}
	p {
		font-size: rem-calc(18);
		margin-bottom: 20px;
	}
	.button {
		margin-bottom: 0;
	}
}

@include breakpoint(730px) {
	.quote-request-callout {
		padding: 60px 90px;
	}
}


// Tax form specific styles
.groundswell-client-quote .tax-quote-intro-field {
	margin-bottom: 50px;
	select {
		padding-left: 20px;
	}
	> div {
		background: $off-white;
		padding: 20px 30px;
	}
}