
.callout-footer-bar {
	background-color: $brand-alt;
	font-size: rem-calc(18);
	font-family: $alt-font;
	color: $white;
	padding-top: 60px;
	&.pad-bottom {
		padding-bottom: 18px;
	}
	.inner-content {
		max-width: 900px;
		padding: 0 16px;
		margin: 0 auto;
	}
	.heading {
		max-width: 620px;
		text-align: center;
		margin: 0 auto 42px;
		h2 {
			font-size: rem-calc(32);
			color: $white;
		}
	}
	.actions {
		padding-bottom: 60px;
		.action-choices {
			display: flex;
			flex-direction: column;
			gap: 24px;
			text-align: center;

		}
		.or-text {
			background: $white;
			width: 34px;
			height: 34px;
			font-weight: $alt-bold;
			color: $brand-alt;
			text-align: center;
			border-radius: $border-round;
			padding-bottom: 3px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
		}
		.button {
			flex-shrink: 0;
			margin: 0;
		}
	}
	.schedule-call-action {
		max-width: 360px;
		margin: 0 auto;
		label {
			font-size: rem-calc(20);
			color: $white;
			line-height: 1;
			margin-bottom: 5px;
		}
		select {
			font-size: rem-calc(18);
			height: 40px;
			padding-top: 2px;
			padding-bottom: 2px;
			margin: 0;
		}
	}
	.schedule-call-result {
		max-width: 308px;
		font-size: rem-calc(16);
		margin: 0 auto;
		.button {
			margin-bottom: 0;
		}
		> div {
			padding-top: 8px;
		}
	}
	.footer {
		background-color: #6A2773;
		font-size: rem-calc(20);
		text-align: center;
		padding: 16px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			font-weight: $alt-bold;
			display: inline-block;
			margin: 0 15px;
		}
		.title {
			font-weight: $alt-normal;
		}
		a {
			color: $white;
			&:hover, &:focus {
				color: $brand;
			}
		}
	}
}

@include breakpoint(700px) {
	.callout-footer-bar {
		.actions {
			.action-choices {
				text-align: left;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: center;
				> div {
					flex-basis: 43%;
					&:nth-child(2) {
						flex-basis: 14%;
					}
				}
			}
			.or-text-wrapper {
				text-align: center;
			}
			.button-wrapper {
				text-align: right;
			}
		}
		.schedule-call-action {
			margin-left: 0;
			margin-right: 0;
		}
		.schedule-call-result-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			gap: 24px;
			> div {
				flex-basis: 43%;
				&:nth-child(2) {
					flex-basis: 14%;
				}
			}
		}
		.schedule-call-result {
			max-width: 360px;
		}
	}


}


@include breakpoint(large) {
	.callout-footer-bar {
		.heading {
			max-width: 700px;
		}
		.actions .schedule-call-action {
			width: 360px;
		}
		.actions .button-wrapper {
			width: 30%;
		}
		.actions .or-text-wrapper {
			width: 18%;
		}
	}
}