@import '../../vendor/slick/slick';

.slider-wrap {
	padding-right: .9375rem;
	padding-left: .9375rem;
}

.testimonial-slider {
	max-width: 1170px;
	margin: 0 auto;
	blockquote {
		background: none;
		color: $gray;
		padding-left: 0;
		padding-right: 0;
		margin: 0;
		.quote-icon {
			margin: 0 10px 2px 0;
			display: inline-block;
			vertical-align: -3px;
		}
		p {
			color: $gray;
			text-shadow: 0 1px 1px #ffffff;
		}
		cite {
			font-weight: bold;
			.title {
				font-weight: normal;
			}
		}
	}
	.slide {
		height: 435px;
		background-repeat: no-repeat;
		background-position: right bottom;
		padding-bottom: 156px;
	}
	.slider-nav {
		padding: 0;
		margin: 0;
		position: absolute;
		left: 0;
		bottom: 13px;
		li {
			background: none;
			display: inline-block;
			padding: 0;
		}
		button {
			width: 24px;
			height: 24px;
			background-color: $brand-alt-accent;
			font-size: 0;
			border-radius: 50%;
			margin-right: 15px;
			display: block;
			transition: .3s;
			&:hover, &:focus {
				background-color: $brand-alt;
			}
		}
		.slick-active button {
			background-color: $brand-alt;
		}
	}
	// Slide background images
	.slide-1 {
		background-image: url('/images/slides/testimonials/girl_drinking_in_chair.png')
	}
}

@media screen and #{breakpoint(medium)} {
	.testimonial-slider {
		blockquote {
			font-size: rem-calc(20);
			cite {
				font-size: rem-calc(16);
				margin-top: 48px;
			}
		}
		.slide {
			padding-bottom: 200px;
		}
	}
}

@media screen and (min-width: 56.25em) {
	.testimonial-slider {
		blockquote {
			max-width: 565px;
			padding-top: 80px;
		}
		.slide {
			height: 524px;
			padding-bottom: 0;
		}
		.slider-nav {
			bottom: 48px;
		}
		// Slide background images
		.slide-1 {
			background-image: url('/images/slides/testimonials/girl_drinking_in_chair_lrg.png')
		}
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.testimonial-slider {
		.slide-1 {
			background-image: url('/images/slides/testimonials/girl_drinking_in_chair@2x.png');
			background-size: 247px 252px;
		}
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and (min-width: 56.25em),
only screen and (-o-min-device-pixel-ratio: 13/10) and (min-width: 56.25em),
only screen and (min-resolution: 120dpi) and (min-width: 56.25em) {
	.testimonial-slider {
		.slide-1 {
			background-image: url('/images/slides/testimonials/girl_drinking_in_chair_lrg@2x.png');
			background-size: 449px 515px;
		}
	}
}


.text-slider {
	background-color: $off-white;
	padding-left: .9375rem;
	padding-right: .9375rem;
	margin-left: -.9375rem;
	margin-right: -.9375rem;
	.slide {
		height: auto;
		padding-bottom: 64px;
	}
	.slider-nav {
		width: 100%;
		text-align: center;
	}
	p {
		font-style: italic;
	}
}

@media screen and #{breakpoint(medium)} {
	.text-slider {
		background-color: $off-white;
		padding-left: 32px;
		padding-right: 32px;
		margin-left: 0;
		margin-right: 0;
		blockquote {
			max-width: 900px;
			margin: 0 auto;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.text-slider {
		p {
			font-size: rem-calc(24);
		}
		blockquote cite {
			font-size: rem-calc(20);
		}
	}
}