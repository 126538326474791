/*----------------------------------------------------------*\
    Forms

    The majority of the form styling comes from the
    Foundation settings file. Any new forms added that
    need specific styling (like search forms as seen here)
    should be added in this file.

\*----------------------------------------------------------*/


form {
	font-family: $alt-font;
	font-size: 18px;
	.req {
		color: $brand-alt-accent;
	}
	[type="text"],
	[type="password"],
	[type="date"],
	[type="datetime"],
	[type="datetime-local"],
	[type="month"],
	[type="week"],
	[type="email"],
	[type="number"],
	[type="search"],
	[type="tel"],
	[type="time"],
	[type="url"],
	[type="color"],
	textarea {
		height: auto;
		font-size: rem-calc(18);
	}
	textarea {
		line-height: 1.5;
		padding-top: 5px;
		padding-bottom: 10px;
		resize: none;
	}
	select {
		height: auto;
	}
	legend {
		color: $brand-alt;
		margin: 0;
	}
	label {
		line-height: 1.4;
		margin: 0 0 3px;
	}
	.heading-section {
		margin: 32px 0 16px;
		h3 {
			font-size: rem-calc(32);
			color: $brand-alt;
			text-align: left;
			padding: 0;
			margin: 0;
			&::after {
				display: none;
			}
		}
	}
	.g-recaptcha {
		transform: scale(.75);
		transform-origin: 0 25%;
		@include breakpoint(450) {
			transform: none;
		}
	}
}

// Heroku Forms
.h-form {
	width: 755px;
	margin: 0 auto;
	h1 {
		color: #8a3397;
		font-family: "ff-meta-web-pro", "Lucida Grande", sans-serif;
		font-size: 24px;
		line-height: 32px;
		padding: 0;
		margin: 0 0 20px;
		text-align: left;
		&::after {
			display: none;
		}
	}
	h2 {
		color: #8a3397;
		font-family: "ff-meta-web-pro", "Lucida Grande", sans-serif;
		font-size: 20px;
		line-height: 32px;
		&::after {
			display: none;
		}
	}
	h3 {
		color: #58585b;
		font-family: "ff-meta-serif-web-pro", palatino, arial;
		font-size: 18px;
		font-weight: bold;
		line-height: 32px;
		&::after {
			display: none;
		}
	}
	p {
		margin: 20px 0;
	}
	a {
		color: #38bdff;
	}
	a:hover {
		color: #1388C2;
	}
	em {
		font-style: italic;
	}
	.default_selection {
		color: #8a3397;
	}
	label {
		width: 263px;
		font-size: 14px;
		color: #58585B;
		margin: 4px 0;
		padding-top: 3px;
		padding-right: 10px;
		line-height: 18px;
	}
	label.longlabel {
		width: 525px;
	}
	.radioenclosure label {
		width: auto;
		float: left;
		padding: 0 0 0 5px;
		clear: none;
		margin-right: 5px;
		margin-top: 0;
		padding-left: 3px;
	}
	input, select, span {
		float: left;
		margin: 3px 0;
		font-size: 12px !important;
	}
	select {
		margin-top: 2px;
	}
	.clr {
		clear: both;
	}
	.fieldlegend {
		float: left;
		clear: both;
		font-size: 12px;
		margin-bottom: 24px;
		font-style: italic;
		width: 540px;
	}
	.radioenclosure select.inline {
		font-size: 10px;
		margin-right: 17px;
		max-width: 180px;
	}
	.radioenclosure {
		margin-top: 5px;
		float: left;
	}
	.calc_field_row {
		clear: both;
		border-top: 1px solid lightgray;
		display: block;
		margin: 3px 0;
	}
	.calc_field_row.labelleft label {
		text-align: left;
	}
	div.noborder {
		border: none;
	}
	#calc_submit_container {
		margin: 10px 0 25px 0;
		clear: both;
		overflow: auto;
	}
	#calc_submit_container input {
		background-color: #37BDFF;
		border: 1px solid #37BDFF;
		border-radius: 4px 4px 4px 4px;
		color: #FFFFFF;
		font-family: "ff-meta-web-pro","Lucida Grande",sans-serif;
		font-size: 18px !important;
		line-height: 1;
		padding: 15px 28px;
		text-decoration: none;
		margin-right: 24px;
		cursor: pointer;
	}
	#calc_submit_container input:hover {
		background-color: #1388C2;
		border: 1px solid #1388C2;
	}
	span.pointer {
		margin-right: 25px;
		padding-left: 3px;
		cursor: pointer;
	}
	input, select {
		border: 1px solid darkgray;
		padding: 3px;
		font-size: 14px;
	}
	select {
		background-position: right 5px center;
	}
	input[type="radio"] {
		border: none;
		font-size: 18px;
		margin-top: 5px;
	}
	.finalhoursdescript {
		font-weight: bold;
		font-size: 15px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.finalhourbox {
		background-color: #F2F2F2;
		border: 1px solid #DADADB;
		font-weight: bold;
		margin: 20px 0;
		width: 75px;
		text-align: center;
	}
	.disclaimer {
		background-color: #F2F2F2;
		border: 1px solid #DADADB;
		border-radius: 6px;
		padding: 16px;
		font-style: italic;
		margin: 16px 0;
		clear: both;
	}
	.disclaimer p {
		margin-top: 0;
	}
	#wait {
		width: 31px;
		height: 31px;
		background: url('/images/cssimg/ajax-loader.gif') no-repeat;
		visibility: hidden;
		float: left;
		margin-top: 14px;
		margin-right: 14px;
	}
	#submitnow {
		margin-right: 9px;
	}
	table { clear: both; border-collapse: collapse; border-spacing: 0; margin-bottom: 30px; }
	.ykey { white-space: nowrap; }
	tr td, tr th { margin: 0; padding: 3px 7px;}
	th {
		font-size: 14px;
		text-align: center;
		border: 1px solid darkgray;
		font-weight: bold;
	}
	th.ykey {
		border-left: 0 none;
		border-top: 0 none;
		border-bottom: 0 none;
	}
	td {
		font-size: 14px;
		text-align: center;
		border: 1px solid darkgray;
	}
	#b0-num, #b1-num, #b2-num {
		font-size: 12px;
	}
	td.ykey {
		font-size: 13px;
		padding-right: 7px;
		text-align: right;
		border: none;
	}
	#b1-title, #b1-num, #b1-min, #b1-avg, #b1-max, #b1-cost, #b1-overage {
		font-weight: bold;
		background-color: #F7E4CC;
	}
	#emailphonediv label {
		width: 150px;
		clear: none;
		text-align: left;
		margin-right: 10px;
	}
	#emailphonediv input {
		width: 150px;
		clear: none;
		margin-right: 10px;
	}
	#complextiyoptions {
		float: left;
		width: 425px;
		padding-top: 4px;
	}
	#complextiyoptions label {
		float: left;
		text-align: left;
		cursor: pointer;
		width: 195px;
		clear: none;
		margin: 1px 0 2px;
	}
	#complextiyoptions input, #complextiyoptions select {
		float: left;
		clear: left;
		margin: 5px 5px 0 0;
	}
	#complextiyoptions input[type=checkbox] {
		padding: 0;
		border: 0 none;
	}
	#complextiyoptions div {
		clear: both;
	}
	h3 {
		clear: both;
	}
	#startup_contact, #overagedisclaimer, #minimumdisclaimer {
		display: none;
	}
	label.selectlabel {
		padding-top: 9px;
	}
	.checkboxenclosure {
		clear: both;
	}
	.checkboxenclosure input {
		float: left;
	}
	.checkboxenclosure label {
		float: left;
		text-align: left;
		clear: none;
		padding: 0;
		margin: 0 0 0 5px;
		cursor: pointer;
	}
	h4 {
		margin-bottom: 0;
	}
	#discount_note {
		display: none;
	}
	#resetform {
		clear: both;
		padding-top: 7px;
	}
	#resetform a {
		color: gray;
		text-decoration: none;
		font-size: 12px;
	}
	.checkboxlabel input {
		margin: 0 5px 0 0;
	}
	.checkboxlabel {
		width: auto;
		display: flex;
		align-items: center;
	}
	#please_describe {
		max-width: 200px;
		margin-left: 10px;
	}
	.center-fields {
		.calc_field_row {
			display: flex;
			align-items: center;
			label {
				padding-top: 0;
				text-align: right;
			}
			input, select {
				width: 300px;
			}
		}
	}
	.long-label-field {
		display: flex;
		align-items: center;
		label {
			padding-top: 0;
		}
	}
	.field-group-4 {
		display: flex;
	}
}

//Quote request form for Pipedrive API
.quote-request-form {
	.form-button-wrapper {
		display: flex;
		align-items: center;
		img {
			margin: 0 0 0 12px;
		}
	}
	.captcha-field {
		margin-bottom: 20px;
	}
	#catwebformbutton {
		margin: 0;
	}
	#catwebformbutton.disabled {
		background-color: #CCC;
		color: #BCBCBC;
	}
}

//Checkbox groups
.checkbox-group {
	.checkbox-field-list {
		display: flex;
		flex-wrap: wrap;
	}
}

// Simple survey form for thanks pages
.simple-survey-form {
	margin-bottom: 20px;
	p {
		font-size: rem-calc(20);
		color: $brand-ally;
		font-weight: bold;
		margin-bottom: 12px;
	}
	form {
		max-width: 500px;
	}
	.radio-group-options {
		margin-bottom: 30px;
		display: grid;
		gap: 10px 20px;
	}
	.radio-group-option {
		width: 100%;
		line-height: 1.2;
		label {
			font-size: 1rem;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
		input {
			margin: 4px 5px 0 0;
		}
		.sublabel {
			font-size: rem-calc(14);
			font-weight: bold;
			font-style: italic;
			line-height: 1.28;
			margin-top: 4px;
			margin-bottom: 10px;
		}
	}
}