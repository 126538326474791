.thanks-forms {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    align-items: start;
    @include breakpoint(400px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    > div {
        width: 100%;
        max-width: 450px;
        padding: 20px;
        border-radius: 10px;
        margin: 0 auto;
        @include breakpoint(850px) {
            padding: 30px;
        }
    }
    .simple-survey-form {
        background: $off-white;
        margin-bottom: 0;
        .button {
            padding: 9px 35px 12px;
            margin-bottom: 0;
        }
    }
    .simple-email-subscribe {
        .input-field {
            input {
                margin-bottom: 20px;
            }
            label {
                margin-bottom: 20px;
            }
        }
    }
}