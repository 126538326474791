.hero-form {
	background-color: #ecebe6;
	margin-bottom: 48px;
	position: relative;
	overflow: hidden;
	.hero-content {
		padding-top: 47px;
		padding-bottom: 32px;
		position: relative;
	}
	.text {
		max-width: 400px;
		padding-bottom: 32px;
		position: relative;
		z-index: 20;
	}
	.media {
		position: absolute;
		right: -42px;
		bottom: 0;
		z-index: 10;
	}
	h1 {
		font-family: $main-font;
		font-size: rem-calc(32);
		color: $brand-alt;
		line-height: 1.2;
		text-align: left;
		text-shadow: 0 1px 1px $white;
		padding-bottom: 0;
		margin-bottom: 12px;
		&::after {
			display: none;
		}
	}
	.subheading {
		max-width: 225px;
		font-family: $alt-font;
		color: $gray;
		font-size: rem-calc(20);
		line-height: 1.2;
		text-shadow: 0 1px 1px $white;
	}
	form {
		max-width: 300px;
		margin: 28px -.6325rem;
	}
	input[type="text"] {
		border-color: #DDDDDD;
	}
	label {
		font-size: rem-calc(16);
		color: $gray;
	}
	input[type="submit"] {
		font-size: rem-calc(16);
		margin-bottom: 0;
	}
}

@media screen and (min-width: 29.0625em) {
	.hero-form {
		.media {
			right: 10px;
		}
	}
}

@media screen and (min-width: 37.5em) {
	.hero-form {
		.subheading {
			max-width: 100%;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.hero-form form {
		max-width: 100%;
		margin-left: -.9375rem;
		margin-right: -.9375rem;
	}
}

@media screen and (min-width: 46.25em) {
	.hero-form {
		.text {
			max-width: 530px;
		}
		.subheading {
			max-width: 450px;
		}
	}
}

@media screen and (min-width: 50em) {
	.hero-form {
		margin-bottom: 96px;
		overflow: visible;
		.hero-content {
			min-height: 700px;
			padding-top: 64px;
			padding-bottom: 64px;
			display: flex;
			align-items: center;
		}
		.text {
			padding-bottom: 0;
		}
		.media {
			bottom: -108px;
		}
		.contained {
			width: 100%;
		}
		h1 {
			font-size: rem-calc(51);
		}
		.subheading {
			font-size: rem-calc(24);
		}
		label {
			font-size: rem-calc(18);
		}
	}
}

@media screen and (min-width: 56.25em) {
	.hero-form {
		.text {
			max-width: 565px;
		}
		.subheading {
			max-width: 100%;
		}
	}
}

@media screen and (min-width: 70em) {
	.hero-form {
		.text {
			max-width: 665px;
		}
		.media {
			right: 56px;
		}
	}
}


// Template elements
.groundswell-template h2 {
	font-size: rem-calc(28);
	padding-bottom: 0;
	&::after {
		display: none;
	}
	&.section-heading {
		margin-bottom: 48px;
	}
}

@media screen and #{breakpoint(medium)} {
	.groundswell-template h2 {
		font-size: rem-calc(38);
		&.section-heading {
			margin-bottom: 96px;
		}
	}
}

// Client example section
.groundswell-template .client-examples {
	margin-bottom: 10px;
	p {
		font-size: rem-calc(24);
		color: $gray;
		line-height: 1.2;
		text-shadow: 0 1px 1px $white;
		margin: 0;
	}
}

@media screen and #{breakpoint(medium)} {
	.groundswell-template .client-examples {
		margin-bottom: 46px;
		p {
			font-size: rem-calc(28);
		}
	}
}

// Callout Form
.groundswell-template .callout-form {
	padding-left: 15px;
	padding-right: 15px;
	margin-left: -.9375rem;
	margin-right: -.9375rem;
	h2 {
		font-size: rem-calc(30);
	}
}

@media screen and #{breakpoint(large)} {
	.groundswell-template .callout-form {
		padding-left: 77px;
		padding-right: 77px;
		margin-left: auto;
		margin-right: auto;
	}
}

// Employee Testimonials
.groundswell-template .employee-testimonials {
	background-image: linear-gradient(180deg, $white 0%, #ecebe6 100%);
	margin-top: 57px;
	margin-bottom: 72px;
	h2 {
		margin-bottom: 48px;
	}
}

@media screen and #{breakpoint(medium)} {
	.groundswell-template .employee-testimonials {
		margin-top: 96px;
		margin-bottom: 120px;
	}
}

// Jitasa flow
.jitasa-flow {
	color: $gray;
	h2 {
		color: $gray;
		text-align: left;
	}
	.flow-intro {
		margin-bottom: 48px;
		position: relative;
		&::before {
			content: '';
			height: 100px;
			width: 1px;
			border-left: dashed 2px #979797;
			position: absolute;
			bottom: -105px;
			left: 30px;
		}
	}
	.flow-card {
		background-color: $off-white;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
		margin: 0 auto 64px;
		position: relative;
		z-index: 20;
		.heading {
			background-color: $brand-alt;
			padding: 13px 15px;
		}
		.details {
			padding: 24px 15px 1px;
			.row {
				margin-left: -.6375rem;
				margin-right: -.6375rem;
			}
		}
		h3 {
			font-size: rem-calc(24);
			font-weight: $alt-bold;
			color: $white;
			line-height: 1.2;
			text-align: left;
			padding-bottom: 0;
			margin-bottom: 0;
			&::after {
				display: none;
			}
		}
		h4 {
			font-size: rem-calc(20);
			font-weight: $alt-bold;
			color: $gray;
			line-height: 1.2;
			text-align: left;
			text-shadow: 0 1px 1px $white;
			padding-bottom: 0;
			margin-bottom: 12px;
			display: flex;
			&::after {
				display: none;
			}
			.icon {
				font-size: 24px;
				color: $brand;
				margin-right: 10px;
			}
		}
	}
	.flow-beginning::after,
	.flow-during::after {
		content: '';
		height: 100px;
		width: 1px;
		border-left: dashed 2px #979797;
		position: absolute;
		left: 50%;
	}
}

@media screen and #{breakpoint(medium)} {
	.jitasa-flow {
		.flow-intro {
			font-size: rem-calc(20);
			position: relative;
			&::before {
				left: 15%;
			}
		}
		.flow-beginning {
			max-width: 570px;
		}
		.flow-during {
			max-width: 770px;
		}
		.flow-end {
			max-width: 570px;
		}
	}
}

@media screen and (min-width: 50em) {
	.jitasa-flow {
		.flow-card {
			margin-bottom: 72px;
		}
		.flow-intro {
			max-width: 400px;
			&::before {
				display: none;
			}
			&::after {
				content: '';
				width: 100px;
				height: 170px;
				border-right: dashed 2px #979797;
				border-top: dashed 2px #979797;
				position: absolute;
				top: 80px;
				right: -100px;
			}
		}
		.flow-beginning::after {
			left: 20%;
		}
		.flow-during::after {
			right: 25%;
			left: auto;
		}
	}
}

@media screen and (min-width: 61.25em) {
	.jitasa-flow {
		.flow-intro {
			max-width: 565px;
			&::after {
				content: '';
				width: 142px;
				right: -142px;
			}
		}
	}
}

// Simple Cta
.groundswell-template .simple-cta {
	margin-bottom: 81px;
	position: relative;
	&::before {
		content: '';
		height: 100px;
		width: 1px;
		border-left: dashed 2px #979797;
		position: absolute;
		left: 50%;
		top: -80px;
		z-index: 1;
	}
}

@media screen and #{breakpoint(medium)} {
	.groundswell-template .simple-cta {
		margin-bottom: 104px;
	}
}