/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/

ul {
	padding: 0 0 0 1em;
	margin: 1.25em 0;
	li {
		background-image: url('/images/cssimg/bg_li.png');
		background-repeat: no-repeat;
		background-position: 0 6px;
		padding: 0 0 0 20px;
		margin: 0 0 .5em;
		list-style: none;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
	}
	li ul {
		margin: .666em 0 .75em;
		li {
			padding-left: 24px;
		}
	}
	&.gray li {
		background-image: url('/images/cssimg/bg_li_gray.png');
	}
}
ol {
	margin: 1.25em 0 1.25em 1em;
	li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}
ul.bare {
	li {
		background: none !important;
		padding-left: 0 !important;
		position: relative;
	}
}
ul.feature-list {
	padding: 0;
	margin: 0;
	text-align: left;
	justify-content: center;
	li {
		background: none;
		font-size: rem-calc(20);
		color: $brand-accent;
		line-height: 1.2;
		padding-left: 26px;
		padding-right: 20px;
		margin: 15px 0;
		position: relative;
		&::before {
			content: '\f001';
			font-family: $icon-font;
			font-size: 16px;
			color: $white;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
.flex-list {
	display: flex;
	flex-direction: column;
}

.grid-item-list {
	display: grid;
	grid-gap: 30px 20px;
	.dark-section &.simple-list li,
	li {
		margin-bottom: 0;
	}
}

@media screen and #{breakpoint(medium)} {
	ul {
		li {
			background-position: 2px 8px;
		}
	}
	ul.split-list-2,
	ol.split-list-2 {
		margin-left: 0;
		padding-left: 0;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		padding-left: 0;
		margin-left: 0;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-4 li,
	ol.split-list-4 li {
		float: left;
		width: 25%;
		padding-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
	ul.split-list-4 li:nth-child(3n+1),
	ol.split-list-4 li:nth-child(3n+1) {
		clear:left;
	}
	.flex-list {
		padding: 0;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		&.flex-list-4 {
			gap: 0 20px;
			li {
				flex-basis: 33.333%;
			}
		}
		&.flex-list-3 {
			li {
				width: 33.333%;
			}
		}
	}
	.grid-list-4 {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
	}
}

@media screen and #{breakpoint(large)} {
	ul.split-list-4 li:nth-child(3n+1),
	ol.split-list-4 li:nth-child(3n+1) {
		clear:none;
	}
	ul.split-list-4 li:nth-child(4n+1),
	ol.split-list-4 li:nth-child(4n+1) {
		clear:left;
	}
	.flex-list {
		&.flex-list-4 {
			li {
				flex-basis: 25%;
			}
		}
	}
	.grid-list-4 {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	ul li {
		background-image: url('/images/cssimg/bg_li@2x.png');
		background-size: 10px 10px;
	}
}

/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
	}
	li ul {
		margin: 0;
	}
	li ul li {
		background: none;
		margin: 0;
	}
	li ul li ul {
		display: none !important;
	}
	li ul li ul li {
		background: none;
	}
}
/* @end */

//Horizontal list with separators
.horizontal-list-dividers {
	font-family: $alt-font;
	font-size: rem-calc(16);
	text-align: center;
	padding: 0;
	li {
		background: none;
		display: block;
		border-bottom: solid 2px $off-white;
		padding-bottom: 10px;
		margin-bottom: 10px;
		padding-left: 0;
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;
		}
		&::before {
			display: none;
		}
	}
}

@include breakpoint(medium) {
	.horizontal-list-dividers {
		li {
			display: inline-block;
			border-bottom: none;
			border-right: solid 2px $off-white;
			padding-right: 30px;
			margin-right: 30px;
			padding-left: 0;
			padding-bottom: 0;
			margin-bottom: 0;
			&:last-child {
				border-right: 0;
				padding-right: 0;
				margin-right: 0;
			}
		}
	}
}