/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

html {
	scroll-padding: 90px;
	scroll-behavior: smooth;
}

body {
	background: $white;
	font-family: $main-font;
	font-size: 16px;
	color: $main-color;
	padding-top: 50px;
	@include breakpoint(medium) {
		padding-top: 80px;
	}
}
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 20px;
	&.intro {
		font-size: rem-calc(18);
	}
}
a, .text-link {
	color: $brand-alt;
	transition: color .3s, text-decoration .3s;
	text-decoration-skip-ink: auto;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 4px;
	text-decoration-color: $brand;
	&:hover, &:focus {
	    text-decoration-color: currentColor;
	    transition: text-decoration 0;
	}
}
hr {
	width: 80%;
	height: 1px;
	background-image: linear-gradient(to right, rgba(#979797, 0), rgba(#979797, 0.5), rgba(#979797, 0));
	color: $white;
	border: none;
	margin: 1.5rem auto;
	display: block;
	position: relative;
	overflow: visible;
	&::before {
		content: '';
		width: 30px;
		height: 30px;
		background: url('/images/cssimg/flames_circle.png') no-repeat center center;
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
}

.switch {
	font-family: $alt-font;
}
/* @end */