/*------------------------------------*\
    @group Pricing Section
\*------------------------------------*/

.pricing-plans {
	background: $dark-gray;
	padding: 50px 0;
	h2 {
		color: $white;
	}
	.pricing-plan-heading {
		text-align: center;
		color: $white;
		button {
			color: $white;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.pricing-plan-footer {
		font-size: rem-calc(20);
		color: $white;
		text-align: center;
		margin-top: 30px;
		h3 {
			padding-bottom: 0;
			&::after {
				display: none;
			}
		}
		.button-group {
			.button {
				margin-left: 10px;
				margin-right: 10px;
			}
		}
		> .contained-narrow {
			margin-bottom: 48px;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.pricing-plans {
		padding: 100px 0;
		.pricing-plan-footer {
			font-size: rem-calc(24);
		}
	}
}


//Light color theme for pricing plans
.pricing-plans.light {
	background-color: $off-white;
	h2 {
		color: $brand-alt;
	}
	.pricing-plan-footer {
		color: $main-color;
	}
}

/* @end Pricing Section */