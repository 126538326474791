.side-by-side-img-center {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-end;
	gap: 20px;
	> div {
		max-width: 300px;
		height: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}