.local-link-lists {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px 50px;
    @include breakpoint(580px) {
        grid-template-columns: 1fr 1fr;
    }
    @include breakpoint(880px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include breakpoint(1180px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.local-link-list {
    @include breakpoint(580px) {
        &.link-columns-2,
        &.link-columns-3,
        &.link-columns-4 {
            grid-column: span 2;
        }
    }
    @include breakpoint(880px) {
        &.link-columns-3,
        &.link-columns-4 {
            grid-column: span 3;
        }
    }
    @include breakpoint(1180px) {
        &.link-columns-4 {
            grid-column: span 4;
        }
    }
    h2 {
        font-size: rem-calc(26);
        text-align: left;
        line-height: 1.1;
        padding: 0 0 10px;
        margin-bottom: 10px;
        border-bottom: solid 2px $light-gray;
        &::after {
            display: none;
        }
        a {
            color: $main-color;
            text-decoration: none;
            &:hover, &:focus {
                color: $brand;
            }
        }
    }
    a {
        text-decoration: none;
    }
    ul {
        font-size: rem-calc(18);
        padding: 0;
        margin: 0 0 30px;
        &[class^="link-columns-"] {
            column-count: auto;
            column-width: 200px;
        }
    }
    li {
        background: none;
        line-height: 1.25;
        padding: 0;
        margin: 0 0 10px;
    }

}