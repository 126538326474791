.copy-link-wrapper {
	margin-bottom: 50px;
	display: flex;
	justify-content: center;
}
.copy-link {
	border: solid 1px $gray;
	border-radius: 10px;
	overflow: hidden;
	display: inline-flex;
	flex-wrap: wrap;
	gap: 0 20px;
	align-items: stretch;
	p {
		font-family: $heading-font;
		font-weight: bold;
		color: $dark-blue;
		padding: 20px;
		margin: 0;
		overflow-x: scroll;
		overflow-y: hidden;
		button {
			text-align: left;
			white-space: nowrap;
		}
	}
	.copy-button {
		width: 100%;
		background: $brand-alt;
		font-family: $heading-font;
		font-weight: $heading-bold;
		color: $white;
		padding: 20px;
		gap: 5px;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-color .3s;
		&:hover, &:focus {
			background-color: $dark-blue;
		}
		.icon {
			display: flex;
		}
	}
}

@include breakpoint(640px) {
	.copy-link {
		flex-wrap: nowrap;
		.copy-button {
			width: auto;
		}
	}
}