.resource-extra-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	grid-column-gap: 30px;
	.card {
		max-width: 400px;
		.heading {
			font-size: rem-calc(24);
		}
	}
}

@include breakpoint(medium) {
	.resource-download-template {
		h1 {
			max-width: 800px;
			font-size: rem-calc(40px);
		}
	}
}