.success-story-template {
	.hero {
		background: $gradient-white-reverse;
		height: auto;
		padding: 51px 0 28px;
		margin-bottom: 5px;
		text-align: left;
		h1 {
			font-family: $heading-font;
			color: $gray;
			text-align: left;
			text-shadow: $text-shadow;
			margin: 0 0 12px;
			.title {
				font-size: rem-calc(14);
				font-weight: $alt-bold;
				color: $brand-ally;
				text-transform: uppercase;
				display: block;
			}
		}
		p {
			max-width: 100%;
			font-size: 1rem;
			line-height: 1.5;
			margin-bottom: 24px;
		}
		.media {
			text-align: center;
			img {
				background-color: $white;
				padding: 17px;
				box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
			}
			.share-options {
				.share-title {
					font-family: $alt-font;
					color: $brand-alt;
					display: block;
					margin-bottom: 4px;
				}
			}
		}
	}
}

@media screen and (min-width: 44.375em) {
	.success-story-template {
		.hero {
			padding-top: 125px;
			.inner-content {
				display: flex;
				align-items: center;
			}
			.content {
				padding-right: 42px;
			}
			.media {
				flex-shrink: 0;
			}
		}
	}
	.success-story-inline-logo {
		display: flex;
		align-items: center;
		gap: 20px;
		> * {
			margin-bottom: 20px;
		}
	}
}

@media screen and (min-width: 55em) {
	.success-story-template {
		.hero {
			p {
				font-size: rem-calc(24);
			}
			.media img {
				padding: 25px;
			}
		}
	}
}