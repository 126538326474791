.logo-grid {
	margin: 50px 0;
	display: grid;
	align-items: center;
	justify-items: center;
	grid-gap: 45px;
	grid-template-columns: 1fr;
}

@include breakpoint(400px) {
	.logo-grid {
		grid-template-columns: 1fr 1fr;
	}
}

@include breakpoint(750px) {
	.logo-grid {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@include breakpoint(1000px) {
	.logo-grid {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@include breakpoint(1250px) {
	.logo-grid {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
}

.logo-grid-item {
	img {
		margin: 0;
	}
}

.logo-grid-item.title-top {
	height: 100%;
	a {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: stretch;
	}
	img {
		margin: 0;
	}
	.image {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.title {
		font-family: $heading-font;
		font-size: rem-calc(18);
		font-weight: $heading-bold;
		color: $main-color;
		margin-bottom: 10px;
		transition: color .3s;
		display: block;
	}
	&:hover, &:focus-visible {
		.title {
			color: $brand-alt;
		}
	}
}