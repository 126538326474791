.careers-intro-section {
	h2 {
		font-family: $main-font;
		font-size: rem-calc(21);
		color: $main-color;
		line-height: 1.5;
		text-align: left;
		padding: 0;
		margin-bottom: 26px;
		&::after {
			display: none;
		}
	}
	p {
		font-size: rem-calc(16);
	}
	.text {
		max-width: 500px;
		margin: 0 auto 32px;
	}
	.media {
		max-width: 500px;
		margin: 0 auto;
	}
}

@media screen and #{breakpoint(medium)} {
	.careers-intro-section {
		h2 {
			font-size: rem-calc(28);
		}
		p {
			font-size: rem-calc(21);
		}
		.text {
			max-width: 650px;
		}
		.media {
			max-width: 650px;
		}
	}
}

@include breakpoint(1000px) {
	.careers-intro-section {
		.contained {
			display: flex;
			justify-content: space-between;
		}
		.text {
			max-width: 100%;
			width: 33%;
		}
		.media {
			max-width: 100%;
			width: 66%;
			padding-left: 64px;
		}
	}
}

// Career Icons Section
.careers-icon-section {
	padding: 32px 0 0;
	h2 {
		margin-bottom: 64px;
	}
	.icon-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.icon-grid-item {
			width: 48%;
			text-align: center;
			margin-bottom: 45px;
			img {
				height: 55px;
			}
			p {
				font-size: rem-calc(16);
				margin-bottom: 0;
			}
		}
	}
}

@include breakpoint(medium) {
	.careers-icon-section {
		.icon-grid .icon-grid-item {
			p {
				font-size: rem-calc(21);
			}
		}
	}
}

@include breakpoint(900px) {
	.careers-icon-section {
		padding: 60px 0 0;
		h2 {
			margin-bottom: 75px;
		}
		.icon-grid .icon-grid-item {
			width: 31%;
			margin-bottom: 90px;
			&:nth-child(4) {
				margin-left: auto;
			}
			&:nth-child(5) {
				margin-right: auto;
			}
		}
	}
}

@include breakpoint(1100px) {
	.careers-icon-section {
		.icon-grid .icon-grid-item {
			p {
				font-size: rem-calc(28);
			}
		}
	}
}

// Careers venn diagram section
.careers-venn-section {
	text-align: center;
	padding-bottom: 90px;
	h2 {
		margin-bottom: 30px;
	}
	img {
		margin: 0;
	}
}

@include breakpoint(medium) {
	.careers-venn-section {
		padding-bottom: 120px;
		h2 {
			margin-bottom: 60px;
		}
	}
}

// Careers about boise section
.careers-boise-section {
	img {
		margin: 16px 0 32px;
	}
	p {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}
}

@include breakpoint(medium) {
	.careers-boise-section {
		padding-top: 45px;
		img {
			margin: 16px 0 48px;
		}
		p {
			font-size: rem-calc(21);
		}
	}
}

// Careers apply section
.careers-apply-section {
	background-color: $brand-alt;
	color: $white;
	text-align: center;
	padding: 32px 0;
	h2 {
		color: $white;
	}
	a:not(.button) {
		color: lighten($brand, 15%);
		text-shadow: 2px 2px rgba(0,0,0, .4);
		&:hover, &:focus {
			color: $white;
		}
	}
	p {
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;
	}
	.button {
		margin: 0;
	}
}

@include breakpoint(medium) {
	.careers-apply-section {
		padding: 64px 0;
		p {
			font-size: rem-calc(21);
		}
	}
}