/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: "meta-pro", "Lucida Grande", sans-serif;
$serif-font: "meta-serif", palatino, georgia;
$icon-font: 'Jitasa';
$heading-font: $sans-serif-font;
$main-font: $serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 500;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;


//Styleguide colors
$brand:				 #38bfff;
$brand-ally:		 #222898;
$brand-light:		 #69cfff;
$brand-lightest:	 #cef0ff;
$brand-accent:		 #9bdfff;
$brand-alt:			 #8d3499;
$brand-alt-light:	 #a966b2;
$brand-alt-lightest: #e3cce6;
$brand-alt-accent:	 #c699cc;
$dark-gray:			 #292929;
$gray:          	 #595959;
$medium-gray:        #CCCCCC;
$light-gray:         #eeeeee;
$white:              #FFFFFF;
$off-white:          #f7f7f7;
$black:              #000000;

$dark-blue: 		 #1f2799;
$bright-blue: 		 #005af0;
$orange:             #ff6b03;
$yellow: 			 #ffc71c;
$green: 			 #8ac53f;


//Gradients, for use on backgrounds only
$gradient-white:     linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%);
$gradient-white-reverse:     linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(231, 231, 231, 1) 100%);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $dark-gray;

//Social media brand colors
$brand-twitter: 		 #00aced;
$brand-facebook: 		 #3b5998;
$brand-googleplus: 		 #dd4b39;
$brand-pinterest: 		 #cb2027;
$brand-linkedin: 		 #007bb6;
$brand-youtube: 		 #bb0000;
$brand-vimeo: 			 #aad450;
$brand-instagram: 		 #517fa4;
$brand-quora: 			 #a82400;
$brand-foursquare: 		 #0072b1;
$brand-stumbleupon: 	 #EB4823;
$brand-soundcloud: 		 #ff3a00;

//Shadows
$text-shadow: 0 1px 1px white;
$text-shadow-light: 0 1px 1px $black;
$box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);;

//Rounded corners
$border-round: 1000px;
$border-radius: 4px;