.number-counter-wrapper {
	text-align: center;
	margin-bottom: 60px;
}
.number-counter-box {
	background: $off-white;
	font-family: $heading-font;
	font-size: rem-calc(22);
	padding: 10px 20px 12px;
	display: inline-block;
	@include breakpoint(medium) {
		font-size: rem-calc(30);
	}
}

.number-counter-total {
	font-weight: $heading-bold;
	color: $brand-alt;
}