.alert-bar {
	width: 100%;
	background-color: $brand-alt;
	font-family: $alt-font;
	font-weight: $alt-bold;
	color: $white;
	padding: 13px 16px;
	text-align: center;
	position: relative;
	z-index: 1000;
	top: 0;
	a {
		color: lighten($brand, 18%);
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-underline-offset: 3px;
		&:hover, &:focus {
			color: lighten($brand, 30%);
		}
	}
	p {
		margin: 0;
	}
}