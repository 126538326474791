.heading-text-box {
    background: $off-white;
    max-width: 560px;
    font-size: rem-calc(16);
    padding: 20px;
    margin: 0 auto 20px;
    > *:last-child {
        margin-bottom: 0;
    }
    .heading {
        font-family: $heading-font;
        font-size: rem-calc(22);
        font-weight: $heading-normal;
        color: $brand-ally;
        text-align: left;
        line-height: 1.1;
        padding: 0;
        margin: 0 0 14px;
        &::after {
            display: none;
        }
    }
}

@include breakpoint(800px) {
    .heading-text-box {
        font-size: rem-calc(20);
        .heading {
            font-size: rem-calc(28);
        }
    }
}