/*----------------------------------------------------------*\
   Tables

	The majority of the table styling comes from the
    Foundation settings file.

\*----------------------------------------------------------*/

table {
	margin-bottom: 2rem;
	th {
		font-size: 1.125em;
		line-height: 1.2;
		border: solid 1px $white;
	}
	td {
		border: solid 1px $white;
	}
}

.responsive-table {
	margin-bottom: 40px;
	&.small-grid {
		.responsive-table-row {
			display: grid;
			background-color: $white;
			text-align: center;
		}
	}
}

.responsive-table-row {
	border-bottom: solid 1px $light-gray;
	padding-bottom: 20px;
	margin-bottom: 20px;
	&.main-heading-row {
		display: none;
	}
	p {
		margin-bottom: 0;
	}
	.title-col {
		margin-bottom: 20px;
		p {
			font-weight: bold;
		}
		a {
			text-decoration: none;
		}
	}
	.title-col .row-heading {
		font-family: $alt-font;
		font-size: rem-calc(14);
		font-weight: $alt-bold;
		color: $gray;
		line-height: 1.29;
		margin-bottom: 0;
	}
	.details-col .row-heading {
		font-family: $alt-font;
		font-size: rem-calc(14);
		font-weight: $alt-bold;
		color: $gray;
		margin-bottom: 5px;
	}
	&.col-1,
	&.col-4 {
		grid-template-columns: 1fr;
	}
}

@include breakpoint(medium) {
	.responsive-table.small-grid {
		.responsive-table-row {
			text-align: left;
			&:nth-child(even) {
				background-color: $light-gray;
			}
			th {
				text-align: center;
			}
		}
	}
	.responsive-table-row {
		border-bottom: none;
		padding-bottom: 0;
		margin-bottom: 0;
		display: grid;
		grid-template-columns: 1fr 2fr;
		&.main-heading-row {
			display: grid;
			p {
				font-family: $alt-font;
				font-size: rem-calc(24);
				font-weight: $alt-bold;
				line-height: 1.25;
			}
		}
		.title-col {
			padding: 20px 20px 20px 0;
			border-right: solid 1px $light-gray;
			border-bottom: solid 1px $light-gray;
			margin-bottom: 0;
		}
		.details-col {
			padding: 20px 0 20px 20px;
			border-bottom: solid 1px $light-gray;
		}
		.title-col .row-heading {
			display: none;
		}
		.details-col .row-heading {
			display: none;
		}
		&.col-4 {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}