/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    color: $white;
}

.page-footer .footer-top {
    background: $dark-gray;
    padding: 3rem 0;
    overflow: hidden;
    a:not(.button) {
        color: $brand;
        &:focus, &:hover {
            color: $brand-accent;
        }
    }
    .heading {
        font-size: rem-calc(20);
        font-weight: 700;
        text-align: center;
    }
    .footer-inner {
        display: grid;
        grid-template-columns: 1fr;
    }
    .footer-social {
        text-align: center;
        margin-bottom: 2rem;
        img {
            display: block;
            margin: 0 auto 1.5rem;
        }
        .social-icon {
            background: $white;
            width: 36px;
            height: 36px;
            font-size: 24px;
            color: $dark-gray;
            line-height: 1;
            border-radius: $border-round;
            margin-left: 5px;
            margin-bottom: .5rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            &:hover, &:focus {
                color: $brand;
            }
            &:first-child {
                margin-left: 0;
            }
            &.twitter {
                svg {
                    max-width: 22px;
                }
                &:hover, &:focus {
                    color: $brand-twitter;
                }
            }
            &.facebook {
                &:hover, &:focus {
                    color: $brand-facebook;
                }
            }
            &.linkedin {
                svg {
                    max-width: 21px;
                }
                &:hover, &:focus {
                    color: $brand-linkedin;
                }
            }
            &.rss {
                svg {
                    max-width: 19px;
                }
                &:hover, &:focus {
                    color: #FF6600;
                }
            }
        }
    }
    .footer-contact {
        background: #363636;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
        margin-left: -.9375rem;
        margin-right: -.9375rem;
        .contact-option {
            padding-left: 30px;
            position: relative;
            &::before {
                content: '';
                background-size: contain;
                line-height: 1;
                position: absolute;
                left: 0;
                top: 5px;
                display: block;
            }
            &.address::before {
                background: url('/images/icons/location_pin.svg') no-repeat left top;
                width: 21px;
                height: 28px;
            }
            &.phone::before {
                background: url('/images/icons/phone.svg') no-repeat left top;
                width: 19px;
                height: 28px;
            }
            &.email::before {
                content: '@';
                font-family: $alt-font;
                font-weight: $alt-bold;
                font-size: 24px;
                color: $brand;
                top: -4px;
            }
            &.phone p {
                padding-top: 6px;
            }
        }
        .contact-inner {
            max-width: 220px;
            margin: 0 auto;
        }
        .button-group {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }
    }
    .footer-locations {
        max-width: 320px;
        float: none;
        padding-top: 1rem;
        margin: 0 auto 1rem;
        img {
            margin: 0;
        }
        .location-map-wrapper {
            position: relative;
            .button {
                width: max-content;
                padding: 8px 20px 12px;
                margin: 0;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

    }
    .footer-feedback {
        font-family: $alt-font;
        font-size: rem-calc(20);
        font-weight: $alt-bold;
        margin: 20px 0 0;
    }
}

@include breakpoint(800px) {
    .page-footer .footer-top {
        .heading {
            text-align: left;
        }
        .footer-inner {
            grid-template-columns: 3fr 5fr 4fr;
            gap: 30px;
        }
        .footer-social {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .footer-contact {
            background: none;
            padding-bottom: 0;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
            .button-group {
                justify-content: flex-start;
            }
        }
        .footer-locations {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .footer-feedback {
            br {
                display: none;
            }
        }
    }
}

@media screen and (min-width:50em) {
    .page-footer .footer-top {
        .footer-inner {
            grid-template-columns: 5fr 3fr 4fr;
        }
    }
}

.page-footer .footer-bottom {
    background: $brand-ally;
    font-family: $alt-font;
    font-size: 14px;
    text-align: center;
    padding: 1rem 0;
    a {
        color: $white;
        text-decoration: none;
        margin: 5px 0;
        display: inline-block;
        &:focus, &:hover {
            color: $brand-accent;
        }
    }
    p {
        margin: 0;
    }
    nav {
        font-size: 1rem;
        li {
            margin-left: 1rem;
            display: inline-block;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .footer-inner {
        @include grid-row;
    }
    .footer-nav {
        @include grid-column();
    }
    .copyright {
        @include grid-column();
        margin-top: 11px;
    }
}

@media screen and #{breakpoint(medium)} {

    .page-footer .footer-bottom {
        nav li {
            margin-left: 2.5rem;
        }
    }

}
