/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/

.callout {
	background: $brand;
	.heading, h3 {
		font-size: 1.4375em;
		color: $white;
	}
	p {
		font-size: rem-calc(14);
		line-height: 1.25;
		margin: 0 0 1rem;
	}
	.button {
		font-size: 1rem;
		padding: .5rem 1rem;
		margin: 0;
	}
}

// Callout Form
.callout-form {
	background-color: $brand-alt;
	padding: 48px 0;
	h2 {
		color: $white;
		margin-bottom: 21px;
	}
	label {
		font-size: rem-calc(16);
		color: $white;
	}
	input[type="submit"] {
		margin-top: 8px;
		margin-bottom: 0;
	}
}

@media screen and #{breakpoint(large)} {
	.callout-form {
		padding-left: 77px;
		padding-right: 77px;
		.row {
			display: flex;
			align-items: flex-end;
		}
		input[type="text"] {
			margin: 0;
		}
	}
}

// List callout
.callout-list {
	background-color: $off-white;
	padding: 30px .9375rem 6px;
	margin-left: -.9375rem;
	margin-right: -.9375rem;
	h2 {
		font-size: rem-calc(28);
		text-align: left;
		padding-bottom: 0;
		&::after {
			display: none;
		}
	}
	ul {
		padding: 0;
	}
}

@media screen and #{breakpoint(medium)} {
	.callout-list {
		font-size: rem-calc(18);
		line-height: 1.5;
		h2 {
			font-size: rem-calc(38);
		}
	}
}

@media screen and #{breakpoint(large)} {
	.callout-list {
		padding-left: 30px;
		padding-right: 30px;
		margin-left: auto;
		margin-right: auto;
	}
}

// Simple Cta
.simple-cta {
	font-family: $alt-font;
	font-size: rem-calc(24);
	color: $gray;
	text-align: center;
	position: relative;
	.circle-img {
		background: $white;
		border-radius: 50%;
		padding: 20px;
		margin-bottom: 12px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
		display: inline-block;
		position: relative;
		z-index: 20;
	}
	img {
		max-width: 73px;
		margin: 0;
	}
	a {
		color: $brand-ally;
		&:hover, &:focus {
			color: $brand-alt;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.simple-cta {
		font-size: rem-calc(30);
	}
}

// Simple callout with email signup
.simple-email-callout {
	background: $brand-alt;
	padding: 30px;
	border: solid 1px #E4E4E4;
	border-radius: 10px;
	input[type="email"] {
		margin-bottom: 0;
	}
	label {
		font-weight: $alt-bold;
		color: $white;
		margin-bottom: 5px;
	}
	.button {
		padding: 9px 35px 12px;
		margin-bottom: 0;
	}
	.button.disabled,
	.button.disabled:focus,
	.button.disabled:hover {
		background-color: $gray;
	}
	.inline-fields {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		gap: 15px 30px;
	}
	.field-grow {
		flex-grow: 1;
	}
	.field-submit {
		flex-shrink: 0;
	}
}