/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/


blockquote {
	background: url('/images/cssimg/ico_quote.png') no-repeat 10px 16px;
	font-size: rem-calc(16);
	line-height: 1.5;
	margin: 1.5rem 0;
	p {
		line-height: 1.5;
	}
	cite {
		font-size: rem-calc(14);
		font-style: normal;
		line-height: 1.3;
		.speaker {
			color: $brand-accent;
			font-weight: 700;
		}
		&::before {
			content: '';
		}
		.title {
			font-size: rem-calc(14);
			color: $gray;
			display: block;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	blockquote {
		background-position: 15px 28px;
		font-size: rem-calc(24);
		padding: 32px 32px 40px 60px;
		p {
			line-height: 1.4;
		}
		cite {
			font-size: rem-calc(20);
			.title {
				font-size: rem-calc(16);
			}
		}
	}
}