/*------------------------------------*\
    @group Software overview Template
\*------------------------------------*/
.software-overview-template {
	.solution-cards {
		padding-bottom: 0;
	}
}

@media screen and #{breakpoint(medium)} {

.software-overview-template {
	.solution-cards {
		padding-bottom: 10px;
	}
}

}
/* @end Software overview Template */


/*------------------------------------*\
    @group Software Inner Template
\*------------------------------------*/
.software-inner-template {
	.hero {
		height: auto;
		padding: 40px 0 80px;
		h1 {
			font-size: rem-calc(24);
			color: $white;
			text-shadow: $text-shadow-light;
			margin-bottom: 16px;
		}
		p {
			max-width: 650px;
			color: $white;
			text-shadow: $text-shadow-light;
		}
		img {
			margin-bottom: 32px;
		}
		.hero-content {
			position: relative;
			top: auto;
			transform: none;
		}
	}
	.subtext {
		font-size: rem-calc(18);
		max-width: 725px;
		margin-left: auto;
		margin-right: auto;
	}
	.two-cards {
		.last {
			margin-bottom: 20px;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.software-inner-template {
		.hero {
			height: 500px;
			padding: 0;
			h1 {
				font-size: rem-calc(48);
			}
			.hero-content {
				top: 10%;
				transform: translate(0);
			}
		}
		.two-cards .card {
			margin-bottom: 20px;
		}
	}
	.software-inner-template .hero .hero-content.no-logo {
		top: 20%;
	}
}

@media screen and #{breakpoint(large)} {
	.software-inner-template .hero img {
		margin-bottom: 48px;
	}
}
/* @end Software Inner Template */