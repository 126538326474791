/*----------------------------------------------------------*\
    Patterns

    All base site patterns are located in this file.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Cards
\*------------------------------------*/

.card {
	max-width: 360px;
	background: $white;
	color: $main-color;
	text-align: center;
	border-top: solid 3px $brand-light;
	box-shadow: $box-shadow;
	margin: 0 auto 3rem;
	position: relative;
	display: flex;
	flex-direction: column;
	&::before {
		content: '';
		width: 30px;
		height: 30px;
		background: url('/images/cssimg/flames_circle.png') no-repeat center center;
		display: block;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%,-50%);
	}
	ul {
		text-align: left;
	}
	.button {
		font-size: 1rem;
		margin: 0;
	}
	.card-content {
		color: $gray;
		padding: 32px 24px 32px;
		p {
			color: $gray;
		}
		> *:last-child {
			margin-bottom: 0;
		}
		p {
			text-align: left;
		}
	}
	.heading {
		font-size: rem-calc(28);
		color: $brand-ally;
		text-align: center;
		padding-bottom: 12px;
		margin: 0 0 12px;
		position: relative;
		@include small-border;
		&.small {
			font-size: rem-calc(20);
		}
		&.bare {
			padding: 0;
			margin: 0 0 .5em;
			&::after {
				display: none;
			}
		}
	}
	.subheading {
		font-family: $heading-font;
		font-weight: $heading-bold;
		margin-bottom: 8px;
	}
	.card-list {
		margin: 0;
		padding-left: 0;
		li {
			margin-bottom: 4px;
		}
	}
	.card-button {
		margin-top: auto;
		.button {
			text-align: center;
			display: block;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.card {
		.heading {
			font-size: rem-calc(32);
			&.small {
				font-size: rem-calc(24);
			}
		}
		&.full-card {
			max-width: 100%;
		}
	}
}

.card.card-center .card-content p {
	text-align: center;
}

.card.card-full {
	max-width: 100%;
}

.card.card-wide {
	max-width: 450px;
}

//Card with full bleed image header
.card.card-header {
	.card-image {
		margin-bottom: 20px;
		img {
			margin-bottom: 0;
		}
	}
	.card-content {
		padding-top: 0;
	}
}

//Card with icons in card header
.card.card-header .card-icon {
	padding: 40px 20px 20px;
	img {
		margin: 0;
	}
}


//Large Card
.card.large {
	max-width: 100%;
	.heading {
		font-size: rem-calc(34);
	}
	p {
		max-width: 725px;
		font-size: rem-calc(20);
		margin-left: auto;
		margin-right: auto;
	}
	.card-content {
		padding: 24px;
	}
}

@media screen and #{breakpoint(large)} {
	.card.large {
		.heading {
			font-size: rem-calc(48);
		}
		.card-content {
			padding: 48px;
		}
	}
}

//Multi-column card
.card.multi-col {
	max-width: 100%;
	&::before {
		display: none;
	}
	.card-content {
		@include grid-row;
	}
	h2 {
		font-size: rem-calc(28);
		margin: 0;
	}
	h3 {
		font-size: rem-calc(22);
		font-weight: $heading-bold;
		text-align: left;
		padding: 0;
		margin: 0 0 10px;
		&::after {
			display: none;
		}
	}
	p {
		margin-bottom: 1.75rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.first-col {
		@include grid-column(12);
		margin-top: -55px;
		margin-bottom: 2rem;
		text-align: center;
		.logo {
			max-width: 92px;
			margin-bottom: .5rem;
		}
	}
	.last-col {
		@include grid-column(12);
	}
}

@media screen and #{breakpoint(medium)} {

.card.multi-col {
	h2 {
		padding: 0;
		margin: 0;
		&::after {
			display: none;
		}
	}
	.first-col {
		width: grid-column(4);
		margin-top: 0;
		margin-bottom: 0;
		.logo {
			max-width: 100%;
			margin-bottom: 1rem;
		}
	}
	.last-col {
		width: grid-column(8);
	}
}

}

//Stacked card with multi-column below
.card.stacked-multi-col {
	background: $off-white;
	max-width: 100%;
	border-top: none;
	box-shadow: 0 1px 7px rgba(0,0,0,.2);
	margin: 2rem auto;
	&::before {
		width: 60px;
		height: 60px;
		background: url('/images/cssimg/flames_lrg.png') center center no-repeat;
		background-size: 60px 60px;
	}
	.card-content {
		padding: 48px 0 0;
		display: flex;
		flex-direction: column;
	}
	.header-row {
		text-align: center;
		margin-bottom: 16px;
		.subhead {
			font-family: $alt-font;
			font-size: rem-calc(22);
			font-weight: $alt-bold;
			color: $dark-gray;
			text-align: center;
			margin-bottom: 0;
		}
		h2 {
			font-size: rem-calc(32);
			padding: 0 2.5%;
			margin: 0;
			&::after {
				display: none;
			}
		}
	}
	.content-row {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
	}
	.content-col {
		background: $white;
		padding: 20px 5% 0;
		border-top: solid 1px $light-gray;
		border-right: solid 1px $light-gray;
		h3 {
			font-size: rem-calc(22);
			color: $brand;
			text-align: left;
			padding: 0;
			margin-bottom: .75rem;
			&::after {
				display: none;
			}
		}
		.button {
			margin-bottom: 20px;
		}
	}
}

.card.stacked-multi-col.narrow {
	max-width: 800px;
}


@media screen and #{breakpoint(medium)} {

.card.stacked-multi-col {
	.header-row h2 {
		font-size: rem-calc(48);
		padding: 0 5%;
	}
	.content-row {
		flex-direction: row;
	}
	.content-col {
		flex-basis: 33.3333%;
		padding: 20px 2.5% 0;
		&:last-child {
			border-right: none;
		}
	}
}

.card.stacked-multi-col.two-col {
	.content-col {
		flex-basis: 50%;
	}
}

}


//2 cards side by side
.two-cards {
	.card {
		max-width: 500px;
	}
}


//Card group
.card-group {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 50px 30px;
	.card {
		margin-bottom: 0;
		.heading {
			font-size: rem-calc(28);
			padding-bottom: 0;
			&::after {
				display: none;
			}
		}
	}
	.card-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		.button:last-child {
			margin-top: auto;
		}
	}
}


.card-group.fill {
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}


// Equal height card group with cta buttons
.equal-height-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 48px 24px;
	justify-items: center;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	&.left {
		justify-items: start;
	}
	.card {
		margin: 0;
		.heading {
			font-size: rem-calc(24);
		}
	}
	.card-image {
		background-color: $off-white;
		padding: 30px 43px 0;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		img {
			box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
			margin-bottom: 0;
		}
	}
	.card-image.float-image {
		height: 180px;
		padding-top: 20px;
		padding-bottom: 20px;
		align-items: center;
		img {
			box-shadow: none;
		}
	}
}


/* @end Cards */


/*------------------------------------*\
    @group Image Grid
\*------------------------------------*/

.image-grid {
	text-align: center;
	.grid-list {
		margin-top: 40px;
		overflow: hidden;
		&.row::after, &.row::before {
			content: none;
		}
	}
	.grid-item {
		margin-bottom: 30px;
	}
	h2 {
		margin-bottom: 12px;
		position: relative;
		@include small-border;
	}
	a:not(.button) {
		color: $brand;
		&:hover, &:focus {
			color: $brand-alt;
		}
	}
	.intro {
		font-size: rem-calc(20);
		@include breakpoint(medium) {
			max-width: 800px;
			font-size: rem-calc(24);
			margin: 0 auto;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.image-grid {
		.grid-item {
			margin-bottom: 50px;
		}
		.grid-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			&.flex-center {
				justify-content: center;
			}
		}
	}
}

//Image grid with labels below image
.image-grid.image-labels {
	margin-bottom: 30px;
	.grid-item {
		height: 100px;
		margin-bottom: 24px;
		img {
			margin: 0;
		}
	+ p {
			font-family: $alt-font;
			font-size: rem-calc(24);
			font-weight: $alt-bold;
			color: $gray;
			line-height: 1;
			margin-bottom: 50px;
		}
	}
	.smaller-text {
		.grid-item + p {
			font-size: rem-calc(20);
		}
	}
}

@media screen and #{breakpoint(medium)} {

.image-grid.image-labels {
	.grid-item {
		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

}


//Image grid section with labels
.image-grid-section {
	background-color: $off-white;
	padding: 50px 0 20px;
	h2 {
		padding-bottom: 0;
		&::after {
			display: none;
		}
	}
}

.image-grid.image-labels-top {
	margin-bottom: 30px;
	p {
		font-family: $alt-font;
		font-size: rem-calc(24);
		font-weight: $alt-bold;
		color: $gray;
		line-height: 1;
	}
	.grid-item {
		margin-bottom: 24px;
		.icon {
			font-size: 120px;
			color: $brand;
			margin-bottom: 30px;
		}
	}
	.smaller-text {
		.grid-item + p {
			font-size: rem-calc(20);
		}
	}
	.grid-list .column:last-child {
		.grid-item .icon {
			margin-bottom: 0;
		}
	}
}

//Image grid with tooltips
.image-grid .grid-tooltips {
	.column .has-tip {
		margin-bottom: 30px;
		.grid-item {
			margin-bottom: 0;
		}
	}
}

/* @end Image Grid */


/*------------------------------------*\
    @group Bifurcated Callout
\*------------------------------------*/

.bi-callout {
	color: $white;
	@include flex-grid-row;
	a {
		color: $white;
		&:hover, &:focus {
			color: $light-gray;
		}
	}
	.button {
		color: $white;
		&.white {
			color: $brand-ally;
			&:hover, &:focus {
				color: $brand-alt;
			}
		}
	}
	.heading {
		width: 100%;
		text-align: center;
		padding: 2rem 2rem;
		@include flex;
		@include flex-align(center, middle);
		h2 {
			font-size: rem-calc(32);
			color: $white;
			padding: 0;
			&::after {
				display: none;
			}
		}
		.button {
			font-size: rem-calc(24);
			padding: 1.25rem 2rem;
			margin: 0;
			+ p {
				margin-top: 10px;
			}
		}
		p {
			font-size: rem-calc(18);
		}
	}
	.info {
		width: 100%;
		background: rgba($white,.3);
		font-family: $alt-font;
		font-size: 1.25rem;
		font-weight: $alt-bold;
		padding: 2rem 2rem;
		position: relative;
		@include flex;
		@include flex-align(center, middle);
		&::before {
			content: 'or';
			background: $white;
			width: 34px;
			height: 34px;
			color: $brand-ally;
			text-align: center;
			border-radius: $border-round;
			display: block;
			@include horizontal-center;
			top: -17px;
		}
		h3 {
			font-size: rem-calc(20);
			text-align: left;
		}
		.title {
			width: 80px;
			font-weight: $alt-normal;
			display: inline-block;
		}
		.person {
			font-size: 1rem;
			font-weight: $alt-normal;
			margin: 0 0 6px;
			.name {
				font-size: rem-calc(20);
			}
		}
		.inner-content > *:last-child {
			margin-bottom: 0;
		}
	}
}

.bi-callout.split {
	.heading {
		padding-bottom: 48px;
	}
	.info {
		padding-top: 48px;
		background: none;
	}
}

@media screen and #{breakpoint(medium)} {
	.bi-callout {
		.heading {
			width: grid-column(6);
		}
		.info {
			width: grid-column(6);
			&::before {
				@include vertical-center;
				left: -17px;
			}
		}
	}

	.bi-callout.split {
		.heading {
			padding: 32px 48px 32px 20px;
		}
		.info {
			padding: 32px 20px 32px 48px;
			.inner-content {
				width: 100%;
			}
		}
	}
}

//Tall modifier
.bi-callout.tall {
	padding-top: 84px;
	padding-bottom: 72px;
}

//Bifurcated Callout Simple
.bi-callout-simple {
	padding-top: 64px;
	padding-bottom: 32px;
	h2 {
		width: 100%;
		font-size: rem-calc(32);
		color: $white;
		text-align: center;
		padding: 0 20px;
		margin: 0;
		&:after {
			display: none;
		}
	}
	a:not(.button) {
		color: $brand;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
	.info {
		background: none;
	}
	.footer-center {
		width: 100%;
		font-family: $alt-font;
		font-size: rem-calc(18);
		text-align: center;
		margin: 0;
		a {
			color: $brand-accent;
			&:hover, &:focus {
				color: $white;
			}
		}
		&.large-text {
			font-size: rem-calc(22);
		}
	}
	&.align-center {
		display: block;
		text-align: center;
		h2 {
			margin-bottom: 24px;
		}
		p {
			font-size: rem-calc(20);
			font-family: $alt-font;
		}
		> *:last-child {
			margin-bottom: 12px;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.bi-callout-simple {
		h2 {
			padding: 0;
		}
	}
}


/* @end Bifurcated Callout */


/*------------------------------------*\
    @group Simple Callout
\*------------------------------------*/

.simple-callout {
	padding: 24px 30px;
	border: solid 1px $light-gray;
	margin: 0 0 48px;
	display: flex;
	flex-direction: column;
	> * {
		margin: 0;
	}
	p {
		font-family: $alt-font;
		font-size: rem-calc(22);
		line-height: 1.2;
	}
	.button {
		font-size: rem-calc(16);
		text-align: center;
		margin-top: 1.5rem;
	}
}

@media screen and #{breakpoint(medium)} {

.simple-callout {
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	p {
		font-size: rem-calc(28);
	}
	.button {
		white-space: nowrap;
		margin-top: 0;
		margin-left: 1.5rem;
	}
}

}



//Full width Callout Simple
.full-callout-simple {
	padding-top: 84px;
	padding-bottom: 60px;
	h2 {
		width: 100%;
		font-size: rem-calc(36);
		color: $white;
		text-align: center;
		&:after {
			display: none;
		}
	}
	a:not(.button) {
		color: $brand;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.full-callout-simple {
		padding: 72px 0 48px;
		h2 {
			font-size: rem-calc(46);
		}
	}
}

/* @end Simple Callout */


/*------------------------------------*\
    @group Four-up Callout
\*------------------------------------*/
.callout-fourup {
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	.callout-item {
		width: 100%;
		border-top: 0;
		margin-bottom: 0;
		flex-direction: column;
		&:first-child {
			border-top: solid 1px $light-gray;
		}
	}
	.button {
		margin-top: 1.5rem;
	}
}

@media screen and #{breakpoint(medium)} {
	.callout-fourup {
		flex-direction: row;
		flex-wrap: wrap;
		.callout-item {
			width: 50%;
			border-top: solid 1px $light-gray;
			align-items: stretch;
			&:nth-child(-n+2) {
				border-bottom: 0;
			}
			&:nth-child(2n+1) {
				border-right: 0;
			}
		}
		p {
			font-size: rem-calc(24);
		}
		.button {
			margin-left: 0;
		}
	}
}

@media screen and (min-width:56.25em) {
	.callout-fourup {
		.callout-item {
			flex-direction: row;
			align-items: center;
		}
		.button {
			margin-top: 0;
			margin-left: 1.5rem;
		}
	}
}
/* @end Four-up Callout */

/*------------------------------------*\
    @group Small icon callout
\*------------------------------------*/
.small-icon-callout {
	max-width: 260px;
	box-shadow: $box-shadow;
	background-color: $white;
	padding: 20px;
	margin: 40px auto;
}
.small-icon-callout-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	p {
		line-height: 1.25;
		padding-left: 5px;
	}
	img {
		flex-shrink: 0;
	}
	> * {
		margin-bottom: 0;
	}
}
/* @end Small icon callout */


/*------------------------------------*\
    @group Featured Text
\*------------------------------------*/

.featured-highlight-text {
	max-width: 650px;
	text-align: center;
	margin: 2rem auto;
	p {
		font-size: rem-calc(24);
	}
	.button {
		font-size: 1rem;
		margin-bottom: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
}

.featured-highlight-text.dark-bg {
	color: $white;
	.heading {
		color: $white;
	}
}

/* @end Featured Text */


/*------------------------------------*\
    @group Section Intro
\*------------------------------------*/

.section-intro {
	max-width: 750px;
	text-align: center;
	margin: 2rem auto;
	p {
		font-size: rem-calc(20);
	}
	> *:last-child {
		margin-bottom: 0;
	}
}
@media screen and #{breakpoint(medium)} {

	.section-intro {
		p {
			font-size: rem-calc(24);
		}
	}

}

//Section intro with larger text
.section-intro-large {
	max-width: 790px;
	text-align: center;
	margin: 2rem auto;
	p {
		font-size: rem-calc(20);
	}
}

@media screen and #{breakpoint(medium)} {
	.section-intro-large {
		p {
			font-size: rem-calc(30);
		}
	}
}

//Section intro with image
.section-intro-image {
	max-width: 790px;
	text-align: center;
	margin: 2rem auto;
	p {
		font-size: rem-calc(20);
	}
}

@media screen and #{breakpoint(medium)} {
	.section-intro-image {
		p {
			font-size: rem-calc(30);
		}
	}
}

/* @end Section Intro */

/*------------------------------------*\
    @group Narrow Section
\*------------------------------------*/
.narrow-section {
	max-width: 800px;
	margin: 0 auto;
}
/* @end Narrow Section */



/*------------------------------------*\
    @group Pricing Table
\*------------------------------------*/

.pricing-table {
	background: $white;
	box-shadow: $box-shadow;
	margin: 0 0 2.5rem;
	ul {
		padding: 0;
		margin: 0;
	}
	.heading {
		padding: 20px 16px;
		h3 {
			font-size: rem-calc(28);
			color: $brand-alt;
			text-align: center;
			margin: 0;
		}
	}
	.subhead {
		margin: 5px 0 0;
		text-align: center;
		a {
			font-weight: bold;
			color: $brand-ally;
			text-decoration: none;
			&:hover,
			&:focus {
				color: $brand-alt;
			}
		}
	}
	.services {
		padding: 0 16px 20px;
		.optional {
			color: #AAA;
		}
	}
	.options {
		background-color: $light-gray;
		max-height: 0;
		padding: 0 16px;
		visibility: hidden;
		overflow: hidden;
		transition: max-height .3s ease-out, visibility .3s ease-out, padding .3s ease-out;
		&.open {
			max-height: 150px;
			visibility: visible;
			transition: max-height .2s ease-in, visibility .3s ease-in, padding .3s ease-in;
		}
		.option-inner {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		h4 {
			font-family: $main-font;
			font-size: 1rem;
			color: $brand-alt;
			text-align: center;
			padding: 0;
			&::after {
				display: none;
			}
		}
	}
	.advisory-services {
		padding-top: 20px;
		h4 {
			font-family: $main-font;
			font-size: 1rem;
			color: $brand-alt;
			text-align: center;
			padding: 0;
			&::after {
				display: none;
			}
		}
		.not-included {
			color: #7D7D7D;
			text-decoration: line-through;
		}
	}
	.price {
		background: $brand-alt;
		font-family: $alt-font;
		color: $white;
		text-align: center;
		padding: 24px 16px;
		.cost {
			font-size: rem-calc(24);
			font-weight: $alt-bold;
			display: block;
		}
		p {
			line-height: 1.2;
			margin: 0;
		}
		a {
			color: $white;
			text-decoration: none;
			display: block;
			transition: all .1s;
			&:hover, &:focus {
				color: $brand;
			}
		}
	}
}

//Toggle switch and label
.pricing-table-toggle {
	font-family: $alt-font;
	margin: 0 0 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	.toggle-label {
		line-height: 1.2;
		margin: 0 15px 0 0;
	}
	.switch {
		margin: 0;
	}
	button {
		background: $brand-ally;
		padding: 10px;
		transition: background-color .3s;
		&:first-child {
			border-radius: $border-radius 0 0 $border-radius;
		}
		&:last-child {
			border-radius: 0 $border-radius $border-radius 0;
		}
		&:hover,
		&:focus {
			background: darken($brand-ally, 10%);
		}
		&.active {
			background: $brand-alt;
			box-shadow: inset 1px 1px 5px 1px rgba(0,0,0,.85);
		}
	}
}

/* @end Pricing Table */


/*------------------------------------*\
    @group Supplemental Aside
\*------------------------------------*/

.supplemental-aside-info {
	margin-bottom: 2rem;
	@include grid-row;
	.heading {
		font-weight: $alt-bold;
	}
	.main-info {
		font-size: rem-calc(20);
		margin-bottom: 2rem;
		@include grid-column;
		.heading {
			font-size: rem-calc(48);
			text-align: left;
			padding: 0;
			&::after {
				display: none;
			}
		}
	}
	.aside-info {
		text-align: center;
		@include grid-column;
		.heading {
			font-size: rem-calc(32);
			padding: 0;
			margin-bottom: .5rem;
			&::after {
				display: none;
			}
		}
		.aside-inner {
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;
		}
		.aside-item {
			margin-bottom: 1.5rem;
			.icon {
				font-size: 70px;
				color: $medium-gray;
				line-height: 1;
				margin-top: .5rem;
				margin-bottom: .5rem;
			}
			.icon-giving-hand {
				background: $medium-gray;
				font-size: 30px;
				color: $white;
				padding-left: 8px;
				padding-top: 20px;
				padding-bottom: 20px;
				border-radius: $border-round;
			}
		}
		img {
			margin-bottom: 0;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.supplemental-aside-info {
		.aside-info {
			text-align: left;
			.aside-inner {
				max-width: 100%;
				@include grid-row;
			}
			.aside-item {
				display: flex;
				align-content: center;
				@include grid-column(6);
				.heading {
					text-align: left;
				}
			}
			.icon-wrap {
				flex-shrink: 0;
				margin-right: 1rem;
			}
		}
	}
}

@media screen and #{breakpoint(large)} {
	.supplemental-aside-info {
		.main-info {
			width: grid-column(7);
			font-size: rem-calc(24);
		}
		.aside-info {
			width: grid-column(5);
			text-align: left;
			.aside-item {
				width: grid-column(12);
			}
		}
	}
}

/* @end Supplemental Aside */


/*------------------------------------*\
    @group Media Preview Grid
\*------------------------------------*/

.media-preview-grid {
	margin: 0 0 2rem;
	@include grid-row;
	.grid-item {
		margin-bottom: 2.5rem;
		position: relative;
		@include grid-column(12);
		&:last-child {
			@include grid-column-end;
		}
		.text {
			width: 100%;
			background: rgba(0,0,0,.4);
			font-family: $alt-font;
			color: $white;
			padding: 0 1rem;
			position: absolute;
			bottom: 44px;
			.main-text p {
				font-size: rem-calc(20);
				font-weight: $alt-bold;
				margin: 0;
			}
			.option-text p {
				font-size: rem-calc(14);
				line-height: 1.2;
				margin: 0 0 12px;
			}
		}
	}
	.item-content {
		max-width: 240px;
		margin: 0 auto;
		position: relative;
	}
	img {
		margin: 0;
	}
	.button {
		background: $gray;
		width: 100%;
		font-weight: $alt-normal;
		text-align: center;
		padding: 10px;
		margin: 0;
		display: block;
		&:hover,
		&:focus {
			background: darken($gray,10%);
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.media-preview-grid {
		.grid-item {
			@include grid-column(6);
		}
	}
}

@media screen and #{breakpoint(large)} {
	.media-preview-grid {
		.grid-item {
			@include grid-column(4);
		}
	}
}

/* @end Media Preview Grid */


/*------------------------------------*\
    @group Timeline
\*------------------------------------*/

.timeline {
	position: relative;
	&::before {
		content: '';
		background: $light-gray;
		width: 2px;
		height: 100%;
		margin-left: -1px;
		position: absolute;
		left: 50%;
		top: 0;
		bottom: 0;
	}
	h2 {
		max-width: 300px;
		background: $white;
		text-align: center;
		border: solid 2px $light-gray;
		padding: 5px 15px 15px;
		margin: 0 auto;
		&::after {
			display: none;
		}
	}
	.timeline-blocks {
		margin-top: 42px;
		margin-bottom: 42px;
	}
	.timeline-block {
		width:100%;
		margin-bottom: 2rem;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
		.event {
			width: 100%;
			background: $white;
			font-size: rem-calc(20);
			padding: 20px 30px;
			border-top: solid 3px $brand-light;
			box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
		}
		.date {
			width: 100%;
			color: $brand;
			position: relative;
			&::after {
				content: '';
				width: 30px;
				height: 30px;
				background-image: url('/images/cssimg/flames_circle.png');
				margin-left: -15px;
				display: block;
				position: absolute;
				left: 50%;
				bottom: -17px;
			}
		}
	}
	.button-wrap {
		background: $white;
		text-align: center;
		position: relative;
		.button {
			margin: 0;
		}
	}
	.timeline-end-text {
		font-size: rem-calc(20);
		text-align: center;
		span {
			background-color: $white;
			padding: 15px 30px;
			border: solid 2px $light-gray;
			position: relative;
		}
	}
}

@media screen and (min-width:50em) {

	.timeline .timeline-block {
		width: 50.1%;
		margin-bottom: 0;
		float: left;
		position: relative;
		&:nth-child(2n+2) {
			float: right;
			clear: left;
			.event {
				margin-left: 30%;
			}
			.date {
				padding-left: 0;
				padding-right: 10px;
				text-align: right;
				left: 0;
				&::after {
					left: -14px;
					right: auto;
				}
			}
		}
		.event {
			width: 70%;
		}
		.date {
			width: 30%;
			color: $brand-ally;
			padding-bottom: 0;
			padding-left: 10px;
			border-bottom: solid 2px $light-gray;
			position: absolute;
			left: 70%;
			top: auto;
			bottom: 30px;
			&::after {
				content: '';
				width: 30px;
				height: 30px;
				background-image: url('/images/cssimg/flames_circle.png');
				margin-left: 0;
				display: block;
				position: absolute;
				left: auto;
				right: -14px;
				bottom: -14px;
			}
		}
	}

}

/* @end Timeline */


/*------------------------------------*\
    @group Animated Hamburger icon
\*------------------------------------*/

.hamburger {
	width: 30px;
	height: 24px;
	font-size: 0;
	margin: 0;
	padding: 0;
	transition: background 0.3s;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: hidden;
	&:focus {
		outline: none;
	}
	span {
		height: 4px;
		background: $brand;
		display: block;
		position: absolute;
		top: 10px;
		left: 0;
		right: 0;
		&::before,
		&::after {
			content: '';
			background-color: $brand;
			width: 100%;
			height: 4px;
			display: block;
			position: absolute;
			left: 0;
		}
		&::before {
			top: -8px;
		}
		&::after {
			bottom: -8px;
		}
	}

}

.hamburger-x {
	span {
		transition: background 0s 0.3s;
		&::before,
		&::after {
			transition-duration: 0.3s, 0.3s;
			transition-delay: 0.3s, 0s;
		}
		&::before {
			transition-property: top, transform;
		}
		&::after {
			transition-property: bottom, transform;
		}
	}
}

//Active state, add .active with JS
.hamburger-x.active {
	span {
		background: none;
		&::before {
			top: 0;
			transform: rotate(45deg);
		}
		&::after {
			bottom: 0;
			transform: rotate(-45deg);
		}
		&::before,
		&::after {
			transition-delay: 0s, 0.3s;
		}
	}

}

/* @end Animated Hamburger icon */

/*------------------------------------*\
    @group Square Grid
\*------------------------------------*/

.square-grid {
	background: $light-gray;
	&.light {
		background: $white;
	}
	.square-grid-row {
		@include flex-grid-row;
	}
	.square-grid-item {
		width: 100%;
		padding: 40px 20px 45px;
		border-bottom: solid 1px $medium-gray;
		h3 {
			font-size: rem-calc(44);
			text-align: left;
			&::after {
				display: none;
			}
			.subhead {
				font-size: rem-calc(24);
			}
		}
		ul {
			padding-left: 5px;
			margin: 0 0 1.5rem;
		}
		.item-content {
			max-width: 360px;
			margin-left: auto;
			margin-right: auto;
		}
		.list-heading {
			font-weight: bold;
			margin-bottom: .5rem;
		}
		.price {
			font-family: $alt-font;
			font-size: rem-calc(32);
			color: $brand-light;
			line-height: 1.1;
			&.small {
				min-height: 35px;
				font-size: rem-calc(24);
			}
		}
	}
}

@media screen and #{breakpoint(medium)} {

.square-grid {
	.square-grid-group {
		border-bottom: solid 1px $medium-gray;
	}
	.square-grid-item {
		width: 50%;
		border-bottom: none;
		&:first-child {
			border-right: solid 1px $medium-gray;
		}
		.item-content {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

}


// Content width square grid
.square-grid-narrow {
	background: none;
	.square-grid-group:last-child .square-grid-item:last-child {
		border-bottom: 0;
	}
	.square-grid-item h3 {
		font-size: rem-calc(22);
		text-align: center;
	}
}

@media screen and #{breakpoint(medium)} {
	.square-grid-narrow {
		.square-grid-group:last-child {
			border-bottom: 0;
		}
	}
}
/* @end Square Grid */


/*------------------------------------*\
    @group PDF Lists
\*------------------------------------*/

.pdf-list-large {
	a {
		color: $white;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
	ul {
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		li {
			min-height: 45px;
			background: none;
			line-height: 1.25;
			padding-top: 3px;
			padding-left: 40px;
			margin-bottom: 15px;
			position: relative;
			&:before {
				content: '\f012';
				font-family: $icon-font;
				font-size: 36px;
				color: $white;
				opacity: .6;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -24px;
			}
		}
	}
}

@media screen and #{breakpoint(medium)} {

.pdf-list-large {
	ul {
		flex-direction: row;
		flex-basis: 30%;
		justify-content: space-around;
		li {
			width: 30%;
		}
	}
}

}


//PDF Detail List
.pdf-list-details {
	text-align: left;
	padding: 0;
	margin-left: 0;
	overflow: hidden;
	li {
		background: none;
		padding-top: 4px;
		padding-left: 24px;
		position: relative;
		&:before {
			content: '';
			width: 15px;
			height: 15px;
			background: url('/images/icons/file_pdf.svg') no-repeat left top;
			background-size: contain;
			position: absolute;
			left: 0;
			top: 10px;
		}
	}
	h3 {
		font-size: rem-calc(24);
		color: $brand-alt-light;
		text-align: left;
		padding: 0;
		&::after {
			display: none;
		}
		a {
			color: $brand-alt-light;
			&:hover, &:focus {
				color: $brand;
			}
		}
	}
}

/* @end PDF Lists */



/*------------------------------------*\
    @group Video Preview Grid
\*------------------------------------*/

.video-previews {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	.video-preview {
		max-width: 295px;
		margin: 0 auto 36px;
		h3 {
			font-size: rem-calc(24);
			text-align: left;
			padding: 0;
			margin-bottom: 8px;
			&::after {
				display: none;
			}
		}
		img {
			margin-bottom: 10px;
		}
	}
	.row:last-child {
		.column:last-child .video-preview {
			margin-bottom: 0;
		}
	}
}

@media screen and #{breakpoint(medium)} {

.video-previews {
	.video-preview {
		margin-bottom: 72px;
	}
	.row:last-child {
		.video-preview {
			margin-bottom: 0;
		}
	}
}

}

/* @end Video Preview Grid */

/*------------------------------------*\
    @group Text Bio
\*------------------------------------*/

.text-bio {
	margin: 0 0 2rem;
	overflow: hidden;
	h3 {
		font-size: rem-calc(24);
		text-align: left;
		padding: 0;
		&::after {
			display: none;
		}
	}
}

/* @end Text Bio */

/*------------------------------------*\
    @group Media Section
\*------------------------------------*/
.media-section {
	background-color: $light-gray;
	position: relative;
	.inner {
		@include grid-row;
		max-width: 500px;
	}
	.text {
		@include grid-col;
		padding-bottom: 10px;
		h2 {
			font-size: rem-calc(28);
			text-align: left;
			line-height: 1.25;
			padding: 0;
			margin: 0 0 10px;
			&::after {
				display: none;
			}
			.subhead {
				font-size: rem-calc(16);
				color: $gray;
				line-height: 1.25;
				display: block;
			}
		}
		p {
			font-family: $alt-font;
			font-weight: $alt-normal;
			font-size: rem-calc(20);
			color: $brand;
			line-height: 1.2;
			&.ico {
				padding-left: 30px;
				position: relative;
				&::before {
					content: '\f02f';
					font-family: $icon-font;
					font-size: 20px;
					color: $brand;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
	}
	.media {
		@include grid-col;
		margin-top: -20px;
		margin-bottom: 20px;
		img {
			margin: 0;
		}
	}
}


@include breakpoint(900px) {
	.media-section {
		.inner {
			max-width: 75em;
		}
		.text {
			width: grid-col(5);
			@include grid-column-position(-7);
			padding-bottom: 30px;
			padding-top: 50px;
		}
		.media {
			width: grid-col(7);
			@include grid-column-position(5);
			margin-bottom: 0;
			margin-top: -30px;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.media-section {
		.text {
			width: grid-col(6);
			@include grid-column-position(-6);
		}
		.media {
			width: grid-col(6);
			@include grid-column-position(6);
		}
	}
}
/* @end Media Section */

/*------------------------------------*\
    @group Media Square Overlaps
\*------------------------------------*/

.media-square-overlaps {
	.heading h2 {
		font-size: rem-calc(38);
		line-height: 1.05;
		text-align: left;
		padding: 0;
		margin: 0 0 20px;
		&::after {
			display: none;
		}
	}
}

.media-square-overlap {
	max-width: 440px;
	margin: 0 auto 30px;
	.media {
		img {
			width: 100%;
			margin: 0;
		}
	}
	.details {
		padding: 30px 20px 10px;
		border: solid 7px $light-gray;
		border-top: 0;
		.heading-icon {
			text-align: center;
			.icon {
				font-size: 66px;
				color: $brand;
			}
			&.quickbooks-icon svg {
				max-width: 300px;
			}
		}
		h3 {
			font-size: rem-calc(24);
			color: $brand-alt;
			padding: 0;
			&::after {
				display: none;
			}
		}
		.title {
			font-size: rem-calc(21);
			line-height: 1.5;
			margin-bottom: 20px;
			span {
				font-size: rem-calc(16);
				line-height: 1.3;
				display: block;
			}
			a {
				color: $brand-alt;
				&:hover, &:focus {
					color: $brand-ally;
				}
			}
		}
	}
	ul {
		padding-left: 0;
	}
	blockquote {
		background: none;
		color: $gray;
		padding: 0;
		margin: 0 0 10px;
		.icon {
			color: $brand-alt-accent;
			vertical-align: 5px;
		}
		p {
			font-size: rem-calc(16);
			font-style: italic;
			color: $gray;
			line-height: 1.5;
		}
		cite {
			font-size: rem-calc(16);
			color: $gray;
			.speaker {
				font-size: rem-calc(18);
				font-weight: $main-normal;
				color: $dark-gray;
				margin-bottom: 3px;
				display: block;
			}
			a {
				color: $brand-alt;
				&:hover, &:focus {
					color: $brand-ally;
				}
			}
		}
	}
}

@include breakpoint(850px) {
	.media-square-overlaps {
		.heading h2 {
			font-size: rem-calc(40);
			margin-bottom: 40px;
		}
	}

	.media-square-overlap {
		max-width: 100%;
		margin-bottom: 50px;
		display: flex;
		.media {
			width: 50%;
			flex-shrink: 0;
			order: 1;
			position: relative;
			z-index: 20;
		}
		.details {
			width: calc(50% + 50px);
			min-height: 480px;
			margin-top: 50px;
			margin-right: -50px;
			padding: 55px 70px 35px 35px;
			border-top: solid 7px $light-gray;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 10;
			h3 {
				font-size: rem-calc(30);
			}
			p, li {
				font-size: rem-calc(18);
			}
			ul li {
				background-position: 2px 12px;
			}

		}
		&:nth-child(odd) {
			.details {
				margin-left: -50px;
				padding-left: 70px;
			}
			.media {
				order: 0;
			}
		}
	}

	.media-square-overlaps.flip .media-square-overlap {
		&:nth-child(odd) {
			.details {
				margin-left: 0;
				padding-left: 35px;
			}
			.media {
				order: 1;
			}
		}
		&:nth-child(even) {
			.details {
				margin-left: -50px;
				padding-left: 70px;
			}
			.media {
				order: 0;
			}
		}
	}
}


@media screen and #{breakpoint(large)} {
	.media-square-overlap {
		.details {
			padding: 55px 104px 35px 55px;
			p {
				font-size: rem-calc(20);
			}
		}
		&:nth-child(odd) {
			.details {
				padding-left: 104px;
				padding-right: 70px;
			}
		}
	}
	.media-square-overlaps.flip .media-square-overlap {
		&:nth-child(odd) {
			.details {
				margin-left: 0;
				padding-left: 35px;
			}
		}
		&:nth-child(even) {
			.details {
				padding-left: 104px;
				padding-right: 70px;
			}
		}
	}
}


//Testimonial
.testimonial-square-overlap {
	.details {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	blockquote {
		background: none;
		color: $gray;
		padding: 0;
		margin: 0 0 10px;
		.icon {
			color: $brand-alt-accent;
			vertical-align: 5px;
		}
		p {
			font-size: rem-calc(16);
			font-style: italic;
			color: $gray;
			line-height: 1.5;
		}
		cite {
			font-size: rem-calc(16);
			color: $gray;
			margin-bottom: 25px;
			.speaker {
				font-size: rem-calc(18);
				font-weight: $main-normal;
				color: $dark-gray;
				margin-bottom: 3px;
				display: block;
			}
			a {
				color: $brand-alt;
				&:hover, &:focus {
					color: $brand-ally;
				}
			}
		}
	}
	.button {
		margin-bottom: 0;
	}
}


@include breakpoint(850px) {
	.testimonial-square-overlap {
		&:nth-child(odd) {
			.media {
				order: 1;
			}
			.details {
				padding: 20px 105px 20px 35px;
			}
		}
		.details {
			align-items: flex-start;
		}
		blockquote {
			p {
				font-size: rem-calc(20);
			}
			cite {
				font-size: rem-calc(16);
				margin-bottom: 5px;
				.speaker {
					font-size: rem-calc(20);
				}
			}
		}
	}
}
/* @end Media Square Overlaps */


/*------------------------------------*\
    @group Cutout Boxes
\*------------------------------------*/
.cut-boxes-section {
	h2 {
		color: $dark-blue;
		padding-bottom: 0;
		&::after {
			display: none;
		}
	}
}

.cut-boxes {
	@include grid-row;
	.cut-box {
		@include grid-col;
		margin-bottom: 30px;
		.outer {
			padding: 30px 50px 0 0;
			border: solid 5px $brand;
		}
		.inner {
			margin: 0 0 -5px -5px;
		}
		h3 {
			background-color: $white;
			font-size: rem-calc(28);
			text-align: left;
			line-height: 1.11;
			margin: 0;
			padding-bottom: 10px;
			border-top: solid 10px $white;
			&::after {
				display: none;
			}
		}
		p {
			background-color: $white;
			font-size: rem-calc(18);
			margin: 0;
			padding-bottom: 20px;
		}
		.button {
			font-size: rem-calc(24);
			margin-bottom: 0;
			border-right:  solid 10px $white;
			img {
				margin: 0 3px 0 0;
			}
		}
	}
	.cut-box.purple {
		.outer {
			border-color: $brand-alt;
		}
		h3 {
			color: $brand-alt;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.cut-boxes-section {
		h2 {
			font-size: rem-calc(40);
			margin-bottom: 50px;
		}
	}
	.cut-boxes {
		display: flex;
		.cut-box {
			width: grid-col(6);
			display: flex;
			.inner {
				display: flex;
				flex-wrap: wrap;
			}
			.outer {
				display: flex;
			}
			.text {
				background-color: $white;
			}
			.button-wrap {
				background-color: $white;
				display: flex;
				align-items: flex-end;
			}
		}
	}
	.cut-boxes.center {
		.cut-box {
			padding-right: 15px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.cut-boxes .cut-box {
		padding-right: 57px;
		h3 {
			font-size: rem-calc(40);
			line-height: 1.1;
		}
		p {
			font-size: rem-calc(20);
		}
		&:nth-child(even) {
			padding-left: 57px;
			padding-right: .9375rem;
		}
	}
}

/* @end Cutout Boxes */



/*------------------------------------*\
    @group Simple Clickable Cards
\*------------------------------------*/

.simple-click-cards {
	padding: 0 .9375rem;
	.simple-click-card {
		margin-bottom: 32px;
	}
	.card-inner {
		max-width: 400px;
		background-color: $white;
		text-align: center;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
		border: solid 1px $white;
		margin: 0 auto;
		transition: all .3s;
		&:hover, &:focus {
			border: 1px solid #38bfff;
			.button {
				background: darken($brand,10);
			}
			img {
				transform: scale(1.1);
			}
		}
	}
	a {
		display: block;
		padding: 40px 16px;
	}
	.button {
		font-size: rem-calc(16);
		padding: 15px 25px;
		line-height: 1.25;
		margin-bottom: 0;
		transition: all .3s;
	}
	h3 {
		font-size: rem-calc(28);
		color: $brand-alt;
		margin-bottom: 20px;
	}
	p {
		font-size: rem-calc(20);
		color: $gray;
		line-height: 1.25;
		margin-bottom: 30px;
	}
	img {
		transition: transform .2s;
	}
}

@media screen and #{breakpoint(large)} {
	.simple-click-cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.simple-click-card {
			width: 32%;
			display: flex;
		}
		.card-inner {
			width: 100%;
		}
		h3 {
			font-size: rem-calc(38);
			line-height: 1.11;
		}
		p {
			font-size: rem-calc(24);
		}
	}
}

/* @end Simple Clickable Cards */


/*------------------------------------*\
    @group Media Objects
\*------------------------------------*/

.media-object {
	color: $gray;
	margin-bottom: 28px;
	overflow: hidden;
	.heading {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		h3 {
			font-size: rem-calc(24);
			color: $gray;
			padding-bottom: 0;
			margin-bottom: 0;
			&::after {
				display: none;
			}
		}
		.icon {
			font-size: rem-calc(24);
			color: $brand;
			margin-right: 10px;
		}
	}
	.media {
		display: none;
	}
}

@media screen and #{breakpoint(medium)} {
	.media-object {
		margin-bottom: 96px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		.heading {
			text-align: center;
			display: block;
			h3 {
				font-size: rem-calc(34);
			}
			.icon {
				font-size: rem-calc(60);
				margin: 0 0 10px;
			}
		}
		.text {
			max-width: 420px;
			font-size: rem-calc(20);
			padding-right: 0;
		}
		.media {
			padding-left: 32px;
			display: block;
			img {
				margin: 0;
			}
		}
	}

	.alternating-media-objects {
		.media-object:nth-child(even) {
			.media {
				padding-left: 0;
				padding-right: 32px;
			}
			.text {
				order: 1;
			}
		}
	}
}



/* @end Media Objects */

/*---------------------------------------------*\
    @group Simple Double Column Text Blocks
\*---------------------------------------------*/
.double-col {
	h2 {
		font-size: rem-calc(28);
		text-align: left;
	}
	.col {
		margin-bottom: 32px;
	}
}

@media screen and #{breakpoint(medium)} {
	.double-col {
		display: flex;
		justify-content: space-between;
		.col {
			width: 48%;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.double-col {
		font-size: rem-calc(18);
		h2 {
			font-size: rem-calc(38);
		}
	}
}

/* @end Simple Double Column Text Blocks */

/*------------------------------------*\
    @group Text Column Grid
\*------------------------------------*/
.text-column-grid {
	max-width: 400px;
	display: grid;
	grid-gap: 32px;
	margin: 0 auto 48px;
	h3 {
		font-weight: $alt-bold;
		text-align: left;
		border-bottom: solid 1px $light-gray;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
		.subhead {
			font-size: rem-calc(20);
			font-weight: $alt-normal;
		}
	}
	ul {
		padding-left: 0;
		margin-top: 10px;
		margin-bottom: 16px;
	}
	.highlight-text {
		font-family: $alt-font;
		font-size: rem-calc(32);
		font-weight: $alt-bold;
		color: $brand-alt;
		line-height: 1.1;
		.small {
			font-size: rem-calc(14);
			font-weight: $alt-normal;
		}
		.subheading {
			font-size: rem-calc(16);
			color: $main-color;
			display: block;
		}
	}
	.list-heading {
		font-weight: bold;
		margin-bottom: .5rem;
	}
}

@media screen and #{breakpoint(medium)} {
	.text-column-grid {
		max-width: 100%;
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and #{breakpoint(large)} {
	.text-column-grid {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 48px;
	}
}
/* @end Text Column Grid */

/*------------------------------------*\
    @group Icon Grid
\*------------------------------------*/
.icon-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 60px 30px;
}
.icon-grid-item {
	p {
		font-family: $alt-font;
		font-size: rem-calc(24);
		font-weight: $alt-bold;
		color: $gray;
		line-height: 1;
		margin: 0;
	}
}
.icon-grid-item-icon {
	height: 90px;
	margin-bottom: 15px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	img {
		margin: 0;
	}
}
/* @end Icon Grid */