.blog-template {
	.blog-features .adds-components {
		width: 100%;
	}
	.addsWg--hit h3.addsWg--title {
		font-size: rem-calc(20) !important;
		font-weight: $heading-normal;
		color: $brand-alt;
		&::after {
			display: none;
		}
	}
	.addsWg-searchfield-container-class .addsWg-searchfield input[type="search"]  {
		min-width: auto;
		width: 100%;
	}
	.addsWg-searchfield-container-class .addsWg-searchfield {
		max-width: 100%;
		min-height: 37px;
	}
	.addsWg-searchfield-container-class .addsWg-searchfield input[type="search"].icon {
		height: auto;
		background-color: $white;
		padding: .5rem;
		border: 1px solid $brand-alt-lightest;
	}
	a.addsWg--hit {
		border-color: $off-white;
	}
	a.addsWg--hit .addsWg--category {
		display: none;
	}
	a.addsWg--hit .addsWg--highlight {
		font-size: rem-calc(16);
	}
}

.blog-search-input {
	height: 37px;
	position: relative;
}