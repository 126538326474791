.highlight-items-bg {
	background: $off-white;
	padding: 30px;
	margin-bottom: 50px;
	> *:last-child {
		margin-bottom: 0;
	}
	h2 {
		font-size: rem-calc(30);
		color: $main-color;
		line-height: 1.1;
		text-align: left;
		margin-bottom: 10px;
		padding-bottom: 0;
		display: flex;
		align-items: center;
		gap: 10px;
		&::after {
			display: none;
		}
		img {
			margin: 0;
		}
	}
}

.highlight-item {
	margin-bottom: 50px;
}

.highlight-item-callout {
	max-width: 425px;
	background: $white;;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
	padding: 25px;
	margin: 0 auto;
	h2 {
		color: $brand-alt;
		padding-bottom: 0;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
	}
	.title {
		font-size: rem-calc(21);
		line-height: 1.5;
		margin-bottom: 0;
		span {
			font-size: rem-calc(16);
			line-height: 1.3;
			display: block;
		}
		a {
			color: $brand-alt;
			&:hover, &:focus {
				color: $brand-ally;
			}
		}
	}
}

@include breakpoint(800px) {
	.highlight-items-bg {
		padding: 60px;
		h2 {
			font-size: rem-calc(36);
		}
	}

	.highlight-item-callout {
		max-width: 100%;
	}

	.highlight-item-callout-content {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 30px;
		.text {
			flex-basis: 446px;
			order: 0;
		}
		.image {
			flex-basis: 377px;
			order: 1;
		}
	}
}

@include breakpoint(large) {
	.highlight-items-bg {
		font-size: rem-calc(21);
		padding: 100px;
		margin-bottom: 100px;
	}
}

@include breakpoint(1100px) {
	.highlight-item-callout {
		padding: 50px;
	}
}