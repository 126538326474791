/*------------------------------------*\
    @group Jitasa Experience Card
\*------------------------------------*/

.jitasa-experience-card.card {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom:0;
}

/* @end Testimonials List */

/*------------------------------------*\
    @group Modal Nonprofit Size
\*------------------------------------*/

#qualify-popup {
	max-width: 900px;
	h2 {
		font-size: rem-calc(28);
		font-weight: $alt-bold;
		color: $gray;
		&::after {
			display: none;
		}
	}
	p {
		margin: 0;
	}
	.nonprofit-size-guide {
		font-family: $alt-font;
		display: flex;
		flex-direction: column;
		border: solid 1px $light-gray;
		border-radius: $border-radius;
		margin-bottom: 10px;
		h3 {
			font-size: rem-calc(24);
			font-weight: bold;
			color: $brand-alt;
			padding: 0;
			&::after {
				display: none;
			}
		}
		.nonprofit-size {
			padding: 30px 20px;
			text-align: center;
			&:nth-child(2n+2) {
				background: $light-gray;
			}
		}
	}
	.disclaimer {
		max-width: 700px;
		font-size: rem-calc(12);
		color: $gray;
		margin: 0 auto;
	}
}

@media screen and #{breakpoint(medium)} {
	#qualify-popup {
		.nonprofit-size-guide {
			flex-direction: row;
		}
	}
}
/* @end Modal Nonprofit Size */


/*------------------------------------*\
    @group Tax Services Section Page
\*------------------------------------*/
.tax-services-section {
	padding-bottom: 32px;
	.flame-heading {
		text-align: center;
		margin-bottom: 48px;
		img {
			margin: 0;
		}
	}
	.callout-button {
		text-align: center;
	}
}
#tax-filings-amount {
	font-weight: 700;
}
/* @end Tax Services Section Page */