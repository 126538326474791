.bsa-quote-form {
	max-width: 900px;
	.bsa-quote-intro {
		margin-bottom: 30px;
		.media {
			text-align: center;
		}
	}
	.fields {
		display: grid;
		grid-template-columns: 1fr;
		gap: 30px;
		@include breakpoint(medium) {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		}
	}
	input {
		margin: 0;
	}
	select {
		height: 42px;
		margin: 0;
	}
	textarea {
		margin: 0;
	}
	fieldset {
		border: solid 3px $brand;
		margin-bottom: 40px;
		padding: 20px;
		@include breakpoint(900px) {
			padding: 30px;
		}
	}
	label,
	.field > p {
		margin-bottom: 5px;
	}
	legend {
		font-size: rem-calc(30);
		line-height: 1.2;
		margin-bottom: 0;
		padding-left: 20px;
		padding-right: 20px;
	}
	.field-full {
		grid-column: 1 / -1;
	}
	.bsa-radio-group {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 20px;
		input {
			margin-right: 5px;
		}
	}
}

@include breakpoint(medium) {
	.bsa-quote-form {
		.bsa-quote-intro {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;
			gap: 30px;
			.text {
				flex-basis: 600px;
			}
			.media {
				flex-basis: 270px;
			}
		}
	}
}

@include breakpoint(large) {
	.bsa-quote-form {
		.bsa-quote-intro {
			font-size: rem-calc(20);
		}
	}
}