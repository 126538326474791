.faq-question {
	margin-bottom: 8px;
	> a {
		font-family: $heading-font;
		font-size: rem-calc(22);
		color: $brand;
		&:hover,
		&:focus {
			color: $brand-alt;
		}
	}
	> h2 {
		font-family: $heading-font;
		font-size: rem-calc(22);
		color: $brand-alt;
		text-align: left;
		padding: 0;
		margin: 0;
		&::after {
			display: none;
		}
	}
	.faq-answer {
		padding: 16px 20px;
	}
}