.grid-2-offset-left-slight {
    max-width: 540px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 30px;
    margin: 0 auto;
}

@include breakpoint(800px) {
    .grid-2-offset-left-slight {
        max-width: 100%;
        grid-template-columns: 540fr 560fr;
    }
}

@include breakpoint(1200px) {
    .grid-2-offset-left-slight {
        gap: 30px 72px;
    }
}

.grid-col-2 {
    max-width: 560px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px 30px;
    margin: 0 auto;
}

@include breakpoint(800px) {
    .grid-col-2 {
        max-width: 100%;
        grid-template-columns: 1fr 1fr;
    }
}