.tooltip {
	max-width: 300px !important;
	box-shadow: $box-shadow;
}

.tippy-box {
	background-color: $white;
	color: $main-color;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.tippy-content {
	padding: 15px;
}

.tippy-box[data-placement^="top"] > .tippy-arrow::before {
	border-top-color: $white;
}