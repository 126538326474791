.quote-request-test {
    max-width: 790px;
    margin: 0 auto;
    h2 {
        font-size: rem-calc(30);
    }
    h3 {
        font-size: rem-calc(27);
        font-weight: $heading-bold;
        margin-bottom: 10px;
    }
    hr {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    p {
        font-size: rem-calc(18);
    }
}

.quote-request-test-cards {
    margin-bottom: 45px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    gap: 30px;
}

.quote-request-test-card {
    background: #1A1F75;
    color: $white;
    h3 {
        font-family: $main-font;
        font-size: rem-calc(20);
        font-weight: $main-normal;
        color: $white;
        padding-bottom: 0;
        margin-bottom: 40px;
        margin-top: 10px;
        &::after {
            display: none;
        }
    }
    a {
        color: $white;
    }
    .button {
        width: 100%;
        background-color: $white;
        color: $brand-ally;
        text-transform: uppercase;
        text-align: center;
        padding-top: 12px;
        padding-bottom: 12px;
        margin: 0;
        &:hover, &:focus {
            background-color:  #CED0F0;
        }
    }
    .highlight-color {
        color: #C5C8FF;
    }
    .content {
        background: $brand-ally;
        text-align: center;
        padding: 20px 20px 30px;
        p {
            font-size: rem-calc(20);
        }
    }
    .footer {
        padding: 20px;
        p {
            font-size: 1rem;
            margin: 0;
        }
    }
}

.quote-request-test-card.alt {
    background-color: #5E2366;
    .button {
        color: $brand-alt;
        &:hover, &:focus {
            background-color: #FBDDFF;
        }
    }
    .content {
        background-color: $brand-alt;
    }
    .highlight-color {
        color: #F9CEFF;
    }
}