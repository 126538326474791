.blog-template {
	.page-content {
		padding-top: 60px;
	}
}

@include breakpoint(medium) {
	.blog-template {
		.page-content {
			padding-top: 120px;
		}
	}
}

// Blog post list
@include breakpoint(700px) {
	.blog-post-list-wrapper {
		display: flex;
		align-items: flex-start;
		.blog-post-list-content {
			padding-right: 30px;
			flex-grow: 1;
		}
		.blog-post-list-sidebar {
			flex-basis: 400px;
		}
	}
}

@include breakpoint(900px) {
	.blog-post-list-wrapper {
		.blog-post-list-content {
			padding-right: 50px;
		}
		.blog-post-list-sidebar {
			flex-shrink: 0;
		}
	}
}

// Featured blog post on post list
.featured-blog-post {
	margin-bottom: 50px;
	.preview-image {
		margin-bottom: 20px;
		position: relative;
		img {
			margin: 0;
		}
		.featured-tag {
			background: $white;
			font-family: $alt-font;
			font-size: rem-calc(14);
			font-weight: $alt-normal;
			color: $brand-ally;
			line-height: 1;
			border-radius: 2px;
			padding: 5px 7px;
			position: absolute;
			top: 10px;
			left: 10px;
		}
	}
	h1 a {
		text-decoration: none;
	}
}

.featured-blog-post-divider {
	margin-bottom: 40px;
}

@include breakpoint(700px) {
	.featured-blog-post {
		display: flex;
		align-items: flex-start;
		.preview-image {
			flex-shrink: 1;
			flex-basis: 60%;
		}
		.preview-text {
			flex-grow: 1;
			flex-shrink: 2;
			padding-left: 32px;
			flex-basis: 40%;
		}
	}
	.featured-blog-post-divider {
		display: none;
	}
}

// Blog post list tiles
.blog-post-list-previews {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
	grid-gap: 60px 30px;
}

.blog-post-list-preview {
	max-width: 400px;
	margin: 0 auto;
	h2 {
		font-size: rem-calc(20);
		text-align: left;
		line-height: 1.2;
		margin: 0;
		padding: 0;
		&::after {
			display: none;
		}
	}
	a {
		text-decoration: none;
	}
	img {
		margin-bottom: 10px;
	}
	.blog-post-date {
		margin-bottom: 0;
	}
}

// Blog post title used for featured post and full post
.blog-post-title {
	font-size: rem-calc(32);
	color: $brand-ally;
	text-align: left;
	padding-bottom: 0;
	margin-bottom: 0;
	&::after {
		display: none;
	}
}

// Blog post date
.blog-post-date {
	font-family: $alt-font;
	font-size: rem-calc(14);
	color: $brand-ally;
	line-height: 1.5;
}

// Blog feature aside
.blog-features {
	background: #F8F8F8;
	padding: 20px;
	border: 1px solid #E4E4E4;
	.feature-box-heading {
		font-size: rem-calc(24);
		color: $brand-ally;
		line-height: 1.25;
		text-align: left;
		padding: 0;
		margin-bottom: 20px;
		&::after {
			display: none;
		}
	}
	.feature-box {
		// border-bottom: solid 1px #DDD;
		// padding-bottom: 20px;
		margin-bottom: 40px;
		&:last-child {
			// border: none;
			// padding-bottom: 0;
			margin-bottom: 0;
		}
	}
	.blog-email-subscribe-box {
		background-color: $brand-alt;
		padding: 20px;
		margin-left: -20px;
		margin-right: -20px;
		h2 {
			color: $white;
		}
	}
	.blog-search input {
		margin: 0;
	}
	.blog-recent-posts .blog-post-list-preview {
		margin-bottom: 45px;
		&:last-child {
			margin-bottom: 0;
		}
		.blog-post-date {
			margin-bottom: 0;
		}
	}
}

.blog-sidebar-callouts {
	display: grid;
	gap: 20px;
}

.blog-sidebar-callout {
	background-color: $white;
	border: solid 1px #E4E4E4;
	padding: 20px;
	.heading {
		margin-bottom: 10px;
		display: flex;
		gap: 7px;
		align-items: center;
		img {
			height: 40px;
			margin: 0;
		}
	}
	h3 {
		font-size: rem-calc(20);
		font-weight: $heading-normal;
		color: $brand-alt;
		line-height: 1.2;
		text-align: left;
		padding: 0;
		margin: 0;
		&::after {
			display: none;
		}
		a {
			font-weight: $heading-normal;
		}
	}
	a {
		font-weight: 700;
		text-decoration: none;
	}
}

//Blog pagination nav
.blog-pagination-nav {
	text-align: center;
	margin: 70px 0 50px;
	a {
		min-width: 120px;
		font-family: $alt-font;
		font-size: rem-calc(18);
		color: $gray;
		padding: 8px 24px;
		line-height: 1;
		border: solid 1px $gray;
		border-radius: 2px;
		margin: 0 5px;
		text-decoration: none;
		display: inline-block;
		&:hover, &:focus {
			color: $brand-ally;
		}
	}
}


// Blog post content
.blog-post {
	h2 {
		font-size: rem-calc(24);
		color: $bright-blue;
		font-weight: $heading-bold;
		text-align: left;
		margin-top: 36px;
		padding-bottom: 0;
		&::after {
			display: none;
		}
		+ h3 {
			margin-top: 24px;
		}
	}
	h3 {
		font-size: rem-calc(20);
		color: $main-color;
		font-weight: $heading-bold;
		text-align: left;
		margin-top: 36px;
		padding-bottom: 0;
		&::after {
			display: none;
		}
	}
	h4 {
		font-size: rem-calc(18);
		font-weight: $alt-normal;
		color: $main-color;
		text-align: left;
		margin-top: 36px;
		padding-bottom: 0;
		&::after {
			display: none;
		}
	}
	img {
		margin-top: 36px;
	}
	hr {
		margin: 48px auto;
	}
	.blog-post-tag-list {
		margin-top: 20px;
		margin-bottom: 30px;
	}
}


// FUll blog post page
@include breakpoint(800px) {
	.blog-post-full {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		.blog-content {
			flex-basis: 700px;
			margin-right: 50px;
		}
		.blog-features {
			max-width: 400px;
			flex-basis: 400px;
		}
	}
}

#blog-email-subscribe {
	legend {
		font-size: rem-calc(16);
		font-weight: bold;
		color: $white;
		margin-bottom: 8px;
	}
	.button.disabled {
		background-color: $gray;
	}
	.checkbox-field-list {
		margin-bottom: 20px;
	}
	.checkbox-field {
		display: flex;
		label {
			font-family: $main-font;
			font-size: rem-calc(16);
			color: $white;
		}
	}
	input[type="checkbox"] {
		margin-bottom: 0;
	}
	button[type="submit"] {
		width: 100%;
		padding: 10px;
		margin-bottom: 0;
		display: block;
	}
}


.blog-post-callout {
	padding-top: 30px;
	margin: 32px auto;
	.inner {
		max-width: 400px;
		background-color: $brand-alt;
		border-radius: $border-radius;
		color: $white;
		text-align: center;
		padding: 30px 20px;
		margin: 0 auto;
	}
	.button {
		margin: 0;
	}
	.media {
		margin-top: -60px;
		margin-bottom: 10px;
		img {
			margin: 0;
		}
	}
}

@media screen and (min-width:53.125em) {
	.blog-post-callout {
		padding: 16px 0;
		margin: 32px auto;
		.inner {
			max-width: 100%;
			padding: 20px 30px;
			text-align: left;
			margin: 0;
			display: flex;
			align-items: center;
		}
		.media {
			flex-shrink: 0;
			margin-top: -46px;
			margin-bottom: -46px;
			img {
				width: auto;
				max-width: 196px;
			}
		}
		.text {
			font-size: rem-calc(20);
			padding-left: 30px;
		}
	}
}

.blog-callout-full {
	background-color: $brand-alt;
	// background-image: url('https://jitasa.imgix.net/blog/callouts/bg_hands_on_calculator_purple.jpg?auto=format&w=705&h=200&fit=crop');
	background-size: cover;
	color: $white;
	text-align: center;
	padding: 20px;
	margin: 40px 0;
	// @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	// only screen and (-o-min-device-pixel-ratio: 13/10),
	// only screen and (min-resolution: 120dpi) {
	// 	background-image: url('https://jitasa.imgix.net/blog/callouts/bg_hands_on_calculator_purple.jpg?auto=format&w=705&h=200&fit=crop&dpr=2');
	// }
	h2 {
		color: $white;
		text-align: center;
		margin: 0 0 20px;
		padding-bottom: 0;
		+ p {
			margin-top: -10px;
		}
		&::after {
			display: none;
		}
	}
	p {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}
	.button {
		padding: 12px 25px;
		margin: 0;
	}
}

@include breakpoint(800px) {
	.blog-callout-full {
		padding: 25px 40px;
		h2 {
			max-width: 600px;
			font-size: rem-calc(30);
			margin-left: auto;
			margin-right: auto;
		}
	}
	.blog-callout-full.full-width-text {
		padding-left: 30px;
		padding-right: 30px;
		h2 {
			max-width: 100%;
		}
	}
}

// Blog callout media object
.blog-callout-media-object {
	background-color: $brand-ally;
	font-family: $alt-font;
	padding: 25px;
	margin: 40px 0;
	.button {
		padding: 12px 25px;
		margin: 0;
	}
	.media {
		text-align: center;
		margin-bottom: 25px;
		img {
			margin: 0;
		}
	}
	.text {
		font-size: rem-calc(18);
	}
	h2 {
		font-weight: $heading-bold;
		color: $white;
		margin: 0 0 15px;
	}
	p {
		color: $white;
	}
}

@include breakpoint(950px) {
	.blog-callout-media-object {
		display: grid;
		grid-template-columns: 1fr fit-content(200px);
		grid-gap: 20px;
		align-items: center;
		.media {
			margin-bottom: 0;
			order: 1;
		}
		.text {
			font-size: rem-calc(22);
		}
		h2 {
			font-size: rem-calc(30);
		}
	}


	.blog-callout-media-object.img-bottom-right {
		.media {
			margin-right: -25px;
			margin-bottom: -25px;
			align-self: end;
		}
	}

}
@include breakpoint(1200px) {
	.blog-callout-media-object {
		padding: 30px 20px 30px 30px;
		grid-template-columns: 1fr fit-content(260px);
	}

	.blog-callout-media-object.img-bottom-right {
		.media {
			margin-right: -20px;
			margin-bottom: -30px;
		}
	}
}

// Blog post tag list
.blog-post-tag {
	background-color: $light-gray;
	font-family: $alt-font;
	font-size: rem-calc(14);
	color: $main-color;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 1.29;
	padding: 5px 7px;
	border-radius: 2px;
	margin: 0 10px 10px 0;
	display: inline-block;
	transition: background-color .3s, color .3s;
	&:hover, &:focus {
		background-color: $brand-alt;
		color: $white;
	}
}

// Blog tag pages
.blog-tag-page-heading {
	font-size: rem-calc(40);
	color: $brand-ally;
	text-align: left;
	margin-bottom: 32px;
}

// Back to blog link
.back-to-blog-link {
	font-family: $alt-font;
	font-size: rem-calc(16);
	font-weight: 400;
	margin-bottom: 20px;
	a {
		text-decoration: none;
	}
}