/*------------------------------------*\
    @group Hero
\*------------------------------------*/

.hero {
	height: 350px;
	background: url('https://jitasa.imgix.net/bg/hero/man_pushing_kid_wheelbarrow.jpg?auto=format') no-repeat center top;
	background-size: cover;
	text-align: center;
	position: relative;
	h1, .h1 {
		font-family: $main-font;
		font-size: rem-calc(32);
		color: $brand-alt;
		text-align: left;
		text-shadow: $text-shadow;
		padding: 0;
		margin: 0;
		&::after {
			display: none;
		}
		&.text-center {
			text-align: center;
		}
		a {
			color: $brand;
			&:hover, &:focus {
				color: lighten($brand, 10%);
			}
		}
	}
	p,
	.p-heading {
		max-width: 500px;
		font-size: rem-calc(18);
		color: $gray;
		line-height: 1.29;
		text-shadow: $text-shadow;
		margin: 0 auto 12px;
		&.wide {
			max-width: 840px;
		}

	}
	.button	{
		margin-top: 15px;
	}
	.hero-content {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.hero {
		height: 500px;
		background-image: url('https://jitasa.imgix.net/bg/hero/man_pushing_kid_wheelbarrow_lrg.jpg?auto=format');
		h1, .h1 {
			font-size: rem-calc(58);
		}
		p,
		.p-heading {
			font-size: rem-calc(28);
		}
	}
}


//Hero backgrounds
.bg-laptop-hands {background-image: url('https://jitasa.imgix.net/bg/hero/accountants_using_laptop.jpg?auto=format');}
.bg-africa-yoga-project {background-image: url('https://jitasa.imgix.net/bg/hero/africa_yoga_project.jpg?auto=format');}
.bg-handshake {background-image: url('https://jitasa.imgix.net/bg/hero/people_shaking_hands.jpg?auto=format');}
.bg-boy-scouts {background-image: url('https://jitasa.imgix.net/bg/hero/boy_scouts_of_america.jpg?auto=format');}
.bg-lemur {background-image: url('https://jitasa.imgix.net/bg/hero/marat_karpeka_lemur.jpg?auto=format');}
.bg-blue-laptop-desk {background-image: url('https://jitasa.imgix.net/bg/hero/blue_laptop_on_desk.jpg?auto=format');background-color: #0F1630;}
.bg-kids-running-hallway {background-image: url('https://jitasa.imgix.net/bg/hero/kids_running_in_hallway.jpg?auto=format');background-color:#59256F;}
.bg-hands-joining {background-image: url('https://jitasa.imgix.net/bg/hero/hands_joining_together.jpg?auto=format');background-color:#437830;}
.bg-hands-together {background-image: url('https://jitasa.imgix.net/bg/hero/hands_together.jpg?auto=format');background-color:#192347;}
.bg-team-working-computer {background-image: url('https://jitasa.imgix.net/bg/hero/team_working_around_computer.jpg?auto=format');background-color:#0F5B75;}
.bg-san-francisco {background-image: url('https://jitasa.imgix.net/bg/hero/san_francisco_bridge.jpg?auto=format');}
.bg-students-talking-hillel {background-image: url('https://jitasa.imgix.net/bg/hero/students_talking_hillel.jpg?auto=format');}
.bg-boy-scout-shirt {background-image: url('https://jitasa.imgix.net/bg/hero/boy_scout_shirt.jpg?auto=format');}
// .bg-elephants-crossing-water {background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water.jpg?auto=format');background-color:#D69F5E;}
.bg-little-girls-learning {background-image: url('https://jitasa.imgix.net/bg/hero/little_girls_learning_english.jpg?auto=format');}
.bg-museum-natural-history {background-image: url('https://jitasa.imgix.net/bg/hero/museum_of_natural_history.jpg?auto=format');}
.bg-office-computer-phone-data {background-image: url('https://jitasa.imgix.net/bg/hero/office_computer_phone_data.jpg?auto=format');background-color:#B3B6BD;}
.bg-office-desk-above {background-image: url('https://jitasa.imgix.net/bg/hero/office_desk_above.jpg?auto=format');background-color: #A97953;}
.bg-new-york-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/new_york_skyline.jpg?auto=format');}
.bg-book-cross {background-image: url('https://jitasa.imgix.net/bg/hero/book_cross.jpg?auto=format');}
.bg-george-washington-statue {background-image: url('https://jitasa.imgix.net/bg/hero/george_washington_statue.jpg?auto=format');}
.bg-atlanta-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/atlanta_skyline.jpg?auto=format');}
.bg-hawaii-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/hawaii_landscape.jpg?auto=format');}
.bg-philadelphia-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/philadelphia_skyline.jpg?auto=format');}
.bg-minnesota-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/minnesota_landscape.jpg?auto=format');}
.bg-washington-dc-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/washington_dc_skyline.jpg?auto=format');}
.bg-austin-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/austin_skyline.jpg?auto=format');}
.bg-denver-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/denver_skyline.jpg?auto=format');}
.bg-phoenix-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/phoenix_landscape.jpg?auto=format');}
.bg-baltimore-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/baltimore_landscape.jpg?auto=format');}
.bg-portland-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/portland_landscape.jpg?auto=format');}
.bg-tax-papers-above {background-image: url('https://jitasa.imgix.net/bg/hero/tax_papers_above.jpg?auto=format');}
.bg-raleigh-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/raleigh_skyline.jpg?auto=format');}
.bg-concord-capitol {background-image: url('https://jitasa.imgix.net/bg/hero/concord_capitol.jpg?auto=format');}
.bg-detroit-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/detroit_skyline.jpg?auto=format');}
.bg-colleagues-reading-desk {background-image: url('https://jitasa.imgix.net/bg/hero/colleagues_desk_reading.jpg?auto=format&fit=crop&crop=focalpoint&w=640&h=400&fp-x=0.50&fp-y=0.50&fp-z=1.2');}
.bg-man-woman-desk-computer {background-image: url('https://jitasa.imgix.net/bg/hero/man_woman_desk_computer_lrg.jpg?auto=format&fit=crop&w=640&h=580');}
.bg-us-map-closeup {background-image: url('https://jitasa.imgix.net/bg/hero/bg_us_map_closeup.jpg?auto=format&fit=crop&w=640&h=580');}
.bg-people-working-by-river {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_working_by_river.jpg?auto=format&w=640');background-color: #7f8458;}
.bg-people-walking-apartments {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_walking_apartments.jpg?auto=format&w=640');background-color: #858281;}
.bg-ameinu-rally {background-image: url('https://jitasa.imgix.net/bg/hero/bg_ameinu_rally.jpg?auto=format&w=640');background-color: #AFB4D2;}
.bg-conversation-at-table {background-image: url('https://jitasa.imgix.net/bg/hero/bg_conversation_at_table.jpg?auto=format&w=640&h=400&fit=crop&');background-color: #F8F8F6;}
.bg-imago-africa-class {background-image: url('https://jitasa.imgix.net/bg/hero/bg_imago_africa_class.jpg?auto=format&w=640');background-color: #DADADA;}
.bg-sasb-meeting {background-image: url('https://jitasa.imgix.net/bg/hero/bg_sasb_meeting.jpg?auto=format&w=640&h=500&fit=crop');background-color: #A7ABB7;}
.bg-virginia-headwaters {background-image: url('https://jitasa.imgix.net/bg/hero/bg_scouts_forest.jpg?auto=format&w=640&h=500&fit=crop');background-color: #876D5F;}
.bg-boys-girls-club {background-image: url('https://jitasa.imgix.net/bg/hero/boys_girls_bridge.jpg?auto=format&w=640&h=500&fit=crop');background-color: #4A7445;}
.bg-forsyth-group {background-image: url('https://jitasa.imgix.net/bg/hero/family_promise_group.jpg?auto=format&w=640&h=500&fit=crop');background-color: #8A4A39;}
.bg-hillel-stanford {background-image: url('https://jitasa.imgix.net/bg/hero/hillel_at_stanford_group.jpg?auto=format&w=640&h=500&fit=crop');background-color: #676251;}
.bg-family-promise {background-color: #473357 !important;}
.bg-ywca-harbor {background-image: url('https://jitasa.imgix.net/bg/hero/ywca_harbor_area.jpg?auto=format&w=640&h=500&fit=crop'); background-color: #756858;}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.bg-us-map-closeup {background-image: url('https://jitasa.imgix.net/bg/hero/bg_us_map_closeup.jpg?auto=format&fit=crop&w=640&h=580&dpr=2&q=20');}
	.bg-people-working-by-river {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_working_by_river.jpg?auto=format&dpr=2&q=20&w=640');}
	.bg-people-walking-apartments {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_walking_apartments.jpg?auto=format&dpr=2&q=20&w=640');}
	.bg-ameinu-rally {background-image: url('https://jitasa.imgix.net/bg/hero/bg_ameinu_rally.jpg?auto=format&dpr=2&q=20&w=640');}
	.bg-conversation-at-table {background-image: url('https://jitasa.imgix.net/bg/hero/bg_conversation_at_table.jpg?auto=format&w=640&h=400&fit=crop&&dpr=2&q=20');}
	.bg-imago-africa-class {background-image: url('https://jitasa.imgix.net/bg/hero/bg_imago_africa_class.jpg?auto=format&dpr=2&q=20&w=640');}
	.bg-sasb-meeting {background-image: url('https://jitasa.imgix.net/bg/hero/bg_sasb_meeting.jpg?auto=format&w=640&h=500&fit=crop&dpr=2&q=20&w=640');}
	.bg-virginia-headwaters {background-image: url('https://jitasa.imgix.net/bg/hero/bg_scouts_forest.jpg?auto=format&w=640&h=500&fit=crop&dpr=2&q=20');}
	.bg-boys-girls-club {background-image: url('https://jitasa.imgix.net/bg/hero/boys_girls_bridge.jpg?auto=format&w=640&h=500&fit=crop&dpr=2&q=20');}
	.bg-forsyth-group {background-image: url('https://jitasa.imgix.net/bg/hero/family_promise_group.jpg?auto=format&w=640&h=500&fit=crop&dpr=2&q=20');}
	.bg-hillel-stanford {background-image: url('https://jitasa.imgix.net/bg/hero/hillel_at_stanford_group.jpg?auto=format&w=640&h=500&fit=crop&dpr=2&q=20');}
	.bg-ywca-harbor {background-image: url('https://jitasa.imgix.net/bg/hero/ywca_harbor_area.jpg?auto=format&w=640&h=500&fit=crop&dpr=2&q=20');}
}

@media screen and #{breakpoint(medium)} {
	.bg-laptop-hands {background-image: url('https://jitasa.imgix.net/bg/hero/accountants_using_laptop_lrg.jpg?auto=format');}
	.bg-africa-yoga-project {background-image: url('https://jitasa.imgix.net/bg/hero/africa_yoga_project_lrg.jpg?auto=format');}
	.bg-handshake {background-image: url('https://jitasa.imgix.net/bg/hero/people_shaking_hands_lrg.jpg?auto=format');}
	.bg-boy-scouts {background-image: url('https://jitasa.imgix.net/bg/hero/boy_scouts_of_america_lrg.jpg?auto=format');}
	.bg-lemur {background-image: url('https://jitasa.imgix.net/bg/hero/marat_karpeka_lemur_lrg.jpg?auto=format');}
	.bg-blue-laptop-desk {background-image: url('https://jitasa.imgix.net/bg/hero/blue_laptop_on_desk_lrg.jpg?auto=format');}
	.bg-kids-running-hallway {background-image: url('https://jitasa.imgix.net/bg/hero/kids_running_in_hallway_lrg.jpg?auto=format');}
	.bg-hands-joining {background-image: url('https://jitasa.imgix.net/bg/hero/hands_joining_together_lrg.jpg?auto=format');}
	.bg-hands-together {background-image: url('https://jitasa.imgix.net/bg/hero/hands_together_lrg.jpg?auto=format');}
	.bg-team-working-computer {background-image: url('https://jitasa.imgix.net/bg/hero/team_working_around_computer_lrg.jpg?auto=format');}
	.bg-san-francisco {background-image: url('https://jitasa.imgix.net/bg/hero/san_francisco_bridge_lrg.jpg?auto=format');}
	.bg-students-talking-hillel {background-image: url('https://jitasa.imgix.net/bg/hero/students_talking_hillel_lrg.jpg?auto=format');}
	.bg-boy-scout-shirt {background-image: url('https://jitasa.imgix.net/bg/hero/boy_scout_shirt_med.jpg?auto=format');}
	// .bg-elephants-crossing-water {background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water_med.jpg?auto=format');}
	.bg-little-girls-learning {background-image: url('https://jitasa.imgix.net/bg/hero/little_girls_learning_english_med.jpg?auto=format');}
	.bg-museum-natural-history {background-image: url('https://jitasa.imgix.net/bg/hero/museum_of_natural_history_med.jpg?auto=format');}
	.bg-office-computer-phone-data {background-image: url('https://jitasa.imgix.net/bg/hero/office_computer_phone_data_med.jpg?auto=format');}
	.bg-office-desk-above {background-image: url('https://jitasa.imgix.net/bg/hero/office_desk_above_med.jpg?auto=format');}
	.bg-new-york-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/new_york_skyline_lrg.jpg?auto=format');}
	.bg-book-cross {background-image: url('https://jitasa.imgix.net/bg/hero/book_cross_lrg.jpg?auto=format');}
	.bg-george-washington-statue {background-image: url('https://jitasa.imgix.net/bg/hero/george_washington_statue_lrg.jpg?auto=format');}
	.bg-atlanta-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/atlanta_skyline_lrg.jpg?auto=format');}
	.bg-hawaii-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/hawaii_landscape_lrg.jpg?auto=format');}
	.bg-philadelphia-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/philadelphia_skyline_lrg.jpg?auto=format');}
	.bg-minnesota-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/minnesota_landscape_lrg.jpg?auto=format');}
	.bg-washington-dc-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/washington_dc_skyline_lrg.jpg?auto=format');}
	.bg-austin-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/austin_skyline_lrg.jpg?auto=format');}
	.bg-denver-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/denver_skyline_lrg.jpg?auto=format');}
	.bg-phoenix-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/phoenix_landscape_lrg.jpg?auto=format');}
	.bg-baltimore-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/baltimore_landscape_lrg.jpg?auto=format');}
	.bg-portland-landscape {background-image: url('https://jitasa.imgix.net/bg/hero/portland_landscape_lrg.jpg?auto=format');}
	.bg-tax-papers-above {background-image: url('https://jitasa.imgix.net/bg/hero/tax_papers_above_med.jpg?auto=format');}
	.bg-raleigh-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/raleigh_skyline_lrg.jpg?auto=format');}
	.bg-concord-capitol {background-image: url('https://jitasa.imgix.net/bg/hero/concord_capitol_lrg.jpg?auto=format');}
	.bg-detroit-skyline {background-image: url('https://jitasa.imgix.net/bg/hero/detroit_skyline_lrg.jpg?auto=format');}
	.bg-colleagues-reading-desk {background-image: url('https://jitasa.imgix.net/bg/hero/colleagues_desk_reading.jpg?auto=format&fit=crop&crop=focalpoint&w=1000&h=700&fp-x=0.50&fp-y=0.60&fp-z=1.2');}
	.bg-man-woman-desk-computer {background-image: url('https://jitasa.imgix.net/bg/hero/man_woman_desk_computer_lrg.jpg?auto=format&w=1920');}
	.bg-us-map-closeup {background-image: url('https://jitasa.imgix.net/bg/hero/bg_us_map_closeup.jpg?auto=format&w=1920');}
	.bg-people-working-by-river {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_working_by_river.jpg?auto=format&fit=crop&crop=focalpoint&w=1000&h=700&fp-x=0.50&fp-y=0.60&fp-z=1.2');}
	.bg-people-walking-apartments {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_walking_apartments.jpg?auto=format&fit=crop&crop=focalpoint&w=1000&h=700&fp-x=0.50&fp-y=0.60&fp-z=1.2');}
	.bg-ameinu-rally {background-image: url('https://jitasa.imgix.net/bg/hero/bg_ameinu_rally.jpg?auto=format&fit=crop&w=1000&h=700');}
	.bg-conversation-at-table {background-image: url('https://jitasa.imgix.net/bg/hero/bg_conversation_at_table.jpg?auto=format&fit=crop&w=1000&h=700')}
	.bg-imago-africa-class {background-image: url('https://jitasa.imgix.net/bg/hero/bg_imago_africa_class.jpg?auto=format&fit=crop&crop=bottom&w=1000&h=500');}
	.bg-sasb-meeting {background-image: url('https://jitasa.imgix.net/bg/hero/bg_sasb_meeting.jpg?auto=format&fit=crop&w=1000&h=500');}
	.bg-virginia-headwaters {background-image: url('https://jitasa.imgix.net/bg/hero/bg_scouts_forest.jpg?auto=format&w=1000&h=500&fit=crop');}
	.bg-boys-girls-club {background-image: url('https://jitasa.imgix.net/bg/hero/boys_girls_bridge.jpg?auto=format&w=1000&h=500&fit=crop');}
	.bg-forsyth-group {background-image: url('https://jitasa.imgix.net/bg/hero/family_promise_group.jpg?auto=format&w=1000&h=500&fit=crop');}
	.bg-hillel-stanford {background-image: url('https://jitasa.imgix.net/bg/hero/hillel_at_stanford_group.jpg?auto=format&w=1000&h=500&fit=crop');}
	.bg-ywca-harbor {background-image: url('https://jitasa.imgix.net/bg/hero/ywca_harbor_area.jpg?auto=format&w=1000&h=500&fit=crop');}
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.bg-us-map-closeup {background-image: url('https://jitasa.imgix.net/bg/hero/bg_us_map_closeup.jpg?auto=format&w=1920&dpr=2&q=20');}
		.bg-people-working-by-river {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_working_by_river.jpg?auto=format&fit=crop&crop=focalpoint&w=1000&h=700&fp-x=0.50&fp-y=0.60&fp-z=1.2&dpr=2&q=20');}
		.bg-people-walking-apartments {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_walking_apartments.jpg?auto=format&fit=crop&crop=focalpoint&w=1000&h=700&fp-x=0.50&fp-y=0.60&fp-z=1.2&dpr=2&q=20');}
		.bg-ameinu-rally {background-image: url('https://jitasa.imgix.net/bg/hero/bg_ameinu_rally.jpg?auto=format&fit=crop&w=1000&h=700&dpr=2&q=20');}
		.bg-conversation-at-table {background-image: url('https://jitasa.imgix.net/bg/hero/bg_conversation_at_table.jpg?auto=format&fit=crop&w=1000&h=700&dpr=2&q=20');}
		.bg-imago-africa-class {background-image: url('https://jitasa.imgix.net/bg/hero/bg_imago_africa_class.jpg?auto=format&fit=crop&crop=bottom&w=1000&h=500&dpr=2&q=20');}
		.bg-sasb-meeting {background-image: url('https://jitasa.imgix.net/bg/hero/bg_sasb_meeting.jpg?auto=format&fit=crop&w=1000&h=500&dpr=2&q=20');}
		.bg-virginia-headwaters {background-image: url('https://jitasa.imgix.net/bg/hero/bg_scouts_forest.jpg?auto=format&w=1000&h=500&fit=crop&dpr=2&q=20')}
		.bg-boys-girls-club {background-image: url('https://jitasa.imgix.net/bg/hero/boys_girls_bridge.jpg?auto=format&w=1000&h=500&fit=crop&dpr=2&q=20');}
		.bg-forsyth-group {background-image: url('https://jitasa.imgix.net/bg/hero/family_promise_group.jpg?auto=format&w=1000&h=500&fit=crop&dpr=2&q=20');}
		.bg-hillel-stanford {background-image: url('https://jitasa.imgix.net/bg/hero/hillel_at_stanford_group.jpg?auto=format&w=1000&h=500&fit=crop&dpr=2&q=20');}
		.bg-ywca-harbor {background-image: url('https://jitasa.imgix.net/bg/hero/ywca_harbor_area.jpg?auto=format&w=1000&h=500&fit=crop&dpr=2&q=20');}
	}
}

@media screen and #{breakpoint(large)} {
	.bg-boy-scout-shirt {background-image: url('https://jitasa.imgix.net/bg/hero/boy_scout_shirt_lrg.jpg?auto=format');}
	// .bg-elephants-crossing-water {background-image: url('https://jitasa.imgix.net/bg/hero/elephants_crossing_water_lrg.jpg?auto=format');}
	.bg-little-girls-learning {background-image: url('https://jitasa.imgix.net/bg/hero/little_girls_learning_english_lrg.jpg?auto=format');}
	.bg-museum-natural-history {background-image: url('https://jitasa.imgix.net/bg/hero/museum_of_natural_history_lrg.jpg?auto=format');}
	.bg-office-computer-phone-data {background-image: url('https://jitasa.imgix.net/bg/hero/office_computer_phone_data_lrg.jpg?auto=format');}
	.bg-office-desk-above {background-image: url('https://jitasa.imgix.net/bg/hero/office_desk_above_lrg.jpg?auto=format');}
	.bg-tax-papers-above {background-image: url('https://jitasa.imgix.net/bg/hero/tax_papers_above_lrg.jpg?auto=format');}
	.bg-colleagues-reading-desk {background-image: url('https://jitasa.imgix.net/bg/hero/colleagues_desk_reading.jpg?auto=format&fit=crop&w=1680&h=700&fp-x=0.30&fp-y=0.70');}
	.bg-people-working-by-river {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_working_by_river.jpg?auto=format&fit=crop&crop=focalpoint&w=1680&h=700&fp-x=0.40&fp-y=0.70');}
	.bg-people-walking-apartments {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_walking_apartments.jpg?auto=format&fit=crop&crop=focalpoint&w=1680&h=700&fp-x=0.40&fp-y=0.70');}
	.bg-ameinu-rally {background-image: url('https://jitasa.imgix.net/bg/hero/bg_ameinu_rally.jpg?auto=format&fit=crop&w=1680&h=700');}
	.bg-conversation-at-table {background-image: url('https://jitasa.imgix.net/bg/hero/bg_conversation_at_table.jpg?auto=format&fit=crop&w=1680&h=650');}
	.bg-imago-africa-class {background-image: url('https://jitasa.imgix.net/bg/hero/bg_imago_africa_class.jpg?auto=format&fit=crop&crop=bottom&w=1680&h=700');}
	.bg-sasb-meeting {background-image: url('https://jitasa.imgix.net/bg/hero/bg_sasb_meeting.jpg?auto=format&fit=crop&w=1680&h=500');}
	.bg-virginia-headwaters {background-image: url('https://jitasa.imgix.net/bg/hero/bg_scouts_forest.jpg?auto=format&w=1680&h=500&fit=crop')}
	.bg-boys-girls-club {background-image: url('https://jitasa.imgix.net/bg/hero/boys_girls_bridge.jpg?auto=format&w=1680&h=500&fit=crop');}
	.bg-forsyth-group {background-image: url('https://jitasa.imgix.net/bg/hero/family_promise_group.jpg?auto=format&w=1680&h=500&fit=crop&crop=focalpoint&fp-y=0.6')}
	.bg-hillel-stanford {background-image: url('https://jitasa.imgix.net/bg/hero/hillel_at_stanford_group.jpg?auto=format&w=1680&h=500&fit=crop&crop=top');}
	.bg-ywca-harbor {background-image: url('https://jitasa.imgix.net/bg/hero/ywca_harbor_area.jpg?auto=format&w=1680&h=500&fit=crop&crop=focalpoint&fp-y=0.35');}
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.bg-people-working-by-river {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_working_by_river.jpg?auto=format&fit=crop&crop=focalpoint&w=1680&h=700&fp-x=0.40&fp-y=0.70&dpr=2&q=20');}
		.bg-people-walking-apartments {background-image: url('https://jitasa.imgix.net/bg/hero/bg_people_walking_apartments.jpg?auto=format&fit=crop&crop=focalpoint&w=1680&h=700&fp-x=0.40&fp-y=0.70&dpr=2&q=20');}
		.bg-ameinu-rally {background-image: url('https://jitasa.imgix.net/bg/hero/bg_ameinu_rally.jpg?auto=format&fit=crop&w=1680&h=700&dpr=2&q=20');}
		.bg-conversation-at-table {background-image: url('https://jitasa.imgix.net/bg/hero/bg_conversation_at_table.jpg?auto=format&fit=crop&w=1680&h=650&dpr=2&q=20');}
		.bg-imago-africa-class {background-image: url('https://jitasa.imgix.net/bg/hero/bg_imago_africa_class.jpg?auto=format&fit=crop&crop=bottom&w=1680&h=700&dpr=2&q=20');}
		.bg-sasb-meeting {background-image: url('https://jitasa.imgix.net/bg/hero/bg_sasb_meeting.jpg?auto=format&fit=crop&w=1680&h=500&dpr=2&q=20');}
		.bg-virginia-headwaters {background-image: url('https://jitasa.imgix.net/bg/hero/bg_scouts_forest.jpg?auto=format&w=1680&h=500&fit=crop&dpr=2&q=20');}
		.bg-boys-girls-club {background-image: url('https://jitasa.imgix.net/bg/hero/boys_girls_bridge.jpg?auto=format&w=1680&h=500&fit=crop&dpr=2&q=20');}
		.bg-forsyth-group {background-image: url('https://jitasa.imgix.net/bg/hero/family_promise_group.jpg?auto=format&w=1680&h=500&fit=crop&crop=focalpoint&fp-y=0.6&dpr=2&q=20');}
		.bg-hillel-stanford {background-image: url('https://jitasa.imgix.net/bg/hero/hillel_at_stanford_group.jpg?auto=format&w=1680&h=500&fit=crop&crop=top&dpr=2&q=20');}
		.bg-ywca-harbor {background-image: url('https://jitasa.imgix.net/bg/hero/ywca_harbor_area.jpg?auto=format&w=1680&h=500&fit=crop&crop=focalpoint&fp-y=0.35&dpr=2&q=20');}
	}
}


//Short hero
.hero-short {
	height: auto;
	background-image: none;
	background-color: $off-white;
	padding: 0;
	margin-bottom: 1rem;
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
	position: relative;
	z-index: 20;
	.hero-content {
		padding-top: 40px;
		padding-bottom: 45px;
		text-align: left;
		top: auto;
		transform: none;
		position: relative;
		&::after {
			content: '';
			width: 50px;
			height: 50px;
			background: url('https://jitasa.imgix.net/cssimg/flames_xlrg.png?auto=format') no-repeat center bottom;
			background-size: 50px 50px;
			position: absolute;
			bottom: -25px;
			left: 50%;
			margin-left: -25px;
		}
		p {
			max-width: 100%;
		}
	}
	h1 {
		color: $brand-ally;
		text-shadow: none;
	}
}

.hero-short-subheading {
	p {
		font-size: rem-calc(18);
		margin-top: 4px;
		span {
			font-weight: bold;
			color: $brand-alt;
		}
	}
}

@media screen and #{breakpoint(medium)} {

	.hero-short {
		.hero-content {
			padding-top: 100px;
		}
	}

}

@media only screen and (min-width:56.25em) {

.hero-short {
	.hero-content {
		padding-right: 260px;
		padding-bottom: 50px;
		overflow: hidden;
		&::after {
			background-size: auto;
			width: 260px;
			height: 260px;
			margin: 0;
			left: auto;
			right: 0;
			bottom: -80px;
		}
	}
	h1 {
		text-align: left;
	}
}

}

//Hero with highlight numbers
.hero-numbers {
	height: 645px;
	h1, .h1 {
		font-family: $heading-font;
		font-size: rem-calc(64);
		span {
			font-family: $main-font;
			font-size: rem-calc(20);
			color: $gray;
			display: block;
			margin-top: 6px;
		}
	}
	.first-col {
		position: relative;
	}
	.divider {
		color: $white;
		margin: 20px 0 10px;
		position: relative;
		span {
			background-color: $brand;
			padding: 5px 10px;
			position: relative;
			z-index: 20;
		}
		&::after {
			content: '';
			width: 260px;
			background: $brand;
			height: 2px;
			margin-left: -130px;
			position: absolute;
			left: 50%;
			right: 0;
			bottom: 11px;
		}
	}
}

@media only screen and (min-width:50em) {
	.hero-numbers {
		height: 560px;
		.hero-content {
			max-width: 900px;
		}
		h1, .h1 {
			float: left;
		}
		.hero-col {
			float: left;
			padding: 0 50px;
			position: relative;
		}
		.first-col {
			width: 30%;
			padding-left: 0;
			padding-right: 70px;
		}
		.mid-col {
			width: 30%;
		}
		.last-col {
			width: 40%;
		}
		.divider {
			margin-top: 50px;
			margin-left: 20px;
			position: absolute;
			right: 0;
			span {
			}
			&::after {
				transform: rotate(-62deg);

			}
		}
	}
}


//Hero with focus on bg image and minimal text
.hero-image {
	height: 400px;
	background-size: cover;
	position: relative;
	.hero-content {
		height: 400px;
		position: relative;
		text-align: center;
	}
	h1 {
		background-color: rgba($brand, .7);
		font-size: rem-calc(32);
		color: $white;
		line-height: 1.13;
		padding: 10px 15px;
		margin: 0;
		display: inline-block;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		&::after {
			display: none;
		}
		span {
			line-height: 1;
			display: inline-block;
			&:last-child {
				padding-top: 3px;
			}
		}
		&.purple {
			background-color: rgba($brand-alt, .7);
		}
		&.dark-blue {
			background-color: rgba($dark-blue, .7);
		}
	}
}
.hero-image-tall {
	h1 {
		.small-text {
			max-width: 300px;
			font-size: rem-calc(18);
			line-height: 1.1;
		}
	}
}

//Rotating background hero
.hero-image-rotate {
	display: flex;
	align-items: center;
	justify-content: center;
	.hero-content {
		height: auto;
	}
	h1, .h1 {
		font-size: rem-calc(24);
		padding: 15px 20px 20px;
		position: relative;
		top: auto;
		bottom: auto;
		right: auto;
		left: auto;
		transform: none;
	}
	.small-text {
		margin-top: 10px;
	}
}

@media screen and #{breakpoint(medium)} {
	.hero-image {
		height: 550px;
		h1 {
			font-size: rem-calc(44);
			text-align: right;
			margin-right: .9375rem;
			top: auto;
			transform: none;
			position: absolute;
			right: 0;
			bottom: 70px;
		}
	}
	.hero-image.short {
		height: 400px;
		.hero-content {
			height: 400px;
		}
	}
	.hero-image-tall {
		height: 700px;
		.hero-content {
			text-align: left;
		}
		h1 {
			font-size: rem-calc(64);
			text-align: center;
			margin-right: 0;
			position: relative;
			top: 50%;
			left: 50%;
			bottom: auto;
			right: auto;
			transform: translate(-50%,-50%);
			.small-text {
				max-width: 650px;
				font-size: rem-calc(32);
			}
		}
	}
	.hero-image-rotate {
		h1, .h1 {
			font-size: rem-calc(48px);
			padding: 20px 25px 25px;
			position: relative;
			top: auto;
			bottom: auto;
			right: auto;
			left: auto;
			transform: none;
		}
	}
}

//Hero with media object
.hero-media {
	background-color: $brand-alt;
	padding: 50px 0;
	h1 {
		font-size: rem-calc(36);
		color: $white;
		line-height: 1;
		text-align: left;
		padding: 0;
		margin: 0;
		&::after {
			display: none;
		}
		&.small-heading {
			font-size: rem-calc(30);
		}
	}
	p {
		font-family: $heading-font;
		font-size: rem-calc(24);
		color: $brand;
		line-height: 1.33;
		margin: 3px 0 0;
		&.small-text {
			font-size: rem-calc(24);
			line-height: 1.25;
		}
	}
	.text {
		margin-bottom: 30px;
	}
	.media {
		text-align: center;
		position: relative;
		img {
			margin: 0;
		}
		.play-button {
			color: #202699;
			display: inline-block;
			position: relative;
			&:hover, &:focus  {
				color: #8D3499;
			}
		}
		.play-button-icon {
			width: 23.3644%;
			height: 41.49377%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: .3s all;
			svg {
				width: 100%;
				height: 100%;

			}
			circle {
				fill: currentColor;
			}
		}
	}
	&.bg-geometric-shapes {
		background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes.jpg?auto=format&w=640');
		background-color: #452670;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&.bg-geometric-shapes-blue {
		background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes_blue.jpg?auto=format&w=640');
		background-color: #151c60;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&.bg-ymca-shapes {
		background-image: url('https://jitasa.imgix.net/bg/hero/bg_ymca_shapes.jpg?w=640&h=450&fit=crop');
		background-color: #472772;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&.bg-bgca-shapes {
		background-image: url('https://jitasa.imgix.net/bg/hero/bgca_shapes.jpg?w=640&h=450&fit=crop');
		background-color: #00466C;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&.bg-jcc-shapes {
		background-image: url('https://jitasa.imgix.net/bg/hero/jcc_shapes.jpg?w=640&h=450&fit=crop');
		background-color: #03474D;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.hero-media.extra-content {
	.contained {
		max-width: 960px;
	}
	h1 {
		margin-bottom: 20px;
	}
	p {
		font-family: $main-font;
		font-size: rem-calc(18);
		color: $white;
		line-height: 1.5;
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.hero-media {
		&.bg-geometric-shapes {
			/* autoprefixer: ignore next */
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes@2x.jpg?auto=format&w=640&dpr=2');
			background-color: #452670;
		}
		&.bg-geometric-shapes {
			/* autoprefixer: ignore next */
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes_blue.jpg?auto=format&dpr=2&w=640');
			background-color: #452670;
		}
		&.bg-ymca-shapes {
			/* autoprefixer: ignore next */
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_ymca_shapes.jpg?w=640&h=450&fit=crop&dpr=2');
			background-color: #472772;
		}
		&.bg-bgca-shapes {
			/* autoprefixer: ignore next */
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_bgca_shapes.jpg?w=640&h=450&fit=crop&dpr=2');
			background-color: #00466C;
		}
		&.bg-jcc-shapes {
			/* autoprefixer: ignore next */
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_jcc_shapes.jpg?w=640&h=450&fit=crop&dpr=2');
			background-color: #03474D;
		}
	}
}

@include breakpoint(medium) {
	.hero-media {
		display: flex;
		align-items: center;
		padding: 94px 0;
		.hero-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.text {
			margin: 0;
			flex-basis: 48%;
		}
		.media {
			flex-basis: 48%;
		}
		&.bg-geometric-shapes {
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes_med.jpg?auto=format');
			background-size: auto;
		}
		&.bg-geometric-shapes-blue {
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes_blue.jpg?auto=format&w=1700&h=402&fit=crop&q=70');
			background-size: auto;
		}
		&.bg-ymca-shapes {
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_ymca_shapes.jpg?w=1700&h=402&fit=crop');
			background-size: auto;
		}
		&.bg-bgca-shapes {
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_bgca_shapes.jpg?w=1700&h=402&fit=crop');
			background-size: auto;
		}
		&.bg-jcc-shapes {
			background-image: url('https://jitasa.imgix.net/bg/hero/bg_jcc_shapes.jpg?w=1700&h=402&fit=crop');
			background-size: auto;
		}
	}
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.hero-media {
			&.bg-geometric-shapes {
				background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes_med@2x.jpg?auto=format');
				background-size: 1700px 402px;
			}
			&.bg-geometric-shapes-blue {
				background-image: url('https://jitasa.imgix.net/bg/hero/bg_geometric_shapes_blue.jpg?auto=format&w=1700&h=402&fit=crop&dpr=2');
				background-size: 1700px 402px;
			}
			&.bg-ymca-shapes {
				background-image: url('https://jitasa.imgix.net/bg/hero/bg_ymca_shapes.jpg?w=1700&h=402&fit=crop&dpr=2');
				background-size: 1700px 402px;
			}
			&.bg-bgca-shapes {
				background-image: url('https://jitasa.imgix.net/bg/hero/bg_bgca_shapes.jpg?w=1700&h=402&fit=crop&dpr=2');
				background-size: 1700px 402px;
			}
			&.bg-jcc-shapes {
				background-image: url('https://jitasa.imgix.net/bg/hero/bg_jcc_shapes.jpg?w=1700&h=402&fit=crop&dpr=2');
				background-size: 1700px 402px;
			}
		}
	}
}


@include breakpoint(900px) {
	.hero-media {
		min-height: 428px;
		.hero-content {
			max-width: 930px;
			min-height: 240px;
			margin: 0 auto;
		}
		.text {
			flex-basis: auto;
			padding-right: 30px;
		}
		.media {
			flex-basis: auto;
			flex-shrink: 0;
		}
		h1 {
			font-size: rem-calc(50);
			&.small-heading {
				font-size: rem-calc(40);
			}
		}
		p {
			font-size: rem-calc(32);
			line-height: 1;
		}
	}
}

/* @end Hero */


/*------------------------------------*\
    @group Subhero
\*------------------------------------*/

.sub-hero-offset-top {
	margin-top: -38px;
}

@media screen and #{breakpoint(medium)} {

.sub-hero-offset-top {
	margin-top: -47px;
}

}

/* @end Subhero */