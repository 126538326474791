.is-active .accordion-title {
	color: $bright-blue;
}
.accordion-title {
	font-family: $heading-font;
	font-size: rem-calc(21);
	color: $brand-alt;
	padding: 1rem 20px 1rem 0;
	padding-left: 0;
	padding-right: 20px;
	&:hover, &:focus {
		color: $brand;
	}
	&::before {
		right: 0;
		transform: translateY(-8px);
	}
}
.accordion-content {
	padding: 0 0 20px;
}

.accordion-heading {
	font-size: rem-calc(28);
	font-weight: $heading-bold;
	color: $gray;
	line-height: 1.1;
	text-align: left;
	padding-bottom: 0;
	&::after {
		display: none;
	}
}