/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background: $brand-ally;
	font-size: rem-calc(18);
	font-family: $alt-font;
	font-weight: $alt-bold;
	color: $white;
	text-decoration: none;
	padding: .777em 1.777em .888em;
	margin: 0 0 1.5rem;
	border: none;
	display: inline-block;
	line-height: 1.2;
	transition: background-color .3s;
	&:hover,
	&:focus {
		background: darken($brand-ally, 10%);
		color: $white;
		text-decoration: none;
	}
	&[disabled="disabled"] {
	  background: gray;
	}
}
.button.small {
	font-size: rem-calc(16);
	padding: .4em 1em .5em;
}
.button.large {
	font-size: rem-calc(24);
	padding: 18px 23px;
}
.button.short {
	padding: .2em .5em .3em;
}
.button.green {
	background: $green;
	&:hover,
	&:focus {
		background: darken($green,10);
	}
}
.button.orange {
	background: $orange;
	&:hover,
	&:focus {
		background: darken($orange,10);
	}
}
.button.purple {
	background: $brand-alt;
	&:hover,
	&:focus {
		background: darken($brand-alt,10);
	}
}
.button.white {
	background: $white;
	color: $brand-ally;
	transition: color .3s;
	&:hover,
	&:focus {
		color: $brand-alt;
	}
}
.button.pre-icon {
	padding: .777em 1.25em .888em 1em;
	.icon {
		margin-right: 7px;
	}
}
.button-group .button {
	margin-left: 8px;
	margin-right: 8px;
	margin-top: 8px;
	margin-bottom: 8px;
}

.button-group.triple-even {
	margin-bottom: 24px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	.button {
		width: 100%;
		margin: 0;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
}

.button.external-link {
	font-size: rem-calc(20);
	line-height: 1;
	display: inline-flex;
	align-items: first baseline;
	justify-content: space-between;
	gap: 10px;
	img {
		margin: 0;
		flex-shrink: 0;
	}
}

.button-info-group {
	margin: 30px 0 50px;
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	justify-content: center;
	align-items: flex-start;
	.heading {
		font-size: rem-calc(27);
		color: $brand-alt;
		padding: 0;
		margin-bottom: 10px;
		&::after {
			display: none;
		}
	}
	.button {
		margin-bottom: 15px;
	}
	.button-info {
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.button,
	form input[type="submit"] {
		font-size: rem-calc(20);
	}
	.button.small {
		padding: .525em 1.25em .625em;
	}
}

@include breakpoint(large) {
	.button-info-group {
		gap: 30px 50px;
	}
}