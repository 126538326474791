.modal {
	height: 100vh;
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 1000;
	overflow-y: scroll;
}

.modal-overlay {
	height: 100vh;
	background-color: rgba(31, 39, 153, 0.9);
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.modal-panel {
	min-height: 100vh;
	margin: 0 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-content {
	max-width: 900px;
	width: 100%;
	background: $white;
	text-align: left;
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
	h2 {
		font-size: rem-calc(30);
		color: $brand-alt;
		text-align: left;
		margin-bottom: 30px;
	}
	h3 {
		font-size: rem-calc(24);
		font-weight: $heading-bold;
		color: $brand-alt;
		line-height: 1.25;
		text-align: left;
		padding: 0;
		margin: 50px 0 20px;
		&::after {
			display: none;
		}
	}
	ul {
		font-family: $main-font;
		font-size: 1rem;
		padding: 0;
		margin: 0;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 20px 30px;
	}
	li {
		margin: 0;
	}
	.bold-list li {
		font-weight: bold;
		line-height: 1.3;
		span {
			font-weight: normal;
			margin-top: 8px;
			display: block;
		}
	}
	.button-close {
		line-height: 1;
		text-align: right;
		margin-top: -10px;
		margin-right: -10px;
		button {
			color: $gray;
			transition: color .3s;
			cursor: pointer;
			&:hover {
				color: $brand;
			}
		}
	}
}

@include breakpoint(800px) {
	.modal-content {
		padding: 50px;
		h2 {
			font-size: rem-calc(40);
		}
	}
}

.modal-panel.fullscreen {
	// height: 100vh;
	// padding: 50px 0;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 auto;
	.smcx-embed {
		height: calc(100vh - 50px);
	}
	.smcx-embed > .smcx-iframe-container {
		height: calc(100vh - 50px);
		max-height: 100vh;
	}
	.modal-content {
		max-width: 1300px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 0;
		padding-right: 0;
		position: relative;
		.button-close {
			position: absolute;
			top: 10px;
			right: 30px;
			margin: 0;
			z-index: 20;
		}
	}
}


@include breakpoint(800px) {
	.modal-panel.fullscreen {
		.smcx-embed {
			height: calc(100vh - 50px);
		}
		.smcx-embed > .smcx-iframe-container {
			height: calc(100vh - 50px);
		}
	}
}