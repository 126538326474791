$nav-bg-color: $white;

body.offscreen-nav-visible {
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.75;
        z-index: 1500;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
.offscreen-nav {
    width: 100%;
    max-width: 375px;
    height: 100%;
    background-color: $nav-bg-color;
    padding: 20px;
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2100;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    visibility: hidden;
    overflow: scroll;
    &.visible {
        z-index: 2100;
        visibility: visible;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
    .hamburger:focus {
        outline: solid 2px $main-color;
    }
    a,
    button {
        background: none;
        color: $brand-alt;
        text-decoration: none;
        padding: 0;
        border-bottom: 0;
        cursor: pointer;
        transition: color 0.3s;
        &.current,
        &.selected {
            color: $brand-ally;
        }
        &:hover,
        &:focus-visible {
            color: $brand-ally;
        }
    }
    .has-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 100%;
            text-align: left;
            display: block;
            &.active::after {
                transform: rotate(180deg);
            }
        }
    }

    .top-level {
        li {
            padding-bottom: 25px;
            margin-bottom: 0;

            &.navicon {
                > div {
                    padding-left: 34px;
                    position: relative;
                    &::before {
                        content: "";
                        background-position: left center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: color 0.3s;
                    }
                }
                &.navicon-hand-flame > div::before {
                    background-image: url("/images/icons/hand_flames.svg");
                }
                &.navicon-people > div::before {
                    background-image: url("/images/icons/users.svg");
                }
                &.navicon-flame > div::before {
                    background-image: url("/images/icons/flame.svg");
                }
                &.navicon-squares > div::before {
                    background-image: url("/images/icons/squares.svg");
                }
            }
            &.button-nav {
                padding-top: 10px;
                a {
                    width: 100%;
                    background: $brand-ally;
                    font-family: $alt-font;
                    font-size: 1rem;
                    font-weight: $alt-normal;
                    color: $white;
                    text-align: center;
                    text-decoration: none;
                    margin: 0 auto;
                    display: block;
                    padding: .777em 1.777em .888em;
                    margin: 0;
                    border: none;
                    display: inline-block;
                    line-height: 1.2;
                    transition: background-color .3s;
                    &:hover,
                    &:focus {
                        background: darken($brand-ally, 10);
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }

        .nav-text {
            font-family: $alt-font;
            font-size: rem-calc(22);
            line-height: 1.12;
        }
        .nav-caret {
            width: 32px;
            height: 30px;
            color: $brand-ally;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(180deg);
            transition: transform 0.3s, color .3s;
            &.active,
            &:hover {
                color: $brand-alt;
                transform: rotate(0);
            }
        }
    }

    .second-level {
        padding-left: 34px;
        margin: 0;
        li {
            font-family: $alt-font;
            font-size: rem-calc(18);
            line-height: 1;
            padding: 0;
            padding-bottom: 10px;
            &:first-child {
                padding-top: 10px;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        a {
            color: $main-color;
            &.current,
            &.selected,
            &:hover,
            &:focus-visible {
                color: $brand-ally;
            }
        }
    }
}

.offscreen-nav-header {
    margin-bottom: 40px;
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    a,
    button {
        display: inline-block;
    }
    img {
        margin: 0;
    }
    .nav-toggle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .nav-label {
        font-family: $alt-font;
        font-size: rem-calc(12);
        text-transform: uppercase;
        padding-top: 2px;
    }
}