.strategic-partner-details {
	max-width: 550px;
	margin: 0 auto;
	display: grid;
	grid-gap: 50px;
	p {
		font-size: rem-calc(18);
		line-height: 1.5;
	}
}

.strategic-partner-callout {
	background: $off-white;
	text-align: center;
	padding: 20px;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
	margin-top: 50px;
	img {
		margin-bottom: 25px;
	}
	p {
		font-size: rem-calc(16);
		margin-bottom: 0;
	}
	.heading {
		font-size: rem-calc(20);
		font-weight: bold;
		color: $brand-alt;
		margin-bottom: 10px;
	}
}

@include breakpoint(large) {
	.strategic-partner-details {
		max-width: 100%;
		grid-template-columns: 1fr 1fr;
		p {
			font-size: rem-calc(20);
		}
	}
	.strategic-partner-callout {
		padding: 30px;
	}
}