/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

   All sections are sized in 'em' which means they will
   resize their spacing according to the font-size of the
   parent container. Smaller font-size means less spacing
   and larger font-size increases the spacing.

\*----------------------------------------------------------*/

.content-section-full {
	padding: 2em 0;
}
.intro-section {
	font-size: rem-calc(20);
	padding-bottom: 2em;
	ul {
		padding-left: 0;
		li {
			background-image: url('/images/cssimg/flames.png');
			background-size: 18px 18px;
			background-position: 0 6px;
			padding-left: 30px;
		}
	}
}
.content-section {
	padding-bottom: 1.5em;
}
.content-sub-section {
	padding-bottom: 1em;
}

@media screen and #{breakpoint(medium)} {
	.content-section-full {
		padding: 3em 0 3em;
	}
	.intro-section {
		font-size: rem-calc(24);
		padding: 0 0 3.5em;
	}
	.content-section {
		padding-bottom: 2.5em;
		&.no-base {
			padding-bottom: 3em;
		}
	}
	.content-sub-section {
		padding-bottom: 1.5em;
	}
}

.section-color {
	color: $white;
	h1,h2,h3,h4,h5,h6, blockquote, cite, p {
		color: $white;
	}
	blockquote cite .title {
		color: $white;
	}
}

//Sections with background colors
.bg-section {
	padding-top: 50px;
	padding-bottom: 50px;
	.contained-text {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
}

.dark-section {
	background: $dark-gray;
	color: $white;
	h2, .heading {
		color: $brand;
	}
	.simple-list {
		font-size: rem-calc(20);
		color: $brand-accent;
		text-align: center;
		margin: 30px 0 0;
		li {
			margin-bottom: 2rem;
		}
	}
	.light-link {
		color: #9bdfff;
		text-decoration: underline;
		&:hover, &:focus-visible {
			color: $brand;
		}
	}
}

@media screen and #{breakpoint(medium)} {

.bg-section {
	padding-top: 100px;
	padding-bottom: 100px;
}

}


//Flame section
.flame-section {
	background-color: $off-white;
	.inner {
		@include grid-row;
		max-width: 600px;
	}
	.text {
		@include grid-col;
		padding-top: 48px;
		padding-bottom: 48px;
	}
	.flame {
		@include grid-col;
		text-align: center;
		position: relative;
		img {
			width: auto;
			margin: 0;
		}
	}
	h2 {
		text-align: left;
		padding-bottom: 0;
		&::after {
			display: none;
		}
	}
	p {
		font-size: rem-calc(20);
		color: $gray;
	}
	.button {
		margin-bottom: 0;
	}
}

@include breakpoint(880px) {
	.flame-section {
		.inner {
			max-width: 75em;
			display: flex;
			align-items: flex-end;
		}
		.text {
			width: grid-col(45%);
			padding-top: 48px;
			padding-bottom: 48px;
		}
		.flame {
			width: grid-col(55%);
			text-align: right;
			img {
				margin-top: -40px;
			}
		}
	}
}

@media screen and #{breakpoint(large)} {
	.flame-section {
		.inner {
			background-size: auto;
		}
		.text {
			width: grid-col(5);
		}
		.flame {
			width: grid-col(7);
		}
	}
}


//Tax Prep Services section
@media screen and #{breakpoint(medium)} {
	.tax-prep-services-section {
		.section-intro {
			margin-top: 80px;
			margin-bottom: 60px;
		}
	}
}
